import { type ChangeEvent } from 'react';
import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { nl } from '../../../assets/text/nl';
import { isDateString } from '../../../core/helpers/utils';
import { useProductionPlan } from '../../../core/providers/ProductionPlanProvider';

interface RemarksTableProps {
  showHeader: boolean;
  day: string;
  rowIndex: number;
}

export const RemarksTable = ({
  showHeader,
  day,
  rowIndex,
}: RemarksTableProps) => {
  const {
    productionPlanData: { machine, comments },
    setComment,
  } = useProductionPlan();

  const comment = isDateString(day)
    ? comments?.[machine?.machineCode ?? '']?.[day]?.[rowIndex] ?? ''
    : '';

  const onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;

    if (!isDateString(day) || !machine) {
      return;
    }

    setComment({
      rowIndex,
      machineKey: machine.machineCode,
      key: day,
      comment: value,
    });
  };

  return (
    <TableContainer sx={{ width: '300px', mx: 0.5 }}>
      <Table>
        {showHeader && (
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>{nl.opmerking}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          <TableRow>
            <TableCell>
              <Input
                disableUnderline
                onChange={onInputChange}
                value={comment}
                sx={{
                  width: '100%',
                  '& .MuiInput-input': {
                    padding: 0,
                  },
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow sx={(theme) => ({ background: theme.palette.grey[300] })}>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
