import { type FC, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { UploadArticleItem } from './UploadArticlesModal/UploadArticleItem';
import { nl } from '../../../assets/text/nl';
import config from '../../../config';
import { type BackUpDataType } from '../../../core/providers/ArticlesTableProvider/utils/saveBackUpArticles';
import { articlesHeadersTranslations } from '../../../core/translations/articlesDictionary';
import { type UploadsStateType } from '../../../core/types/UploadTypes';
import { Modal } from '../../Modal';

type PropsType = Parameters<typeof Modal>['0'] & {
  currentUploads: UploadsStateType | undefined;
};

const { articleCodeName } = config.fields;

const {
  [articleCodeName]: articleCodeTranslation,
  productionNumber,
  machinePlanningId,
} = articlesHeadersTranslations;

export const TableHeaders = {
  [articleCodeName]: articleCodeTranslation,
  productionNumber: productionNumber,
  machinePlanningId: machinePlanningId,
  status: 'Status',
  machine: nl.machine,
  date: nl.day,
  info: 'Info',
};

export type UploadTableRowDataType = {
  [key in keyof typeof TableHeaders]: string;
};

export const orderedUploadTableCells: Array<keyof UploadTableRowDataType> = [
  articleCodeName,
  'productionNumber',
  'machinePlanningId',
  'machine',
  'date',
  'status',
  'info',
];

export const uploadTableColumnLength = orderedUploadTableCells.length;

export const UploadArticlesModal: FC<PropsType> = (props) => {
  const { currentUploads } = props;

  const [articles, requests] = currentUploads ?? [];

  const backUpArticles = useMemo(() => {
    if (!articles || !requests) {
      return null;
    }

    return JSON.parse(
      localStorage.getItem(config.localStorageKeys.BACKUP_ARTICLES) ?? '{}',
    ) as BackUpDataType;
  }, [articles, requests]);

  if (!articles || !requests) {
    return null;
  }

  return (
    <Modal {...props}>
      <Box sx={{ height: '100%' }}>
        <Typography
          sx={{
            paddingBottom: '20px',
            fontSize: '1.3em',
            textAlign: 'center',
          }}
        >
          {nl.uploadModalCaption}
        </Typography>
        <TableContainer
          sx={{
            boxShadow: 'inset -10px 1px 11px #cbbaba',
            height: 'calc(100% - 60px)',
          }}
        >
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                {orderedUploadTableCells.map((header) => {
                  return (
                    <TableCell key={header} sx={{ textAlign: 'center' }}>
                      <Typography sx={{ fontWeight: '600', fontSize: '1.1em' }}>
                        {TableHeaders[header]}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={{ width: '100%' }}>
              {articles.map((article, inx) => {
                return (
                  <UploadArticleItem
                    key={`${article.article}-${inx}`}
                    article={article}
                    backUpArticles={backUpArticles}
                    request={requests[inx]}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};
