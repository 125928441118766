import { useEffect, useState } from 'react';

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    window.onmousedown = () => {
      window.onmousemove = (ev: MouseEvent) => {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
      };
    };

    window.onmouseup = () => {
      window.onmousemove = null;
    };
  }, []);

  return mousePosition;
};

export default useMousePosition;
