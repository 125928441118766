import { type SvgIconProps } from '@mui/material';

import { NoFillSvgIcon } from '../NoFillSvgIcon';

export const CheckIcon = (props: SvgIconProps) => {
  return (
    <NoFillSvgIcon {...props} width='14' height='11' viewBox='0 0 14 11'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='m5.03 7.494 7.171-7.17 1.414 1.414-8.584 8.585L.385 5.676l1.414-1.415L5.03 7.494Z'
        fill='currentColor'
      />
    </NoFillSvgIcon>
  );
};
