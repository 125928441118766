import config from '../../../../config';
import { type SelectedHeadersType } from '../ArticlesTableProvider';
import { TOrderType } from '../types';
import { type ArticleDataType } from './ArticleTypes';
import { getArticlesSum } from './getArticlesSum';
import { getBarCodes } from './getBarCodes';
import { getMakeToOrderShortages } from './getMakeToOrderShortages';
import { getStockOrderShortages } from './getStockOrderShortages';
import { type RawArticlesDataType, ProductionStatus } from './getTableData';

export const baseSelectedHeader: SelectedHeadersType = [
  'productionWeek',
  'articleCode',
  'productionQuantityToProduce',
  'productionSalesOrderNumber',
  'sum',
  'weekZeroShortages',
  'weekOneShortages',
  'weekTwoShortages',
  'weekThreeShortages',
  'weekFourShortages',
  'weekFiveShortages',
  'weeksLeftShortages',
];

export const hiddenHeaders: SelectedHeadersType = [
  'id',
  'timesToBeProduced',
  'economicStock',
  'availableStock',
  'quantityPerPallet',
  'machinePlanningId',
  'barCodes',
];

const { MAKE_TO_ORDER } = config.statuses;

export const offHeaders: SelectedHeadersType = [
  'barCodes',
  'hasPlannedProduction',
  'stockIsEnough',
];

export const disabledHeaders: SelectedHeadersType = [
  config.fields.articleCodeName,
];

const initialData: Array<ArticleDataType> = [];

export const prepareArticlesData = (data: Array<RawArticlesDataType>) => {
  return data.reduce((acc, dataItem) => {
    const { productions = [] } = dataItem;
    const production = productions[0];
    const isMakeToOrder = dataItem.ordertype === TOrderType.MakeToOrder;

    const getShortages = (week: number | undefined) => {
      return (
        (isMakeToOrder
          ? getMakeToOrderShortages(dataItem, week)
          : getStockOrderShortages(dataItem, week)) || '0'
      );
    };

    const barCodes = getBarCodes(dataItem);

    const { data: weekZeroShortages, stockIsEnough: weekZeroStockIsEnough } =
      getShortages(0);
    const { data: weekOneShortages, stockIsEnough: weekOneStockIsEnough } =
      getShortages(1);
    const { data: weekTwoShortages, stockIsEnough: weekTwoStockIsEnough } =
      getShortages(2);
    const { data: weekThreeShortages, stockIsEnough: weekThreeStockIsEnough } =
      getShortages(3);
    const { data: weekFourShortages, stockIsEnough: weekFourStockIsEnough } =
      getShortages(4);
    const { data: weekFiveShortages, stockIsEnough: weekFiveStockIsEnough } =
      getShortages(5);
    const { data: weeksLeftShortages, stockIsEnough: weeksLeftStockIsEnough } =
      getShortages(undefined);

    const stockIsEnough = [
      weekZeroStockIsEnough,
      weekOneStockIsEnough,
      weekTwoStockIsEnough,
      weekThreeStockIsEnough,
      weekFourStockIsEnough,
      weekFiveStockIsEnough,
      weeksLeftStockIsEnough,
    ].every(Boolean);

    const hasPlannedProduction = productions.some(
      (selectedProduction) =>
        selectedProduction?.production_status === ProductionStatus.Planned,
    );

    const sum = getArticlesSum(productions);

    const baseData: ArticleDataType = {
      barCodes,
      hasPlannedProduction,
      stockIsEnough,
      weekZeroShortages,
      weekOneShortages,
      weekTwoShortages,
      weekThreeShortages,
      weekFourShortages,
      weekFiveShortages,
      weeksLeftShortages,
      sum,
      id: `${dataItem.article_code}-${dataItem.ordertype}`,
      articleCode: dataItem.article_code,
      repeats: dataItem.repeats,
      turn: dataItem.turn === 1,
      economicStock: dataItem.economic_stock,
      availableStock: dataItem.available_stock,
      height: dataItem.article_height,
      length: dataItem.article_length,
      width: dataItem.article_width,
      orderType: dataItem.ordertype,
      articleDescription: dataItem.article_description,
      mouldLength: `${dataItem.length_mould || ''}`,
      planCustomItem: `${dataItem.plan_custom_item || ''}`,
      quantityPlan: dataItem.plan_quantity,
      mouldPosition: dataItem.poss_moulds,
      possibleMachines: dataItem.possible_machines,
      quantityPerMould: `${dataItem.quantity_per_mould}`,
      defaultQuantityPerMould: `${dataItem.quantity_per_mould}`,
      totalQuantityToProduce: `${dataItem.total_quantity_to_produce || ''}`,
      quantityPerPallet: dataItem.quantity_per_pallet,
      machinePark: dataItem.machine_park,
      timesToBeProduced: '',
      productionYear: `${dataItem.year || production?.year || ''}`,
      productionWeek: `${dataItem.week || production?.week || ''}`,
      productionQuantityToProduce: `${
        dataItem.total_quantity_to_produce || ''
      }`,
      productionSalesOrderNumber: production?.salesorder_nr,
      productionCustomer: production?.customer,
    };

    if (dataItem.ordertype === MAKE_TO_ORDER && dataItem.productions.length) {
      const newItems: Array<ArticleDataType> = dataItem.productions.map(
        (orderProduction) => {
          return {
            ...baseData,
            id: `${baseData.id}-${orderProduction?.production_nr || ''}`,
            productionNumber: orderProduction?.production_nr ?? '',
            productionSalesOrderNumber: orderProduction?.salesorder_nr,
            machinePlanningId: orderProduction?.machine_planning_id ?? '',
            productionWeek: orderProduction?.week,
            productionYear: orderProduction?.year,
            productionCustomer: orderProduction?.customer,
            productionQuantityToProduce: `${
              orderProduction?.quantity_to_produce ?? ''
            }`,
          };
        },
      );

      return [...acc, ...newItems];
    }

    return [...acc, baseData];
  }, initialData);
};
