import toast from 'react-hot-toast';

import { nl } from '../../assets/text/nl';
import config from '../../config';
import {
  type IInsertedArticlesPayload,
  type IProductionData,
} from '../providers/ProductionPlanProvider/types';
import { isMoreThanNeeded } from '../providers/ProductionPlanProvider/utils/isMoreThanNeeded';

export const modifyStateArticle = (
  state: IProductionData,
  payload: IInsertedArticlesPayload,
): IProductionData => {
  const {
    machineKey,
    key,
    article,
    row: rowIdx,
    tableIndex: payloadTableIndex,
  } = payload;

  const existingArticles = state.insertedArticles?.[machineKey]?.[key];

  if (!existingArticles) {
    return {
      ...state,
    };
  }

  let rowIndex = -1;
  let articleIndex = -1;

  const tableIndex = payloadTableIndex ?? article.tableIndex;

  if (typeof tableIndex !== 'undefined') {
    rowIndex = rowIdx;
    articleIndex = tableIndex;
  } else {
    for (let i = 0; i < existingArticles.length; i++) {
      const articlesRow = existingArticles[i];

      for (let j = 0; j < articlesRow.length; j++) {
        const rowArticle = articlesRow[j];

        if (
          rowArticle[config.fields.articleCodeName] ===
            article[config.fields.articleCodeName] &&
          rowArticle.tableIndex === article.tableIndex &&
          i === rowIdx
        ) {
          rowIndex = i;
          articleIndex = j;
          break;
        }
      }
    }

    if (articleIndex === -1 || rowIndex === -1) {
      return {
        ...state,
      };
    }
  }

  const newArticles = existingArticles.map((row) => [...row]);

  if (article.isUploaded && !parseInt(article.timesToBeProduced, 10)) {
    newArticles[rowIndex].splice(articleIndex, 1);
  } else {
    newArticles[rowIndex][articleIndex] = article;
  }

  const newState = {
    ...state,
    insertedArticles: {
      ...state.insertedArticles,
      [machineKey]: {
        ...(state.insertedArticles?.[machineKey] ?? {}),
        [key]: newArticles,
      },
    },
  };

  if (isMoreThanNeeded(newState, article)) {
    toast.error(nl.moreThanNeeded, { id: 'moreThanNeeded' });
  }

  return newState;
};
