import { type FC, memo, useEffect, useState } from 'react';
import { Box } from '@mui/system';

import config from '../../../config';
import { calculateArticlesLength } from '../../../core/helpers/calculateArticlesHeight';
import {
  CONTEXT_MENU_DATE_KEY,
  CONTEXT_MENU_ROW_KEY,
} from '../../../core/helpers/hooks/useContextMenu';
import { isDateString } from '../../../core/helpers/utils';
import { useContextMenuContext } from '../../../core/providers/ContextMenuProvider';
import { useProductionPlan } from '../../../core/providers/ProductionPlanProvider';
import { ArticlesTableProductionPlan } from './ArticlesTableProductionPlan';
import ContextMenu from './ContextMenu';
import { DayTable } from './DayTable';
import { RemarksTable } from './RemarksTable';

interface IProps {
  showAm: boolean;
}

export const DateParseOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const { articlesToBePlanned } = config;

const HOLIDAY_STYLE = {
  '&::before': {
    content: '""',
    position: 'absolute',
    zIndex: 2,
    background: '#9f9f9f85',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
};

export const PlanningCalendar: FC<IProps> = memo(({ showAm }) => {
  const [highlightArticles, setHighlightArticles] = useState<boolean>(false);

  const {
    productionPlanData: { days, machine },
    holidays,
    getInsertedArticles,
  } = useProductionPlan();
  const { handleContextMenu } = useContextMenuContext();

  useEffect(() => {
    const onMouseUp = () => {
      setHighlightArticles(false);
    };

    const onMouseDown = () => {
      setHighlightArticles(true);
    };

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  const dayTableRows = days?.map((date) =>
    date.toLocaleString('en-GB', DateParseOptions),
  );

  const insertedArticles = getInsertedArticles();

  return (
    <Box
      sx={{
        minWidth: 'max-content',
        maxHeight: 'calc(100vh - 110px)',
        overflow: 'hidden scroll',
      }}
    >
      {dayTableRows?.map((date, idx) => {
        if (!isDateString(date)) {
          return null;
        }

        const isHoliday = holidays.includes(date);

        const selectedArticles = insertedArticles?.[date];

        const rowQuantity = selectedArticles?.length || 1;
        let currentLenght = machine?.beamLength ?? 0;

        return Array.from(Array(rowQuantity)).map((_item, inx) => {
          const articles = selectedArticles?.[inx];

          if (articles) {
            currentLenght =
              (machine?.beamLength ?? 0) -
              calculateArticlesLength(articles ?? []);
          }

          return (
            <Box
              sx={{
                mb: 0.7,
                display: 'flex',
                mt: inx ? '-7px' : 0,
                position: 'relative',
                ...(isHoliday ? HOLIDAY_STYLE : {}),
              }}
              key={`${date}-${inx}`}
            >
              <Box
                onContextMenu={(e) => handleContextMenu?.(e)}
                {...{
                  [CONTEXT_MENU_DATE_KEY]: date,
                  [CONTEXT_MENU_ROW_KEY]: inx,
                }}
              >
                <DayTable
                  day={date}
                  showHeader={idx === 0 && inx === 0}
                  rowIndex={inx}
                  currentLenght={currentLenght}
                  articles={articles}
                />
              </Box>
              {[...Array(articlesToBePlanned)].map((_el, index) => (
                <ArticlesTableProductionPlan
                  key={index}
                  articles={articles}
                  day={date}
                  showHeader={idx === 0 && inx === 0}
                  rowIndex={inx}
                  highlightArticles={highlightArticles}
                  tableIndex={index}
                  showAm={showAm}
                />
              ))}
              <Box
                onContextMenu={(e) => handleContextMenu?.(e)}
                {...{
                  [CONTEXT_MENU_DATE_KEY]: date,
                  [CONTEXT_MENU_ROW_KEY]: inx,
                }}
              >
                <RemarksTable
                  showHeader={idx === 0 && inx === 0}
                  day={date}
                  rowIndex={inx}
                />
              </Box>
            </Box>
          );
        });
      })}
      <ContextMenu />
    </Box>
  );
});
