import { type DateStringType } from './types';

export const typedArrayDates = <T extends Date>(dates: T[]) => {
  return dates.map((date) => {
    const day = `${date.getDate()}`.padStart(2, '0');
    const month = `${date.getMonth() + 1}`.padStart(2, '0');

    return `${day}/${month}/${date.getFullYear()}`;
  }) as Array<DateStringType>;
};
