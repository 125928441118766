import { type ThemeOptions } from '@mui/material/styles';

export default (): ThemeOptions['components'] => ({
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        height: '30px',
      },
    },
  },
});
