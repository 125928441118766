import { type IProductionData } from '../types';

export const prepareBaseData = (): IProductionData => {
  return {
    year: new Date().getFullYear(),
    days: [],
    week: undefined,
    insertedArticles: {},
    machine: undefined,
    machinePark: undefined,
    comments: {},
  };
};
