import { type ThemeOptions } from '@mui/material/styles';

export default (): ThemeOptions['components'] => ({
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        MozAppearance: 'textfield',
      },
    },
  },
});
