import { useContext } from 'react';

import { ContextMenuContext } from './ContextMenuProvider';

export const useContextMenuContext = () => {
  const ctx = useContext(ContextMenuContext);

  if (ctx == null) {
    throw new Error('Wrap app with Context Menu Context');
  }

  return ctx;
};
