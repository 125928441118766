import {
  type IAddRowPayload,
  type IChangeProductionPlanInsertedArticle,
  type ICommentsPayload,
  type IFullDate,
  type IInsertedArticlesPayload,
  type IInsertedArticlesRemovePayload,
  type IProductionData,
  type IRemoveProductionPlanInsertedArticle,
  type ISetProductionPlan,
  type ISetProductionPlanArticlesRow,
  type ISetProductionPlanComment,
  type ISetProductionPlanDays,
  type ISetProductionPlanFullDate,
  type ISetProductionPlanInsertedArticle,
  type ISetProductionPlanMachine,
  type ISetProductionPlanMachinePark,
  type ISetProductionPlanWeek,
  type ISetProductionPlanYear,
  type ProductionPlanMachine,
  ProductionPlanActionType,
} from '../../providers/ProductionPlanProvider/types';

export const setProductionPlanAction = (
  payload: IProductionData,
): ISetProductionPlan => {
  return {
    type: ProductionPlanActionType.SET_DATA,
    payload,
  };
};

export const setProductionPlanDaysAction = (
  payload: Array<Date>,
): ISetProductionPlanDays => {
  return {
    type: ProductionPlanActionType.SET_DAYS,
    payload,
  };
};

export const setProductionPlanWeekAction = (
  payload: number,
): ISetProductionPlanWeek => {
  return {
    type: ProductionPlanActionType.SET_WEEK,
    payload,
  };
};

export const setProductionPlanYearAction = (
  payload: number,
): ISetProductionPlanYear => {
  return {
    type: ProductionPlanActionType.SET_YEAR,
    payload,
  };
};

export const setProductionPlanFullYearAction = (
  payload: IFullDate,
): ISetProductionPlanFullDate => {
  return {
    type: ProductionPlanActionType.SET_FULL_DATE,
    payload,
  };
};

export const setProductionPlanInsertedArticleAction = (
  payload: IInsertedArticlesPayload,
): ISetProductionPlanInsertedArticle => {
  return {
    type: ProductionPlanActionType.SET_INSERTED_ARTICLE,
    payload,
  };
};

export const changeProductionPlanInsertedArticleAction = (
  payload: IInsertedArticlesPayload,
): IChangeProductionPlanInsertedArticle => {
  return {
    type: ProductionPlanActionType.CHANGE_INSERTED_ARTICLE,
    payload,
  };
};

export const removeProductionPlanInsertedArticleAction = (
  payload: IInsertedArticlesRemovePayload,
): IRemoveProductionPlanInsertedArticle => {
  return {
    type: ProductionPlanActionType.REMOVE_ARTICLE,
    payload,
  };
};

export const setProductionPlanMachineAction = (
  payload: ProductionPlanMachine,
): ISetProductionPlanMachine => {
  return {
    type: ProductionPlanActionType.SET_MACHINE,
    payload,
  };
};

export const setProductionPlanMachineParkAction = (
  payload: string,
): ISetProductionPlanMachinePark => {
  return {
    type: ProductionPlanActionType.CHANGE_MACHINE_PARK,
    payload,
  };
};

export const setProductionPlanCommentsAction = (
  payload: ICommentsPayload,
): ISetProductionPlanComment => {
  return {
    type: ProductionPlanActionType.SET_COMMENT,
    payload,
  };
};

export const setProductionPlanArticlesRow = (
  payload: IAddRowPayload,
): ISetProductionPlanArticlesRow => {
  return {
    type: ProductionPlanActionType.ADD_ROW,
    payload,
  };
};
