import { useCallback } from 'react';

import { type SearchFields } from '../../../../components/ArticlesPartition/Components/ArticlesTable/ArticlesTable';
import {
  setArticlesData,
  setDictionaryData,
  setMachinesLoading,
  setSearchData,
  setSelectedHeadersData,
  setSelectedRowData,
  setSortData,
} from '../../../actions/ArticlesData/ArticlesDataAction';
import {
  type DictionaryType,
  type SelectedHeadersType,
} from '../ArticlesTableProvider';
import { type ArticlesDataAction, type ArticleSort } from '../types';
import { type ArticleDataType } from '../utils/ArticleTypes';

export interface ReturnTypeUseArticlesDataSetters {
  setAllArticles: (machines: Array<ArticleDataType>) => void;
  setSearchFields: (searchFields: SearchFields) => void;
  setLoading: (loading: boolean) => void;
  setDictionary: (dictionary?: DictionaryType) => void;
  setSelectedHeaders: (selectedHeaders: SelectedHeadersType) => void;
  setSelectedRow: (selectedRow: ArticleDataType) => void;
  setSort: (sort: ArticleSort) => void;
}

export const useArticlesDataSetters = (
  dispatch: React.Dispatch<ArticlesDataAction>,
): ReturnTypeUseArticlesDataSetters => {
  const setAllArticles = useCallback(
    (articles: Array<ArticleDataType>) => {
      dispatch(setArticlesData(articles));
    },
    [dispatch],
  );

  const setSearchFields = useCallback(
    (searchFields: SearchFields) => {
      dispatch(setSearchData(searchFields));
    },
    [dispatch],
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch(setMachinesLoading(loading));
    },
    [dispatch],
  );

  const setDictionary = useCallback(
    (dictionary: DictionaryType | undefined) => {
      dispatch(setDictionaryData(dictionary));
    },
    [dispatch],
  );

  const setSelectedHeaders = useCallback(
    (selectedHeaders: SelectedHeadersType) => {
      dispatch(setSelectedHeadersData(selectedHeaders));
    },
    [dispatch],
  );

  const setSelectedRow = useCallback(
    (selectedRow: ArticleDataType | undefined) => {
      dispatch(setSelectedRowData(selectedRow));
    },
    [dispatch],
  );

  const setSort = useCallback(
    (sort: ArticleSort | undefined) => {
      dispatch(setSortData(sort));
    },
    [dispatch],
  );

  return {
    setAllArticles,
    setSearchFields,
    setLoading,
    setDictionary,
    setSelectedHeaders,
    setSelectedRow,
    setSort,
  };
};
