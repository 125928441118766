import { prepareRowForPaste } from '../../../../core/helpers/prepareRowForPaste';
import { isDateString } from '../../../../core/helpers/utils';
import { useContextMenuContext } from '../../../../core/providers/ContextMenuProvider';
import { useProductionPlan } from '../../../../core/providers/ProductionPlanProvider';

export type ContextMenuActionsType = ReturnType<typeof useContextMenuActions>;

export const useContextMenuActions = () => {
  const { setArticlesRow, getInsertedArticles } = useProductionPlan();
  const { copiedRow, setCopiedRow, setShiftModalProps } =
    useContextMenuContext();
  const insertedArticles = getInsertedArticles();

  const addRowBelow = (targetElement: HTMLElement | null) => {
    const { contextMenuDay, contextMenuRow } = targetElement?.dataset ?? {};

    if (!contextMenuDay || !isDateString(contextMenuDay) || !contextMenuRow) {
      return;
    }

    const inx = parseInt(contextMenuRow, 10) + 1;

    setArticlesRow({
      inx,
      day: contextMenuDay,
    });
  };

  const addRowAbove = (targetElement: HTMLElement | null) => {
    const { contextMenuDay, contextMenuRow } = targetElement?.dataset ?? {};

    if (!contextMenuDay || !isDateString(contextMenuDay) || !contextMenuRow) {
      return;
    }

    const inx = parseInt(contextMenuRow, 10);

    setArticlesRow({
      inx: inx,
      day: contextMenuDay,
    });
  };

  const removeCell = (targetElement: HTMLElement | null) => {
    const { contextMenuDay, contextMenuRow, contextCellIdx } =
      targetElement?.dataset ?? {};

    if (
      !contextMenuDay ||
      !isDateString(contextMenuDay) ||
      !contextMenuRow ||
      !contextCellIdx
    ) {
      return;
    }

    const inx = parseInt(contextMenuRow, 10);
    const cellIdx = parseInt(contextCellIdx, 10);
    const insertedRow = insertedArticles?.[contextMenuDay]?.[inx];

    const copyOfInsertedRow = [...(insertedRow ?? [])]; //the copy is needed because of a bug caused by using .splice directly on insertedRow
    const selectedArticle = { ...copyOfInsertedRow[cellIdx] };

    if (selectedArticle.productionNumber) {
      selectedArticle.timesToBeProduced = '0';
      selectedArticle.isUploaded = false;
    } else {
      copyOfInsertedRow?.splice(cellIdx, 1);
    }

    const row = copyOfInsertedRow?.map((article, idx) => ({
      ...article,
      tableIndex: idx,
    }));

    setArticlesRow({
      row,
      inx: inx,
      day: contextMenuDay,
    });
  };

  const removeRow = (targetElement: HTMLElement | null) => {
    const { contextMenuDay, contextMenuRow } = targetElement?.dataset ?? {};

    if (!contextMenuDay || !isDateString(contextMenuDay) || !contextMenuRow) {
      return;
    }

    const inx = parseInt(contextMenuRow, 10);

    setArticlesRow({
      inx: inx,
      day: contextMenuDay,
      row: null,
    });
  };

  const copyRow = (targetElement: HTMLElement | null) => {
    const { contextMenuDay, contextMenuRow } = targetElement?.dataset ?? {};

    if (!contextMenuDay || !isDateString(contextMenuDay) || !contextMenuRow) {
      return;
    }

    const inx = parseInt(contextMenuRow, 10);

    const row = insertedArticles?.[contextMenuDay]?.[inx];

    setCopiedRow(row);
  };

  const pasteRow = (targetElement: HTMLElement | null) => {
    const { contextMenuDay, contextMenuRow } = targetElement?.dataset ?? {};

    if (!contextMenuDay || !isDateString(contextMenuDay) || !contextMenuRow) {
      return;
    }

    const rowIndex = parseInt(contextMenuRow, 10);
    const increment = insertedArticles?.[contextMenuDay]?.[rowIndex]?.length
      ? 1
      : 0;

    const inx = rowIndex + increment;

    setArticlesRow({
      inx,
      day: contextMenuDay,
      row: prepareRowForPaste(copiedRow),
    });
  };

  const shiftRow = (targetElement: HTMLElement | null) => {
    const { contextMenuDay, contextMenuRow } = targetElement?.dataset ?? {};

    if (!contextMenuDay || !isDateString(contextMenuDay) || !contextMenuRow) {
      return;
    }

    const inx = parseInt(contextMenuRow, 10);

    setShiftModalProps({
      day: contextMenuDay,
      inx: inx,
    });
  };

  const clearRow = (targetElement: HTMLElement | null) => {
    const { contextMenuDay, contextMenuRow } = targetElement?.dataset ?? {};

    if (!contextMenuDay || !isDateString(contextMenuDay) || !contextMenuRow) {
      return;
    }

    const inx = parseInt(contextMenuRow, 10);
    const newRow = insertedArticles?.[contextMenuDay]?.[inx]
      ?.filter((article) => article.productionNumber)
      ?.map((article) => {
        return { ...article, timesToBeProduced: '0', isUploaded: false };
      });

    setArticlesRow({
      inx,
      day: contextMenuDay,
      row: newRow ?? [],
    });
  };

  return {
    removeRow,
    clearRow,
    removeCell,
    copyRow,
    pasteRow,
    addRowAbove,
    addRowBelow,
    shiftRow,
  };
};
