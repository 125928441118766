import { type SearchFields } from '../../../components/ArticlesPartition/Components/ArticlesTable/ArticlesTable';
import {
  type DictionaryType,
  type SelectedHeadersType,
} from '../../providers/ArticlesTableProvider';
import {
  type ArticleSort,
  type ISetArticlesData,
  type ISetArticleSort,
  type ISetDictionary,
  type ISetLoading,
  type ISetSearchFields,
  type ISetSelectedHeaders,
  type ISetSelectedRow,
  ArticlesDataActionType,
} from '../../providers/ArticlesTableProvider/types';
import { type ArticleDataType } from '../../providers/ArticlesTableProvider/utils/ArticleTypes';

export const setArticlesData = (
  payload: Array<ArticleDataType>,
): ISetArticlesData => {
  return {
    type: ArticlesDataActionType.SET_ALL_ARTICLES_DATA,
    payload,
  };
};

export const setSearchData = (payload: SearchFields): ISetSearchFields => {
  return {
    type: ArticlesDataActionType.SET_SEARCH_FIELDS,
    payload,
  };
};

export const setMachinesLoading = (payload: boolean): ISetLoading => {
  return {
    type: ArticlesDataActionType.SET_LOADING,
    payload,
  };
};

export const setDictionaryData = (
  payload: DictionaryType | undefined,
): ISetDictionary => {
  return {
    type: ArticlesDataActionType.SET_DICTIONARY,
    payload,
  };
};

export const setSelectedHeadersData = (
  payload: SelectedHeadersType,
): ISetSelectedHeaders => {
  return {
    type: ArticlesDataActionType.SET_SELECTED_HEADERS,
    payload,
  };
};

export const setSelectedRowData = (
  payload: ArticleDataType | undefined,
): ISetSelectedRow => {
  return { type: ArticlesDataActionType.SET_SELECTED_ROW, payload };
};

export const setSortData = (
  payload: ArticleSort | undefined,
): ISetArticleSort => {
  return { type: ArticlesDataActionType.SET_SORT, payload };
};
