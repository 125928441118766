import config from '../../../../config';
import { type DateStringType } from '../../../helpers/types';
import { type IProductionData } from '../../ProductionPlanProvider/types';

export type BackUpDataType = {
  data: IProductionData['insertedArticles'];
  localStorageVersion: string;
} | null;

const { localStorageVersion, localStorageKeys } = config;

export const saveBackUpArticles = (
  insertedArticles: IProductionData['insertedArticles'],
) => {
  const existingArticles = JSON.parse(
    localStorage.getItem(localStorageKeys.BACKUP_ARTICLES) ?? '{}',
  ) as BackUpDataType;

  const uploadedArticles = getUploadedArticles(
    insertedArticles,
    existingArticles,
  );

  localStorage.setItem(
    localStorageKeys.BACKUP_ARTICLES,
    JSON.stringify({ data: uploadedArticles, localStorageVersion }),
  );
};

const getUploadedArticles = (
  insertedArticles: IProductionData['insertedArticles'],
  existingArticles: BackUpDataType,
) => {
  return Object.fromEntries(
    Object.entries(insertedArticles)?.map(([machineName, machineArticles]) => {
      return [
        machineName,
        Object.fromEntries(
          Object.entries(machineArticles)?.map(([date, dateArticlesRows]) => {
            return [
              date,
              dateArticlesRows?.map((articlesArray, rowIndex) => {
                return articlesArray
                  ?.map((article, tableIndex) => {
                    const { isUploaded } = article;

                    const existingArticle =
                      existingArticles?.data?.[machineName]?.[
                        date as DateStringType
                      ]?.[rowIndex]?.[tableIndex];

                    if (!isUploaded && existingArticle) {
                      return existingArticle;
                    }

                    return article;
                  })
                  .filter((article) => {
                    return article.isUploaded;
                  });
              }),
            ];
          }),
        ),
      ];
    }),
  );
};
