import toast from 'react-hot-toast';

import { nl } from '../../assets/text/nl';
import config from '../../config';
import { type ArticleDataType } from '../providers/ArticlesTableProvider/utils/ArticleTypes';
import { type ProductionPlanMachine } from '../providers/ProductionPlanProvider/types';
import { calculateArticlesLength } from './calculateArticlesHeight';

export const isArticleDropError = (
  siblingsCells: Array<ArticleDataType> | undefined,
  sharedData: ArticleDataType,
  { beamLength, machineCode }: ProductionPlanMachine,
  isHoliday: boolean,
) => {
  if (isHoliday) {
    toast.error(nl.isHolidayError);

    return true;
  }
  if (machineCode === config.allMachines) {
    toast.error(nl.selectMachineFirst);

    return true;
  }

  if (!sharedData.repeats) {
    toast.error(nl.notEnoughData);

    return true;
  }

  if (!Array.isArray(siblingsCells) || !siblingsCells.length) {
    return false;
  }

  if (
    siblingsCells.filter(
      (sibblingCell) => sibblingCell.articleCode === sharedData.articleCode,
    ).length >= parseInt(sharedData.repeats, 10)
  ) {
    toast.error(nl.tooManyRepeats);

    return true;
  }

  if (
    siblingsCells.some((siblingCell) => {
      return siblingCell.turn !== sharedData.turn;
    })
  ) {
    toast.error(nl.rotationNoMatch);

    return true;
  }

  if (
    siblingsCells.some((siblingCell) => {
      return siblingCell.height !== sharedData.height;
    })
  ) {
    toast.error(nl.heightNoMatch);
  }

  const articlesHeight =
    calculateArticlesLength(siblingsCells) +
    parseInt(sharedData.mouldLength, 10);

  if (articlesHeight > beamLength) {
    toast.error(nl.lengthExceeded);

    return true;
  }

  return false;
};
