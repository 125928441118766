export const nl = {
  hermes: 'Herman',
  plannen: 'Plannen',
  productieDossier: 'Productie Dossier',
  productieOrder: 'Productie Order',
  omschrijving: 'Omschrijving',
  availableMolds: 'Beschikbare mallen',
  week: 'Week',
  machine: 'Machine',
  am: 'A/M',
  ag: 'A/G',
  day: 'Dag',
  paletten: 'Paletten',
  artikel: 'Artikel',
  opmerking: 'Opmerking',
  login: 'Gebruikersnaam',
  enter: 'login',
  password: 'Wachtwoord',
  multiPerMouldError: 'Dit aantal is geen veelvoud van het aantal per mal',
  tooManyRepeats: 'Het maximum aantal herhalingen wordt overschreden',
  notEnoughData: 'Niet genoeg data',
  rotationNoMatch:
    'Dit artikel kan niet gepland worden. De draaivereisten komen niet overeen',
  heightNoMatch:
    'Opgelet: de hoogte van de artikels op deze lijn komen niet overeen',
  lengthExceeded: 'Lengte van de machine wordt overschreden',
  selectMachineFirst: 'Selecteer een machine om artikelen te kunnen plannen',
  p: 'P',
  moreThanNeeded: 'Maatwerk: meer gepland dan tekort',
  uploadModalCaption:
    'Gelieve te wachten op verwerking in Globis vooraleer verder te plannen.',
  uploadButton: 'Naar globis',
  oneSheet: 'per machine een blad A4',
  machinePark: 'MachinePark',
  planningWeek: 'Planning week',
  comments: 'Opmerking',
  shiftHeader: 'Planning verschuiven',
  shiftLabel: 'Geef datum op:',
  removeRow: 'Rij verwijderen',
  clearRow: 'Rij leegmaken',
  removeCell: 'Cel leegmaken',
  copyRow: 'Kopieer regel',
  pasteRow: 'Plak regel',
  addRowAbove: 'Regel boven invoegen',
  addRowBelow: 'Regel onder invoegen',
  shiftRow: 'Planning verschuiven...',
  isHolidayError: 'Sluitingsdag',
  no: 'Nee',
  sum: 'Gepland',
  yes: 'Ja',
};
