import { type IInsertedArticle } from '../providers/ProductionPlanProvider/types';

export type FilteredArticle = Partial<
  Record<`${number}/${number}/${number}`, IInsertedArticle[][]>
>;

export const filterArticlesByMachinePark = (
  articles: FilteredArticle,
  park: string | undefined,
): FilteredArticle => {
  if (!articles) {
    return {};
  }

  const entries = Object.entries(articles);

  const preparedEntries: (string | IInsertedArticle[][])[][] = entries
    .map(([key, rowArticles]) => {
      const preparedValue: IInsertedArticle[][] = [];

      if (!rowArticles || !rowArticles[0].length) {
        return [key, []];
      }

      for (let i = 0; i < rowArticles.length; i++) {
        for (let j = 0; j < rowArticles[i].length; j++) {
          if (
            rowArticles[i][j].machinePark === park &&
            rowArticles[i][j].isUploaded
          ) {
            preparedValue[i] = [...(preparedValue[i] ?? []), rowArticles[i][j]];
          }
        }
      }

      return [key, preparedValue];
    })
    .filter(Boolean);

  return Object.fromEntries(preparedEntries);
};
