import {
  type ArticlesDataAction,
  type IArticlesData,
  ArticlesDataActionType,
} from '../types';

export const articlesReducer = (
  state: IArticlesData,
  payload: ArticlesDataAction,
) => {
  const newState = ArticlesReducer(state, payload);

  return newState;
};

const ArticlesReducer = (
  state: IArticlesData,
  { type, payload }: ArticlesDataAction,
): IArticlesData => {
  switch (type) {
    case ArticlesDataActionType.SET_SEARCH_FIELDS:
      return {
        ...state,
        searchFields: payload,
      };

    case ArticlesDataActionType.SET_ALL_ARTICLES_DATA:
      return {
        ...state,
        allArticles: payload,
      };

    case ArticlesDataActionType.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case ArticlesDataActionType.SET_DICTIONARY:
      return {
        ...state,
        dictionary: payload,
      };

    case ArticlesDataActionType.SET_SELECTED_ROW:
      return {
        ...state,
        selectedRow: payload,
      };

    case ArticlesDataActionType.SET_SELECTED_HEADERS:
      return {
        ...state,
        selectedHeaders: payload,
      };

    case ArticlesDataActionType.SET_SORT:
      return {
        ...state,
        sort: payload,
      };

    default:
      return { ...state };
  }
};
