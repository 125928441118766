import {
  type ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';

import config from '../../config';
import { SharedTableDataProvider } from './SharedTableDataProvider';

interface ResizableLayoutProps {
  rigthChild?: ReactNode;
  leftChild?: ReactNode;
}

const { SCREEN_WIDTH_KEY } = config.localStorageKeys;

export const ResizableLayout = memo(
  ({ rigthChild, leftChild }: ResizableLayoutProps) => {
    const [leftSideWidth, setLeftSideWidth] = useState(
      localStorage.getItem(SCREEN_WIDTH_KEY) != null
        ? Number(localStorage.getItem(SCREEN_WIDTH_KEY))
        : Math.ceil(window.innerWidth / 3),
    );

    const divider = useRef<HTMLDivElement>();

    const { dividerWidth, headerHeight } = config;

    const handleMove = useCallback((e: MouseEvent) => {
      setLeftSideWidth(e.clientX);
    }, []);

    const handleMouseUp = useCallback(
      (e: MouseEvent) => {
        window.removeEventListener('mousemove', handleMove);

        localStorage.setItem(SCREEN_WIDTH_KEY, `${e.clientX}`);

        divider.current?.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('mouseup', handleMouseUp);
      },
      [handleMove],
    );

    const handleMouseDown = useCallback(() => {
      window.addEventListener('mousemove', handleMove);
      divider.current?.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mouseup', handleMouseUp);
    }, [handleMouseUp, handleMove]);

    useEffect(() => {
      const dividerNode = divider.current;

      dividerNode?.addEventListener('mousedown', handleMouseDown);

      return () => {
        dividerNode?.removeEventListener('mousedown', handleMouseDown);
      };
    }, [handleMouseDown]);

    return (
      <SharedTableDataProvider>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box
            sx={{
              width: leftSideWidth,
              boxSizing: 'border-box',
              px: '10px',
              overflow: 'clip',
            }}
          >
            {leftChild}
          </Box>
          <Box
            ref={divider}
            sx={(theme) => ({
              width: dividerWidth,
              height: `calc(100vh - ${headerHeight}px)`,
              background: theme.palette.grey?.[200],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'col-resize',
            })}
          >
            <Box
              sx={(theme) => ({
                width: 4,
                height: 38,
                background: theme.palette?.grey?.[400],
                borderRadius: '100px',
              })}
            />
          </Box>
          <Box
            sx={{
              width: `calc(100% - ${leftSideWidth + dividerWidth}px)`,
              boxSizing: 'border-box',
              px: '10px',
              overflow: 'scroll hidden',
            }}
          >
            {rigthChild}
          </Box>
        </Box>
      </SharedTableDataProvider>
    );
  },
);
