import { Box, Typography } from '@mui/material';

import { ABLogo, ABText, LogoutIcon } from '../../assets/icons';
import { nl } from '../../assets/text/nl';
import { useAuthContext } from '../../core/providers/AuthenticationProvider';

export const Header = () => {
  const { isLogedIn, logout } = useAuthContext();

  return (
    <Box
      sx={(theme) => ({
        height: 42,
        width: '100%',
        fontSize: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.grey?.[200]}`,
      })}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '10px', ml: '10px' }}
      >
        <ABLogo />
        <ABText />
      </Box>
      {isLogedIn && (
        <Box
          sx={{
            display: 'flex',
            mr: 2,
            alignItems: 'center',
            fontSize: '14px',
          }}
        >
          <Typography variant='body1' sx={{ mr: '11px' }}>
            {nl.hermes}
          </Typography>
          <LogoutIcon
            onClick={logout}
            sx={{ cursor: 'pointer', fontSize: 'inherit' }}
          />
        </Box>
      )}
    </Box>
  );
};
