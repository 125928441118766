import { useContext } from 'react';

import { ProductionPlanContext } from './ProductionPlanProvider';

export const useProductionPlan = () => {
  const ctx = useContext(ProductionPlanContext);

  if (ctx == null) {
    throw new Error('Wrap app with ProductionPlanProvider');
  }

  return ctx;
};
