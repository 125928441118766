import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { SplitScreenTables } from '../../screens/SplitScreenTables/SplitScreenTables';

export const AuthenticatedRoutes = memo(() => {
  return (
    <Routes>
      <Route path='/' element={<SplitScreenTables />} />
      <Route path='/*' element={<SplitScreenTables />} />
    </Routes>
  );
});
