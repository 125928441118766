import { useContext } from 'react';

import { SharedTableDataContext } from './SharedTableDataProvider';

export const useSharedTableDataContext = () => {
  const ctx = useContext(SharedTableDataContext);

  if (!ctx) {
    throw new Error(
      'Wrap ResizableLayout children with SharedTableDataProvider',
    );
  }

  return ctx;
};
