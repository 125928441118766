import { v4 } from 'uuid';

import {
  type IInsertedArticle,
  type IProductionData,
} from '../providers/ProductionPlanProvider/types';
import { type DateStringType } from './types';

type EntriesType = [DateStringType, IInsertedArticle[][]];

const initialValues: Array<EntriesType> = [];

export const addIdToArticles = (
  insertedArticles: IProductionData['insertedArticles'],
) => {
  const newArticles: IProductionData['insertedArticles'] = Object.fromEntries(
    Object.entries(insertedArticles).map(([machineKey, entry]) => {
      const entries = Object.entries(entry) as unknown as Array<EntriesType>;

      const filteredEntries = entries.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, dayEntry) => {
          const [day, dayValue] = dayEntry;

          const newDayValue = dayValue?.map((dayRows) =>
            dayRows.map((article) => {
              return {
                ...article,
                uuid: article.uuid || v4(),
              };
            }),
          );

          const newEntry = [day, newDayValue];

          return [...acc, newEntry];
        },
        initialValues,
      );

      const machineArticles = Object.fromEntries(filteredEntries);

      return [machineKey, machineArticles];
    }),
  );

  return newArticles;
};
