import { articlesHeadersTranslations } from '../../../translations/articlesDictionary';
import { type DictionaryType } from '../ArticlesTableProvider';
import { type ArticleDataType } from './ArticleTypes';

const haveTranslation = (
  key: string,
): key is keyof typeof articlesHeadersTranslations => {
  return articlesHeadersTranslations.hasOwnProperty(key);
};

export const prepareDictionary = (
  dataItem?: ArticleDataType,
): DictionaryType | undefined => {
  if (!dataItem) {
    return undefined;
  }

  return Object.keys(dataItem).reduce((acc, item) => {
    return {
      ...acc,
      [item]: haveTranslation(item) ? articlesHeadersTranslations[item] : item,
    };
  }, {} as DictionaryType);
};
