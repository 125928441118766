import { type FC, type PropsWithChildren } from 'react';
import { renderToString } from 'react-dom/server';
import { Box } from '@mui/material';

import { resizeSvg } from '../../../../../assets/icons/ResizeSVG';

export const ResizableHeadTableCell: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        pr: 3,
        resize: 'horizontal',
        overflow: 'hidden',
        minWidth: 'min-content',
        position: 'relative',
        '::-webkit-resizer': {
          display: 'none',
        },
        '&::after': {
          pointerEvents: 'none',
          position: 'absolute',
          height: '10px',
          width: '15px',
          textAlign: 'center',
          bottom: '-2px',
          right: '0px',
          content: '""',
          backgroundImage: `url('data:image/svg+xml;utf8,${renderToString(
            resizeSvg,
          )}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top right',
        },
      }}
    >
      {children}
    </Box>
  );
};
