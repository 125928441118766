import { type FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { nl } from '../../../assets/text/nl';
import { type ArticleDataType } from '../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { useProductionPlan } from '../../../core/providers/ProductionPlanProvider';
import { getMaxPalettesOverall } from '../utils/getMaxPalettesNumber';

interface DayTableProps {
  day: string;
  showHeader: boolean;
  currentLenght: number;
  rowIndex: number;
  articles: Array<ArticleDataType> | undefined;
}

export const DayTable: FC<DayTableProps> = ({
  day,
  showHeader,
  currentLenght,
  rowIndex,
  articles,
}) => {
  const {
    productionPlanData: { machine },
  } = useProductionPlan();

  return (
    <TableContainer sx={{ width: '155px' }}>
      <Table>
        <colgroup>
          <col style={{ width: '60%' }} />
          <col style={{ width: '40%' }} />
        </colgroup>
        {showHeader && (
          <TableHead sx={{ backgroundColor: 'secondary.100' }}>
            <TableRow>
              <TableCell>
                <Typography>{nl.day}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{nl.paletten}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          <TableRow>
            <TableCell>{rowIndex ? '' : day}</TableCell>
            <TableCell>
              {articles?.length ? getMaxPalettesOverall(articles) : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={(theme) => ({ background: theme.palette.grey[100] })}
            >
              <Typography>{`${currentLenght} / ${
                machine?.beamLength || 0
              }`}</Typography>
            </TableCell>
            <TableCell
              sx={(theme) => ({ background: theme.palette.grey[300] })}
            ></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
