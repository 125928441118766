import React, { type ChangeEvent, useState } from 'react';
import { Box, IconButton, Input, Typography } from '@mui/material';

import { Chevron } from '../../../assets/icons';
import { nl } from '../../../assets/text/nl';

interface WeekSelectProps {
  shownWeekNumber: number;
  changeWeek: (newWeek: number) => void;
}

export const WeekSelect = ({
  shownWeekNumber,
  changeWeek,
}: WeekSelectProps) => {
  const [emptyInput, setEmptyInput] = useState<string>();

  const handleWeekChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!ev.target.value) {
      changeWeek(0);

      return setEmptyInput('');
    }

    setEmptyInput(undefined);
    changeWeek(Number(ev.target.value) - 1);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Typography>{nl.week}</Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          borderTop: `1px solid ${theme.palette.grey?.[200]}`,
          borderBottom: `1px solid ${theme.palette.grey?.[200]}`,
          ml: '13px',
        })}
      >
        <IconButton
          onClick={() => {
            setEmptyInput(undefined);
            changeWeek(shownWeekNumber - 2);
          }}
          sx={(theme) => ({
            transform: 'rotate(90deg)',
            fontSize: '15px',
            background: theme.palette.primary.light,
          })}
        >
          <Chevron />
        </IconButton>
        <Box
          sx={{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Input
            sx={{
              '& .MuiInput-input': {
                textAlign: 'center',
                padding: 0,
              },
            }}
            type='number'
            disableUnderline
            onBlur={() => setEmptyInput(undefined)}
            value={emptyInput ?? shownWeekNumber}
            onChange={handleWeekChange}
          />
        </Box>
        <IconButton
          onClick={() => {
            setEmptyInput(undefined);
            changeWeek(shownWeekNumber);
          }}
          sx={(theme) => ({
            transform: 'rotate(-90deg)',
            fontSize: '15px',
            background: theme.palette.primary.light,
          })}
        >
          <Chevron />
        </IconButton>
      </Box>
    </Box>
  );
};
