import React, { memo } from 'react';
import { type CheckboxProps, Box, Checkbox } from '@mui/material';

import { CheckIcon } from '../../assets/icons';

export const CustomCheckbox = memo((props: CheckboxProps) => {
  const icon = (
    <Box
      sx={(theme) => ({
        background: theme.palette.primary.light,
        width: '18px',
        height: '18px',
        borderRadius: '2px',
        border: `1px solid ${theme.palette.grey?.[200]}`,
      })}
    />
  );

  const checkedIcon = (
    <Box
      sx={(theme) => ({
        background: theme.palette.primary.light,
        width: '18px',
        height: '18px',
        borderRadius: '2px',
        border: `1px solid ${theme.palette.grey?.[200]}`,
        color: theme.palette.primary.contrastText,
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <CheckIcon fontSize='inherit' />
    </Box>
  );

  return <Checkbox icon={icon} checkedIcon={checkedIcon} {...props} />;
});
