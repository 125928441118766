import { type FC, useCallback, useEffect, useState } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';

import { CheckIcon, CloseIcon } from '../../../../assets/icons';
import config from '../../../../config';
import { logError } from '../../../../core/helpers/logError';
import { type BackUpDataType } from '../../../../core/providers/ArticlesTableProvider/utils/saveBackUpArticles';
import { useProductionPlan } from '../../../../core/providers/ProductionPlanProvider';
import {
  type UploadResponseErrorType,
  type UploadResponseType,
  type UploadsStateType,
} from '../../../../core/types/UploadTypes';
import { TableLoading } from '../../../TableLoading/TableLoading';
import {
  type UploadTableRowDataType,
  orderedUploadTableCells,
  uploadTableColumnLength,
} from '../UploadArticlesModal';

interface IProps {
  article: UploadsStateType[0][0];
  request: UploadsStateType[1][0];
  backUpArticles: BackUpDataType;
}

const { articleCodeName } = config.fields;

export const UploadArticleItem: FC<IProps> = ({
  article,
  backUpArticles,
  request,
}) => {
  const [data, setData] = useState<UploadResponseType | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const { changeInsertedArticle, removeInsertedArticle } = useProductionPlan();

  const getToBackUp = useCallback(() => {
    if (!backUpArticles) {
      return;
    }

    const {
      rawArticle: { row },
      machine,
      production_date: productionDate,
    } = article;

    article.rowIndexList.forEach(({ quantity, index, uuid }) => {
      if (!parseInt(quantity, 10)) {
        removeInsertedArticle({
          row,
          uuid,
          machineKey: machine,
          day: productionDate,
          tableIndex: index,
        });

        return;
      }

      const selectedItem =
        backUpArticles.data?.[machine]?.[productionDate]?.[row]?.[index];

      if (
        !selectedItem ||
        selectedItem.articleCode !== article.rawArticle.articleCode
      ) {
        return;
      }

      changeInsertedArticle({
        row,
        tableIndex: index,
        machineKey: machine,
        key: productionDate,
        article: { ...selectedItem, uuid },
      });
    });
  }, [article, backUpArticles, changeInsertedArticle, removeInsertedArticle]);

  useEffect(() => {
    try {
      request.then((res) => {
        setData(res);
        setIsLoading(false);
      });
    } catch (e: unknown) {
      getToBackUp();
      logError(e);
    }
  }, [article, getToBackUp, request]);

  const preparedData = prepareUploadData(data);

  useEffect(() => {
    if (!data || isUploadError(data)) {
      if (!isLoading) {
        getToBackUp();
      }

      return;
    }

    const { row, ...articleToChange } = article.rawArticle;

    article.rowIndexList.forEach(({ index, quantity, uuid }) => {
      if (!parseInt(quantity, 10)) {
        removeInsertedArticle({
          row,
          uuid,
          machineKey: article.machine,
          day: article.production_date,
          tableIndex: index,
        });

        return;
      }

      changeInsertedArticle({
        row,
        tableIndex: index,
        machineKey: article.machine,
        key: article.production_date,
        article: {
          ...articleToChange,
          uuid,
          isUploaded: true,
          timesToBeProduced: quantity,
          productionNumber: preparedData.productionNumber,
          machinePlanningId: preparedData.machinePlanningId ?? '',
        },
      });
    });
  }, [
    article.machine,
    article.production_date,
    article.rawArticle,
    changeInsertedArticle,
    preparedData.productionNumber,
    data,
    article.rowIndexList,
    preparedData.machinePlanningId,
    getToBackUp,
    isLoading,
    article.quantity,
    removeInsertedArticle,
    article.article,
  ]);

  if (isLoading) {
    return <TableLoading columnsLength={uploadTableColumnLength} />;
  }

  const tableData: UploadTableRowDataType = {
    [articleCodeName]: article.article,
    machine: article.machine,
    productionNumber: preparedData.productionNumber ?? '',
    machinePlanningId: preparedData.machinePlanningId ?? '',
    date: article.production_date,
    status: preparedData.status ?? '',
    info: preparedData.info ?? '',
  };

  return (
    <TableRow sx={{ width: '100%' }}>
      {orderedUploadTableCells.map((key) => {
        return (
          <TableCell sx={{ textAlign: 'center', lineHeight: '30px' }} key={key}>
            {mapTableData(tableData, key)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const prepareUploadData = (data: UploadResponseType | undefined) => {
  if (!data) {
    return {};
  }

  if (isUploadError(data)) {
    const selectedData = data?.[0] ?? {};
    const message =
      'error' in selectedData
        ? selectedData?.message
        : selectedData?.response?.body?.errors?.[0]?.Message;

    return {
      status: 'error',
      info: message,
    };
  }

  return {
    status: 'success',
    info: data[0]?.message,
    productionNumber: data[0]?.production_number,
    machinePlanningId: data[0]?.machine_planning_id,
  };
};

const mapTableData = (
  tableData: UploadTableRowDataType,
  key: keyof UploadTableRowDataType,
) => {
  const statusValueSuccess = tableData.status === 'success';
  switch (key) {
    case articleCodeName:
      return (
        <Typography sx={{ fontWeight: '600', letterSpacing: '0.5px' }}>
          {tableData[key]}
        </Typography>
      );
    case 'status':
      return statusValueSuccess ? (
        <CheckIcon color='primary' />
      ) : (
        <CloseIcon color='error' />
      );

    case 'info':
      return (
        <Typography sx={{ color: statusValueSuccess ? 'green' : 'red' }}>
          {tableData[key]}
        </Typography>
      );
    default:
      return <Typography>{tableData[key]}</Typography>;
  }
};

const isUploadError = (
  data: Exclude<UploadResponseType, null>,
): data is UploadResponseErrorType => {
  const selectedData = data?.[0] ?? {};
  const hasError = 'error' in selectedData;
  const hasExternalError = 'external error' in selectedData;

  return hasExternalError || (hasError && selectedData.error === 'true');
};
