import { type Page, Font, StyleSheet } from '@react-pdf/renderer';

import InterBold from '../../assets/fonts/Inter-Bold.ttf';
import InterItalic from '../../assets/fonts/Inter-Italic.ttf';
import InterRegular from '../../assets/fonts/Inter-Regular.ttf';

Font.register({
  family: 'Inter',
  src: InterRegular,
});

Font.register({
  family: 'Inter',
  src: InterItalic,
  fontStyle: 'italic',
});

Font.register({
  family: 'Inter',
  src: InterBold,
  fontWeight: 'bold',
});

export const documentProps = {
  title: 'AndersBeton',
  author: 'AndersBeton',
  language: 'nl-NL',
  pageLayout: 'singlePage',
};

export const pageProps: typeof Page['prototype']['props'] = {
  orientation: 'landscape',
};

export const PDFStyles = StyleSheet.create({
  page: {
    padding: '2cm 0.7cm',
    fontSize: '8pt',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  marginRow: {
    marginBottom: '0.5cm',
  },
  paddingRow: {
    paddingBottom: '0.15cm',
    paddingTop: '0.15cm',
  },
  divider: {
    borderBottom: '0.3mm',
  },
  dividerTop: {
    borderTop: '0.3mm',
  },
  marginArticle: {
    marginBottom: '0.3cm',
  },
  startCell: {
    width: '8%',
  },
  emptyCell: {
    width: '9.2%',
  },
  baseCell: {
    width: '15%',
  },
  bold: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
  },
  italic: {
    fontFamily: 'Inter',
    fontStyle: 'italic',
  },
  styleComment: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingRight: '3cm',
    paddingLeft: '2.62cm',
    alignItems: 'flex-start',
    paddingTop: '0.3cm',
    paddingBottom: '0.3cm',
  },
  flexGrow: {
    flexGrow: 1,
  },
  paddingDayArticlesTop: {
    paddingTop: '3mm',
  },
  articleBlock: {
    flex: '1 1',
    padding: '0 4pt',
    display: 'flex',
    minWidth: '15%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  image: {
    marginBottom: 10,
    height: 100,
    width: 200,
  },
  articleRow: {
    marginLeft: '0.6cm',
    marginBottom: '0.3cm',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    width: '100%',
  },
});
