import { v4 } from 'uuid';

import {
  type IInsertedArticlesPayload,
  type IProductionData,
} from '../providers/ProductionPlanProvider/types';

export const setStateArticle = (
  state: IProductionData,
  { machineKey, key, article, row }: IInsertedArticlesPayload,
) => {
  const day = [...(state.insertedArticles[machineKey]?.[key] ?? [])];

  day[row] = [...(day[row] || []), { ...article, uuid: article.uuid || v4() }];

  return {
    ...state,
    insertedArticles: {
      ...state.insertedArticles,
      [machineKey]: {
        ...(state.insertedArticles?.[machineKey] ?? {}),
        [key]: day,
      },
    },
  };
};
