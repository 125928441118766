import {
  getWeekNumber,
  weekCountInYear,
} from '../../ProductionPlanProvider/utils/getWeekNumber';
import { getIntegerRange } from './getIntegerRange';
import { type RawArticlesDataType, ProductionStatus } from './getTableData';

export const getStockOrderShortages = (
  dataItem: RawArticlesDataType,
  weeks: number | undefined,
) => {
  const productions = dataItem.productions;

  if (!productions.length) {
    return { data: 0 };
  }

  const availableStock = parseInt(dataItem.available_stock || '0', 10);
  const weekNumber =
    typeof weeks === 'number' ? getWeekNumber(new Date(), weeks) : Infinity;

  const { orderAmount, amountInProduction } = productions.reduce(
    (acc, curr) => {
      const {
        production_status: productionStatus,
        quantity_to_produce: quantityToProduce,
        week,
        year,
      } = curr || {};

      if (!week || !year) {
        return acc;
      }

      const currentYear = new Date().getFullYear();
      const intProductionWeek = parseInt(week, 10);
      const intProductionYear = parseInt(year, 10);

      const preparedWeekRange =
        intProductionYear > currentYear
          ? getIntegerRange(currentYear, intProductionYear - 1)
              .map(weekCountInYear)
              .reduce((accCount, currentItem) => accCount + currentItem, 0)
          : 0;

      const productionWeek =
        intProductionYear < currentYear
          ? -1
          : preparedWeekRange + intProductionWeek;

      const isAvailable = productionWeek <= weekNumber;

      if (productionStatus === ProductionStatus.OnSalesorder && isAvailable) {
        return {
          ...acc,
          orderAmount: acc.orderAmount + (quantityToProduce ?? 0),
        };
      }

      if (productionStatus === ProductionStatus.Planned && isAvailable) {
        return {
          ...acc,
          amountInProduction: acc.amountInProduction + (quantityToProduce ?? 0),
        };
      }

      return acc;
    },
    {
      orderAmount: 0,
      amountInProduction: 0,
    },
  );

  const shortage = orderAmount - availableStock - amountInProduction;
  return {
    data: shortage <= 0 ? '0' : shortage,
    stockIsEnough: orderAmount - availableStock <= 0,
  };
};
