import { type SvgIconProps } from '@mui/material';

import { NoFillSvgIcon } from '../NoFillSvgIcon';

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <NoFillSvgIcon {...props} width='9' height='8' viewBox='0 0 9 8'>
      <path
        opacity='.6'
        d='m7.906 7.434-1.96-1.958A3.35 3.35 0 0 0 3.353 0a3.35 3.35 0 0 0-2.37.982A3.352 3.352 0 0 0 0 3.352a3.356 3.356 0 0 0 3.353 3.352c.775 0 1.524-.269 2.123-.76l1.958 1.959a.33.33 0 0 0 .47 0 .33.33 0 0 0 .002-.469ZM6.042 3.352A2.692 2.692 0 0 1 3.353 6.04 2.692 2.692 0 0 1 .665 3.35 2.692 2.692 0 0 1 3.353.664a2.692 2.692 0 0 1 2.69 2.689Z'
        fill='#000'
      />
    </NoFillSvgIcon>
  );
};
