import { type IInsertedArticle, type IProductionData } from '../types';

export const getAllInsertedArticles = (
  insertedArticles: IProductionData['insertedArticles'],
): IInsertedArticle[] => {
  const value = Object.values(insertedArticles)
    .map((machine) => Object.values(machine))
    .flat(3);

  const filteredValue = value.filter((val) => val);

  return filteredValue as IInsertedArticle[];
};
