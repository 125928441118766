import config from '../../../../config';
import { getUTCDate } from '../../../helpers/getUTCDate';

const { weeksAfterNeedDeletion } = config;

export const isDateThreeWeeksAgo = (date: string): boolean => {
  const [day, month, year] = date.split('/');

  const inputDate = new Date(
    Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)),
  );
  const currentDate = getUTCDate(new Date());

  const diffInMilliseconds = currentDate.getTime() - inputDate.getTime();

  //  the difference in weeks
  const diffInWeeks = diffInMilliseconds / (1000 * 60 * 60 * 24 * 7);

  return diffInWeeks > weeksAfterNeedDeletion;
};
