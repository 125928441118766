import { memo, useState } from 'react';
import { Box } from '@mui/material';

import { PlanningCalendar } from './components/PlanningCalendar';
import { ProductionPlanOverheadButtons } from './components/ProductionPlanOverheadButtons';
import { useContextMenuContext } from '../../core/providers/ContextMenuProvider';

export const ProductionPlan = memo(() => {
  const [showAm, setShowAm] = useState(true);

  const { setIsMenuVisible } = useContextMenuContext();

  return (
    <Box
      sx={{ position: 'relative', height: 'calc(100% - 4px)' }}
      onClick={() => setIsMenuVisible?.(false)}
    >
      <ProductionPlanOverheadButtons setShowAm={setShowAm} />
      <PlanningCalendar showAm={showAm} />
    </Box>
  );
});
