import { TableCell, Typography } from '@mui/material';

import {
  isArticlesDataIndex,
  offHeaders,
} from '../../../../../core/providers/ArticlesTableProvider';
import { type ArticleDataType } from '../../../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { parseArticleCellValue } from '../helpers/parseArticleCellValue';

interface CellProps {
  headerCells: string[];
  rowValues: ArticleDataType;
  isSelected: boolean;
}

export const Cell = ({ headerCells, rowValues }: CellProps) => {
  return (
    <>
      {headerCells.map((key: string) => {
        if (!isArticlesDataIndex(rowValues, key) || offHeaders.includes(key)) {
          return null;
        }

        const value = parseArticleCellValue(rowValues, key);

        const showedValue = typeof value !== 'object' ? value : null;

        return (
          <TableCell
            key={`${key}-${rowValues.id}`}
            component='th'
            scope='row'
            sx={{
              fontWeight: 500,
              fontSize: 15,
              p: '16px 0 16px 20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography variant='body1'>{showedValue}</Typography>
          </TableCell>
        );
      })}
    </>
  );
};
