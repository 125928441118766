import { type SvgIconProps } from '@mui/material';

import { NoFillSvgIcon } from '../NoFillSvgIcon';

export const Chevron = (props: SvgIconProps) => {
  return (
    <NoFillSvgIcon {...props} width='13' height='8' viewBox='0 0 13 8'>
      <path
        d='M6.125 7.125a.913.913 0 0 1-.629-.246l-5.25-5.25A.913.913 0 0 1 0 1C0 .763.082.553.246.371A.913.913 0 0 1 .875.125c.237 0 .447.082.629.246L6.125 5.02 10.746.37a.913.913 0 0 1 .629-.246c.237 0 .447.082.629.246a.912.912 0 0 1 .246.63.912.912 0 0 1-.246.629l-5.25 5.25a.913.913 0 0 1-.629.246Z'
        fill='currentColor'
      />
    </NoFillSvgIcon>
  );
};
// to achieve differen chevron directions use css rotate(deg) property
