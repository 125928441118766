import { type FC, memo } from 'react';
import { Box, Table, TableBody, TableContainer } from '@mui/material';

import { BodyCells } from './components/BodyCells';
import { DraggableArticleNumber } from './components/DraggableArticleNumber';
import { HeaderCellsArticlesTable } from './components/HeaderCellsArticlesTable';
import { SearchCells } from './components/SearchCells';
import config from '../../../../config';
import { useArticlesContext } from '../../../../core/providers/ArticlesTableProvider';
import { type ArticleDataTypeKeys } from '../../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { TableLoading } from '../../../TableLoading/TableLoading';

export type SearchFields = Partial<Record<ArticleDataTypeKeys, string>>;

const { articleCodeName, ordertype } = config.fields;

export const ArticlesTable: FC = memo(() => {
  const {
    articlesData: {
      filteredArticles,
      selectedHeaders,
      selectedRow,
      searchFields,
      loading: articlesLoading,
    },
    setSelectedRow,
    setSearchFields,
  } = useArticlesContext();

  const manageSearchFields = (value: string, cell: string) => {
    setSearchFields({ ...searchFields, [cell]: value });
  };

  return (
    <Box>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
        <Table aria-label='simple-table'>
          <HeaderCellsArticlesTable />
          <TableBody>
            <SearchCells
              headerCells={selectedHeaders}
              manageSearchFields={manageSearchFields}
              searchFields={searchFields}
            />
            {articlesLoading ? (
              <TableLoading columnsLength={selectedHeaders.length} />
            ) : (
              filteredArticles?.map((rowValues, index) => {
                return (
                  <BodyCells
                    key={
                      rowValues[articleCodeName] + rowValues[ordertype] + index
                    }
                    headerCells={selectedHeaders}
                    rowValues={rowValues}
                    selectRow={() => setSelectedRow(rowValues)}
                    isSelected={
                      selectedRow?.[articleCodeName] ===
                      rowValues[articleCodeName]
                    }
                  />
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DraggableArticleNumber />
    </Box>
  );
});
