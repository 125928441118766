import {
  type IAddRowPayload,
  type IProductionData,
} from '../providers/ProductionPlanProvider/types';

export const addStateRow = (
  state: IProductionData,
  payload: IAddRowPayload,
) => {
  const { row, day, inx } = payload;

  if (!state.machine) {
    return {
      ...state,
    };
  }

  const { machineCode } = state.machine;

  const selectedDay = [...(state.insertedArticles[machineCode]?.[day] ?? [])];

  if (row === null) {
    selectedDay.splice(inx, 1);
  } else {
    selectedDay.splice(inx, row ? 1 : 0, row || []);
  }

  return {
    ...state,
    insertedArticles: {
      ...state.insertedArticles,
      [machineCode]: {
        ...(state.insertedArticles?.[machineCode] ?? {}),
        [day]: selectedDay,
      },
    },
  };
};
