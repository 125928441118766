import { type IInsertedArticle } from '../providers/ProductionPlanProvider/types';

export const prepareRowForPaste = (
  row: IInsertedArticle[] | undefined | null,
) => {
  if (!row) {
    return row;
  }

  return row.map((article) => {
    const {
      productionNumber,
      isUploaded,
      machinePlanningId,
      ...articleToUpload
    } = article;

    return articleToUpload;
  });
};
