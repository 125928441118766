import JsBarcode from 'jsbarcode';

import { type AllBarCodes } from '../providers/ArticlesTableProvider';
import { type BarCodeType } from '../providers/ArticlesTableProvider/types';
import { type FilteredArticle } from './filterArticlesByMachinePark';
import { type DateStringType } from './types';

type BarCodes = Record<
  DateStringType,
  Array<Array<Record<BarCodeType, Array<string>>>>
>;

export const getBarCodeData = (
  allBarCodes: AllBarCodes,
  preparedMachineArticles: FilteredArticle,
  containerRoot: HTMLElement,
) => {
  const barCodes: BarCodes = Object.entries(preparedMachineArticles).reduce(
    (acc, [date, articleRows]) => {
      const BarCodesRows = articleRows?.map((articleRow) => {
        return articleRow.map((article) => {
          const selectedProductionNumber = article.productionNumber ?? '';

          const selectedBarCodes =
            allBarCodes[article.articleCode]?.[selectedProductionNumber];

          return selectedBarCodes ?? {};
        });
      });

      return {
        ...acc,
        [date]: BarCodesRows,
      };
    },
    {},
  );

  const barCodeData: BarCodes = Object.entries(barCodes).reduce(
    (acc, [date, articleRows]) => {
      const newArticleRows = articleRows.map((articleRow) => {
        return articleRow?.map((barCode) => {
          return Object.entries(barCode).reduce(
            (acc2, [barCodeType, barCodeArray]) => {
              const barCodeEan = barCodeArray.map((eanCode) => {
                const newElement = document.createElement('img');

                newElement.setAttribute('id', `${barCodeType}_${eanCode}`);

                containerRoot.appendChild(newElement);

                const newImage = document.getElementById(
                  `${barCodeType}_${eanCode}`,
                ) as HTMLImageElement | null;

                JsBarcode(newImage, eanCode);

                const imageSrc = newImage?.src;

                newImage?.remove();

                return imageSrc;
              });

              return {
                ...acc2,
                [barCodeType]: barCodeEan,
              };
            },
            {},
          );
        });
      });

      return {
        ...acc,
        [date]: newArticleRows,
      };
    },
    {},
  );

  return barCodeData;
};
