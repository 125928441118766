import config from '../../../../config';
import { logError } from '../../../helpers/logError';
import request from '../../../helpers/request';
import { type MachinesParkResponseType } from '../types';
import prepareMachinePark from './prepareMachinePark';

export const getAllMachinesParks = async () => {
  let response: MachinesParkResponseType | null = null;

  try {
    response = await request<MachinesParkResponseType>(
      config.routes.machinePark,
      {
        executionToken: 'machinePark',
      },
    );
  } catch (error) {
    logError(error);
  }

  return prepareMachinePark(response);
};
