import { type IInsertedArticle, type IProductionData } from '../types';
import { getAllInsertedArticles } from './getAllInsertedArticles';

export const isMoreThanNeeded = (
  state: IProductionData,
  article: IInsertedArticle,
) => {
  const { insertedArticles } = state;
  const { productionQuantityToProduce } = article;

  const toBeProducedSum = getAllInsertedArticles(insertedArticles).reduce(
    (acc, curr) => {
      if (article.id !== curr.id) {
        return acc;
      }

      return (acc += parseInt(curr.timesToBeProduced || '0', 10));
    },
    0,
  );

  return (
    productionQuantityToProduce &&
    toBeProducedSum > parseInt(productionQuantityToProduce, 10)
  );
};
