import { type FC } from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';

import { type PreparedDocumentData } from './hooks/usePDFRender';
import { nl } from '../../assets/text/nl';
import { getMaxPalettesNumber } from '../../components/ProductionPlan/utils/getMaxPalettesNumber';
import { getPalletesNumber } from '../../components/ProductionPlan/utils/getPalletesNumber';
import config from '../../config';
import { type BarCodeType } from '../providers/ArticlesTableProvider/types';
import { documentProps, pageProps, PDFStyles } from './basePDFDocumentProps';
import { dateFormat } from './dateFormat';
import { typedArrayDates } from './typedArrayDates';

interface IProps {
  data: PreparedDocumentData;
}

const NLWeekDays = [
  'Zondag',
  'Maandag',
  'Dinsdag',
  'Woensdag',
  'Donderdag',
  'Vrijdag',
  'Zaterdag',
];

const {
  page,
  row,
  baseCell,
  startCell,
  bold,
  marginRow,
  paddingRow,
  divider,
  styleComment,
  flexGrow,
  dividerTop,
  paddingDayArticlesTop,
  articleRow,
  articleBlock,
  emptyCell,
} = PDFStyles;

const {
  eanCodes: { types: codeTypes },
} = config;

const PDFDocument: FC<IProps> = ({ data }) => {
  return (
    <Document {...documentProps}>
      {data.map((document) => {
        const articleKeys = typedArrayDates(document.days);

        const articles = document.articles;

        return (
          <Page
            {...pageProps}
            key={`${document.machinePark}-${document.machineCode}`}
            style={page}
          >
            <View style={[row, marginRow]}>
              <Text style={[startCell, bold]}>{nl.planningWeek}</Text>
              <Text style={emptyCell} />
              <Text style={baseCell}>{document.planningWeek}</Text>
              <Text>{dateFormat(new Date())}</Text>
            </View>
            <View style={[row, marginRow]}>
              <Text style={[startCell, bold]}>{nl.machinePark}:</Text>
              <Text style={emptyCell} />
              <Text>{document.machinePark}</Text>
            </View>
            <View style={[row, divider]}>
              <Text style={[startCell, bold]}>{document.machineCode}</Text>
              <Text style={[startCell, bold, { textAlign: 'center' }]}>
                {nl.paletten}
              </Text>
            </View>
            <View>
              {articleKeys.map((articleKey, id) => {
                const [day, month, year] = articleKey.split('/');
                const computedDay = new Date(
                  Date.UTC(
                    parseInt(year, 10),
                    parseInt(month, 10) - 1,
                    parseInt(day, 10),
                  ),
                ).getUTCDay();
                const dayArticlesRows = articles?.[articleKey];
                const rowComments = document.machineComments?.[articleKey];
                const dayBarCodes = document.barCodeData?.[articleKey];

                return (
                  <View
                    style={[paddingRow, id === 0 ? {} : dividerTop, row]}
                    wrap={false}
                    key={`${articleKey}: ${id}`}
                  >
                    <View style={[startCell]}>
                      <Text>{NLWeekDays[computedDay]}</Text>
                      <Text>{`${day}/${month}/${year}`}</Text>
                    </View>
                    <View style={[flexGrow]}>
                      {dayArticlesRows?.map((dayArticles, idx) => {
                        const bottomDivider = {
                          borderBottom:
                            dayArticlesRows.length - 1 === idx
                              ? undefined
                              : '0.3mm',
                        };
                        const rowBarCodes = dayBarCodes?.[idx] ?? [];

                        return (
                          <View
                            style={[bottomDivider, paddingDayArticlesTop]}
                            key={idx}
                          >
                            <View style={[row, { width: '100%' }]}>
                              <Text
                                style={[{ textAlign: 'center', width: '8%' }]}
                              >
                                {getMaxPalettesNumber(dayArticles)}
                              </Text>
                              <View style={articleRow} wrap={false}>
                                {[...new Array(config.articlesToBePlanned)].map(
                                  (_, articleIndex) => {
                                    const rowArticle =
                                      dayArticles[articleIndex];

                                    if (!rowArticle) {
                                      return (
                                        <View
                                          key={articleIndex}
                                          style={articleBlock}
                                        />
                                      );
                                    }

                                    const { articleCode, articleDescription } =
                                      rowArticle;
                                    const palettesNumber =
                                      getPalletesNumber(rowArticle);

                                    return (
                                      <View
                                        key={`${rowArticle.id} - ${articleIndex}`}
                                        style={articleBlock}
                                      >
                                        <Text style={{ marginBottom: '4pt' }}>
                                          {`${articleCode}${
                                            palettesNumber
                                              ? ` - ${palettesNumber}`
                                              : ''
                                          }`}
                                        </Text>
                                        <Text style={{ maxWidth: '100%' }}>
                                          {articleDescription.trimEnd()}
                                        </Text>
                                      </View>
                                    );
                                  },
                                )}
                              </View>
                            </View>
                            {createBarCodesRow(
                              rowBarCodes,
                              codeTypes.profilering,
                            )}
                            {createBarCodesRow(rowBarCodes, codeTypes.fill)}
                            {createBarCodesRow(rowBarCodes, codeTypes.label)}
                            {rowComments?.[idx] && (
                              <Text style={styleComment}>
                                {nl.comments}: {rowComments[idx]}
                              </Text>
                            )}
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

const createBarCodesRow = (
  rowBarCodes: Record<BarCodeType, string[]>[],
  type: BarCodeType,
) => {
  const codesExist = rowBarCodes?.some((barCode) => type in barCode);

  if (!codesExist) {
    return null;
  }

  return (
    <View style={[row, { alignItems: 'center' }]}>
      <Text style={startCell} hyphenationCallback={hyphenationCallback}>
        {type}
      </Text>
      {rowBarCodes.map((barCodeGroup, groupIndx) => {
        if (!barCodeGroup[type]) {
          return <View style={baseCell} key={groupIndx} />;
        }

        return barCodeGroup[type]?.map((imageSrc, indx) => {
          return (
            <View style={baseCell} key={indx}>
              <Image
                src={imageSrc}
                style={[
                  {
                    textAlign: 'center',
                    width: '100px',
                    height: '50px',
                  },
                ]}
              />
            </View>
          );
        });
      })}
    </View>
  );
};

const hyphenationCallback = (word: string) => {
  return [word];
};

export default PDFDocument;
