import { devRoutes, devTokens, prodRoutes, prodTokens } from './tokens';

const isProd = process.env.REACT_APP_PROD_ENV === 'true';

const config = {
  executionToken: isProd ? prodTokens : devTokens,
  // : { ...prodTokens, sendArticles: devTokens.sendArticles },
  routes: isProd ? prodRoutes : devRoutes,
  // : { ...prodRoutes, sendArticles: devRoutes.sendArticles },
  productionPlan: {},
  fields: {
    articleCodeName: 'articleCode',
    ordertype: 'orderType',
  },
  localStorageKeys: {
    AUTH_STORAGE_KEY: 'AUTH_STATE_BOOL',
    LOGIN_ERROR_TOAST_ID: 'ERROR',
    SCREEN_WIDTH_KEY: 'SCREEN_WIDTH',
    PRODUCTION_PLAN_DATA: 'PRODUCTION_PLAN_DATA',
    BACKUP_ARTICLES: 'BACKUP_ARTICLES',
  },
  statuses: {
    MAKE_TO_ORDER: 'Make to Order',
  },
  availableMoldsTableLength: 4,
  yearsToBeShown: 5,
  articlesToBePlanned: 6,
  allMachineParkKey: 'all',
  dividerWidth: 10,
  headerHeight: 43,
  allMachines: `all`,
  // change the version when the structure is not compatible with user saved data
  localStorageVersion: '0.03',
  weeksAfterNeedDeletion: 3,
  eanCodes: {
    types: {
      profilering: 'BETON PROFILERING',
      fill: 'BETON GIETEN',
      label: 'LABELLEN + QC',
    },
  },
} as const;

export default config;
