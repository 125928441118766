import { type Theme, createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import button from './button';
import iconButton from './iconButton';
import input from './input';
import menu from './menu';
import outlinedInput from './outlinedInput';
import select from './select';
import table from './table';

const overrides = (theme: Theme): Theme =>
  createTheme(
    deepmerge(theme, {
      components: {
        ...iconButton(theme),
        ...button(theme),
        ...select(theme),
        ...outlinedInput(),
        ...menu(theme),
        ...table(theme),
        ...input(),
      },
    }),
  );

export default overrides;
