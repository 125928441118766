import { type FC } from 'react';
import { Box } from '@mui/material';

import config from '../../../../../config';
import useMousePosition from '../../../../../core/helpers/hooks/useMousePosition';
import { useSharedTableDataContext } from '../../../../ResizableLayout/SharedTableDataProvider';

export const DraggableArticleNumber: FC = () => {
  const { x: mouseXPosition, y: mouseYPosition } = useMousePosition();

  const { sharedData } = useSharedTableDataContext();

  if (!sharedData) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        display:
          mouseXPosition !== 0 && mouseYPosition !== 0 ? 'block' : 'none',
        padding: '5px',
        zIndex: 100,
        pointerEvents: 'none',
        position: 'fixed',
        top: mouseYPosition - 20,
        left: mouseXPosition - 20,
        background: theme.palette.secondary.main,
        border: `1.5px dashed ${theme.palette.primary.main}`,
      })}
    >
      {sharedData?.[config.fields.articleCodeName]}
    </Box>
  );
};
