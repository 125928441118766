import { useCallback } from 'react';
import { TableRow } from '@mui/material';

import { type ArticleDataType } from '../../../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { itHasShortages } from '../../../../../core/providers/ArticlesTableProvider/utils/itHasShortages';
import { useSharedTableDataContext } from '../../../../ResizableLayout/SharedTableDataProvider';
import { Cell } from './Cell';

interface BodyCellsProps {
  headerCells: string[];
  isSelected: boolean;
  rowValues: ArticleDataType;
  selectRow: () => void;
}

export const BodyCells = ({
  rowValues,
  headerCells,
  isSelected,
  selectRow,
}: BodyCellsProps) => {
  const { setSharedData, sharedData } = useSharedTableDataContext();

  const handleMouseUp = useCallback(() => {
    setSharedData(null);

    window.removeEventListener('mouseup', handleMouseUp);
  }, [setSharedData]);

  const handleMouseDown = () => {
    setSharedData(rowValues);
    selectRow();

    window.addEventListener('mouseup', handleMouseUp);
  };

  let backgroundColor: string;

  if (!itHasShortages(rowValues)) {
    backgroundColor = '#EFEFEF';
  } else if (rowValues.hasPlannedProduction) {
    backgroundColor = '#009de236';
  }

  return (
    <TableRow
      onMouseDown={(e) => {
        e.preventDefault();
        handleMouseDown();
      }}
      sx={(theme) => ({
        backgroundColor,
        height: '27px',
        cursor: 'pointer',
        position: 'relative',
        border: isSelected
          ? `1.5px dashed ${theme.palette.primary.main}`
          : undefined,
        boxSizing: rowValues === sharedData ? 'border-box' : undefined,
      })}
    >
      <Cell
        headerCells={headerCells}
        rowValues={rowValues}
        isSelected={isSelected}
      />
    </TableRow>
  );
};
