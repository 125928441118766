import { Box, Input, TableCell, TableRow } from '@mui/material';

import { SearchIcon } from '../../../../../assets/icons';
import { type SelectedHeadersType } from '../../../../../core/providers/ArticlesTableProvider';
import { type SearchFields } from '../ArticlesTable';

interface SearchCellsProps {
  headerCells: SelectedHeadersType;
  manageSearchFields: (value: string, cell: string) => void;
  searchFields: SearchFields;
}

export const SearchCells = ({
  headerCells,
  manageSearchFields,
  searchFields,
}: SearchCellsProps) => {
  return (
    <TableRow>
      {headerCells.map((cell) => (
        <TableCell key={cell}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '8px',
              '&>div': {
                width: '100%',
              },
            }}
          >
            <Input
              disableUnderline
              onChange={(ev) => manageSearchFields(ev.target.value, cell)}
              sx={{
                '& .MuiInput-input': {
                  padding: 0,
                },
              }}
              startAdornment={
                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                  <SearchIcon fontSize='inherit' />
                </Box>
              }
              value={searchFields?.[cell] ?? ''}
            />
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );
};
