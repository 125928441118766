import { type Theme, type ThemeOptions } from '@mui/material/styles';

export default (theme: Theme): ThemeOptions['components'] => ({
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        width: '30px',
        height: '28px',
        p: 0,
        fontSize: 'inherit',
        border: `1px solid ${theme.palette.grey?.[200]}`,
        background: theme.palette.primary.light,
        color: theme.palette.text.primary,
      },
    },
  },
});
