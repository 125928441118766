import { type DateStringType } from './types';

export const noop = () => {};

export const isDateString = (date = ''): date is DateStringType => {
  const parts = date.split('/');
  const [day, month, year] = parts;

  if (!day || !month || !year) {
    return false;
  }

  if ([day, month].some(({ length }) => length !== 2)) {
    return false;
  }

  if (year.length !== 4) {
    return false;
  }

  if ([month, year, day].every((num) => parseInt(num, 10) < 1)) {
    return false;
  }

  if (parseInt(month, 10) > 12 || parseInt(day, 10) > 31) {
    return false;
  }

  return parts.every(Number);
};

export const debounce = <T extends Array<unknown>, K>(
  fn: (...rest: T) => K,
  ms = 300,
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: unknown, ...args: T) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
