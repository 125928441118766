import { type SvgIconProps } from '@mui/material';

import { NoFillSvgIcon } from '../NoFillSvgIcon';

export const CloseIcon = (props: SvgIconProps) => {
  return (
    <NoFillSvgIcon {...props} width='9' height='10' viewBox='0 0 9 10'>
      <path
        d='M8.504 7.621a.913.913 0 0 1 .246.629.913.913 0 0 1-.246.629.913.913 0 0 1-.629.246.913.913 0 0 1-.629-.246L4.375 5.98l-2.871 2.9a.913.913 0 0 1-.629.246.913.913 0 0 1-.629-.246A.913.913 0 0 1 0 8.25c0-.237.082-.447.246-.629L3.145 4.75.246 1.879A.913.913 0 0 1 0 1.25C0 1.013.082.803.246.621A.913.913 0 0 1 .875.375c.237 0 .447.082.629.246L4.375 3.52 7.246.62a.913.913 0 0 1 .629-.246c.237 0 .447.082.629.246a.913.913 0 0 1 .246.629.913.913 0 0 1-.246.629L5.605 4.75l2.899 2.871Z'
        fill='currentColor'
      />
    </NoFillSvgIcon>
  );
};
