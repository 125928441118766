import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from '../core/providers/AuthenticationProvider';
import MaterialThemeProvider from '../core/providers/MaterialThemeProvider/MaterialThemeProvider';
import { ProductionPlanProvider } from '../core/providers/ProductionPlanProvider';
import { Routes } from '../routes/Routes';
import '../fonts.css';

function App() {
  return (
    <Router>
      <MaterialThemeProvider>
        <ProductionPlanProvider>
          <AuthProvider>
            <Routes />
            <Toaster position='top-center' reverseOrder={false} />
          </AuthProvider>
        </ProductionPlanProvider>
      </MaterialThemeProvider>
    </Router>
  );
}
export default App;
