import { type Theme, type ThemeOptions } from '@mui/material/styles';

export default (theme: Theme): ThemeOptions['components'] => ({
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: '0 !important',
        border: `1px solid ${theme.palette.grey?.[200]}`,
        marginTop: '4px',
        boxShadow: 'none !important',
      },
      list: {
        maxHeight: '379px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          background: theme.palette.primary.light,
        },
        '&.Mui-selected': {
          background: theme.palette.secondary.main,
          '&:hover': {
            background: theme.palette.primary.light,
          },
        },
      },
      gutters: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '4px',
      },
    },
  },
});
