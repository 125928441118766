import { type RawArticlesDataType, ProductionStatus } from './getTableData';

export const getArticlesSum = (
  productions: RawArticlesDataType['productions'] = [],
) => {
  return productions
    .filter(
      (production) =>
        production?.production_status === ProductionStatus.Planned,
    )
    .reduce((acc, production) => {
      return acc + (production?.quantity_to_produce ?? 0);
    }, 0);
};
