import { type ReactNode } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import shadows, { type Shadows } from '@mui/material/styles/shadows';

import overrides from './overrides';

interface MaterialThemeProviderProps {
  children: ReactNode;
}

const theme = responsiveFontSizes(
  overrides(
    createTheme({
      shadows: shadows.map(() => 'none') as Shadows,
      palette: {
        primary: {
          main: '#009DE2',
          contrastText: '#000000',
          dark: 'rgba(0, 157, 226, 0.7)',
          light: '#F8F9FA',
          50: '#99D8F4 ',
        },
        secondary: {
          main: '#FFFFFF',
          light: '#009DE2',
        },
        text: {
          primary: '#333333',
          secondary: '#00000',
        },
        grey: {
          50: '#afabab',
          100: '#EFEFEF',
          200: '#DDDDDD',
          300: '#F7F7F7',
          400: '#999999',
          500: '#333333',
          700: '#495057',
        },
        success: {
          main: '#A4F5C4',
          light: '#b5ffd2',
          dark: '#26DE81',
        },
        error: {
          main: '#fb5c64',
          dark: '#FC5C65',
        },
        warning: {
          main: '#fba95c',
          dark: '#FCBC5C',
        },
      },
      typography: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        h1: {
          fontSize: '34px',
          fontWeight: 600,
        },
        h2: {
          fontSize: '24px',
          fontWeight: 600,
        },
        h3: {
          fontSize: '21px',
          fontWeight: 600,
        },
        h4: {
          fontSize: '12px',
          fontWeight: 700,
        },
        h5: {
          fontSize: '14px',
          fontWeight: 600,
        },
        caption: {
          fontSize: '15px',
          fontWeight: 500,
        },
        body1: {
          fontSize: '12px',
          fontWeight: 400,
        },
        body2: {
          fontSize: '12px',
          fontWeight: 500,
        },
      },
    }),
  ),
);

const MaterialThemeProvider = ({ children }: MaterialThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialThemeProvider;
