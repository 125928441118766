import { nl } from '../../../../../assets/text/nl';
import {
  type ArticleDataType,
  type ArticleDataTypeKeys,
} from '../../../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';

const { yes, no } = nl;

export const parseArticleCellValue = (
  value: ArticleDataType,
  key: ArticleDataTypeKeys,
) => {
  switch (key) {
    case 'turn':
      return value.turn ? yes : no;
    case 'sum':
      return value.sum;
    default:
      return value?.[key] || '';
  }
};
