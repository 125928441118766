import { type FC } from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';

interface IProps {
  columnsLength: number;
}

export const TableLoading: FC<IProps> = ({ columnsLength }) => {
  return (
    <TableRow sx={{ height: 70 }}>
      <TableCell colSpan={columnsLength} align='center'>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );
};
