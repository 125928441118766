import { type SvgIconProps } from '@mui/material';

import { NoFillSvgIcon } from '../NoFillSvgIcon';

export const LogoutIcon = (props: SvgIconProps) => {
  return (
    <NoFillSvgIcon {...props} width='14' height='13' viewBox='0 0 14 13'>
      <path
        d='M13.863 6.45 9.926 2.511c-.2-.183-.401-.183-.602 0-.182.2-.182.4 0 .601l3.172 3.2H4.812c-.273.018-.419.164-.437.437.018.273.164.42.438.438h7.683l-3.172 3.199c-.182.2-.182.4 0 .601.2.183.401.183.602 0l3.937-3.937a.418.418 0 0 0 .137-.3c0-.11-.046-.21-.137-.3ZM4.813 12H2.188a1.427 1.427 0 0 1-.93-.383 1.427 1.427 0 0 1-.383-.93V2.814c.018-.365.146-.675.383-.93.255-.237.565-.365.93-.383h2.624c.274-.018.42-.164.438-.438-.018-.273-.164-.42-.438-.438H2.188c-.62.018-1.139.228-1.558.629-.401.42-.61.939-.629 1.558v7.876c.018.62.228 1.139.629 1.558.42.401.939.61 1.558.629h2.626c.273-.018.419-.164.437-.438-.018-.273-.164-.419-.438-.437Z'
        fill='#999'
      />
    </NoFillSvgIcon>
  );
};
