const ModalContainer = {
  zIndex: 10,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'block',
} as const;

const ModalOverlay = {
  position: 'absolute',
  cursor: 'pointer',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#fffc',
  height: '100%',
  zIndex: 1,
} as const;

const ModalContent = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 2,
  transform: 'translate(-50%, -50%)',
  boxShadow: '1px 7px 11px #4c4c4c26',
  backgroundColor: '#fff',
  width: '70vw',
  minWidth: '600px',
  height: '50vh',
  minHeight: '600px',
  paddingTop: '40px',
} as const;

const ModalCloseIcon = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  fontSize: '20px',
} as const;

export { ModalContainer, ModalOverlay, ModalContent, ModalCloseIcon };
