import { type DateStringType } from '../../../helpers/types';
import { type IInsertedArticle, type IProductionData } from '../types';
import { isDateThreeWeeksAgo } from './isDateThreeWeeksAgo';

type EntriesType = [DateStringType, IInsertedArticle[][]];

const initialValues: Array<EntriesType> = [];

export const deleteOldArticles = (
  insertedArticles: IProductionData['insertedArticles'],
) => {
  const newArticles: IProductionData['insertedArticles'] = Object.fromEntries(
    Object.entries(insertedArticles).map(([machineKey, entry]) => {
      const entries = Object.entries(entry) as unknown as Array<EntriesType>;

      const filteredEntries = entries.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, dayEntry) => {
          const [day, dayValue] = dayEntry;

          if (isDateThreeWeeksAgo(day) || !dayEntry) {
            return acc;
          }

          const newDayValue = dayValue?.map((dayRows) =>
            dayRows.filter(Boolean),
          );

          const newEntry = [day, newDayValue];

          return [...acc, newEntry];
        },
        initialValues,
      );

      const machineArticles = Object.fromEntries(filteredEntries);

      return [machineKey, machineArticles];
    }),
  );

  return newArticles;
};
