import { type SvgIconProps } from '@mui/material';

import { NoFillSvgIcon } from '../NoFillSvgIcon';

export const ReloadIcon = (props: SvgIconProps) => {
  return (
    <NoFillSvgIcon {...props} width='14' height='14' viewBox='0 0 14 14'>
      <path
        d='M12.688.188a.852.852 0 0 0-.63.246.852.852 0 0 0-.245.628v2.024a5.835 5.835 0 0 0-2.08-1.806A5.822 5.822 0 0 0 7 .625c-1.313.018-2.48.392-3.5 1.121-1.003.747-1.714 1.741-2.133 2.98a.89.89 0 0 0 .055.684.75.75 0 0 0 .492.41.978.978 0 0 0 .684-.027c.2-.11.337-.283.41-.52.31-.875.82-1.576 1.531-2.105.711-.51 1.531-.775 2.461-.793.693 0 1.34.155 1.941.465.584.31 1.067.738 1.45 1.285H9.187a.852.852 0 0 0-.628.246.852.852 0 0 0-.246.629c0 .255.082.465.246.629a.852.852 0 0 0 .629.246h3.5a.852.852 0 0 0 .628-.246.852.852 0 0 0 .246-.629V1.062a.852.852 0 0 0-.246-.628.852.852 0 0 0-.629-.246Zm-.602 7.492a.89.89 0 0 0-.684.054.75.75 0 0 0-.41.493c-.31.875-.82 1.576-1.531 2.105-.711.51-1.531.775-2.461.793-.693 0-1.34-.155-1.941-.465a4.088 4.088 0 0 1-1.45-1.285h1.204a.852.852 0 0 0 .628-.246.852.852 0 0 0 .247-.629.852.852 0 0 0-.247-.629.852.852 0 0 0-.628-.246h-3.5a.852.852 0 0 0-.63.246.852.852 0 0 0-.246.629v3.938a.85.85 0 0 0 .247.628.852.852 0 0 0 .628.246.852.852 0 0 0 .63-.246.852.852 0 0 0 .246-.629v-2.023a6.163 6.163 0 0 0 2.078 1.805c.838.437 1.74.656 2.707.656 1.312-.018 2.479-.392 3.5-1.121 1.002-.747 1.722-1.741 2.16-2.98a.89.89 0 0 0-.055-.684.665.665 0 0 0-.492-.41Z'
        fill='currentColor'
      />
    </NoFillSvgIcon>
  );
};
