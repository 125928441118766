import config from '../../../../config';
import { typedObjectValues } from '../../../helpers/typedObjectKeys';
import { type BarCodeType } from '../types';
import { type RawArticlesDataType } from './getTableData';

const {
  eanCodes: { types: codeTypes },
} = config;

const codeTypeValues = typedObjectValues(codeTypes);

type EanCodes = Array<{ productionMethod: BarCodeType; ean_code: string }>;
export type BarCodes = Record<string, Partial<Record<BarCodeType, EanCodes>>>;

export const getBarCodes = (dataItem: RawArticlesDataType): BarCodes => {
  const { productions } = dataItem;

  if (!productions || !productions.length) {
    return {};
  }

  return productions.reduce((acc, currentProduction) => {
    const { production_nr: productionNumber, ean_codes: codes } =
      currentProduction ?? {};

    if (!productionNumber || !codes?.length) {
      return { ...acc };
    }

    const newCodes = codeTypeValues.reduce((acc2, currentType) => {
      const selectedCodes = codes
        .filter(({ production_method }) => production_method === currentType)
        .map(({ ean_code }) => ean_code);

      if (!selectedCodes?.length) {
        return { ...acc2 };
      }

      return {
        ...acc2,
        [currentType]: selectedCodes,
      };
    }, {});

    return {
      ...acc,
      [productionNumber]: newCodes,
    };
  }, {});
};
