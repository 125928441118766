import { type FC, type PropsWithChildren } from 'react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import usePDFRender from '../../core/helpers/hooks/usePDFRender';

interface PDFButtonProps extends PropsWithChildren {
  fileName: string;
  isDebug?: boolean;
}

const PDFRenderButton: FC<PDFButtonProps> = ({
  children,
  fileName,
  isDebug,
}) => {
  const pdfValues = usePDFRender();

  if (!pdfValues) {
    return null;
  }

  if (isDebug) {
    return (
      <PDFViewer width='100%' height='1300px'>
        {pdfValues.document}
      </PDFViewer>
    );
  }

  return (
    <PDFDownloadLink document={pdfValues.document} fileName={fileName}>
      {children}
    </PDFDownloadLink>
  );
};

export default PDFRenderButton;
