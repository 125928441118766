import { useCallback, useMemo, useState } from 'react';

import config from '../../../config';
import { useArticlesContext } from '../../providers/ArticlesTableProvider';
import { useProductionPlan } from '../../providers/ProductionPlanProvider';
import {
  type IInsertedArticle,
  type IProductionData,
} from '../../providers/ProductionPlanProvider/types';
import {
  type UploadResponseType,
  type UploadsStateType,
} from '../../types/UploadTypes';
import request from '../request';
import { type DateStringType } from '../types';
import { isDateString } from '../utils';

export const useSaveProductionPlan = () => {
  const [currentUploads, setCurrentUploads] = useState<UploadsStateType>();
  const {
    productionPlanData: { insertedArticles },
  } = useProductionPlan();
  const { setRefreshTableData } = useArticlesContext();

  const saveProductionPlan = useCallback(async () => {
    const preparedArticles = prepareSentData(insertedArticles);

    const requests = preparedArticles.map((rawArticleToSend) => {
      const { rawArticle, rowIndexList, ...articleToSend } = rawArticleToSend;

      const preparedSentArticle = {
        ...articleToSend,
        machine_planning_id: articleToSend.machine_planning_id || null,
        production_number: articleToSend.production_number || null,
      };

      return request<UploadResponseType>(config.routes.sendArticles, {
        method: 'POST',
        body: preparedSentArticle,
        executionToken: 'sendArticles',
        returnError: true,
      });
    });

    setCurrentUploads([preparedArticles, requests]);
  }, [insertedArticles]);

  const closeModal = useCallback(() => {
    setCurrentUploads(undefined);
    setRefreshTableData(true);
  }, [setRefreshTableData]);

  const values = useMemo(() => {
    return {
      saveProductionPlan,
      currentUploads,
      closeModal,
    };
  }, [closeModal, currentUploads, saveProductionPlan]);

  return values;
};

const prepareSentData = (
  insertedArticles: IProductionData['insertedArticles'],
) => {
  const savedMachines = Object.keys(insertedArticles);

  if (!savedMachines.length) {
    return [];
  }

  const preparedArticles: Array<PreparedArticlesType> = [];

  savedMachines.map((machineKey) => {
    const machine = insertedArticles[machineKey];
    const entries = Object.entries(machine);

    entries.forEach(([day, articleRows]) => {
      if (!isDateString(day)) {
        return new Error('Unexpected day');
      }

      const initialDirtyArticles: Array<PreparedArticlesType> = [];
      const initialFilteredState: Record<string, PreparedArticlesType> = {};

      const preparedDirtyArticles = (articleRows ?? []).reduce(
        (acc, articleRow, rowIndex) => {
          const articles = articleRow.map((article, tableIndex) => {
            return prepareArticleForUpload(
              { ...article, tableIndex, row: rowIndex },
              day,
              machineKey,
            );
          });

          return [...acc, ...articles];
        },
        initialDirtyArticles,
      );

      const preparedFilteredArticles = preparedDirtyArticles.reduce(
        (acc, article) => {
          const { article: articleId, production_number: PN } = article;
          const id = `${articleId}-${PN ?? ' '}-${article.rawArticle.row}`;
          const newAcc: Record<string, PreparedArticlesType> = { ...acc };

          if (article?.rawArticle?.isUploaded) {
            return newAcc;
          }

          if (newAcc[id]) {
            newAcc[id] = {
              ...newAcc[id],
              quantity: `${
                parseInt(newAcc[id].quantity, 10) +
                parseInt(article.quantity || '0', 10)
              }`,
              rowIndexList: [
                ...newAcc[id].rowIndexList,
                ...article.rowIndexList,
              ],
              production_number: acc[id].production_number || undefined,
            };

            return newAcc;
          }

          newAcc[id] = article;

          return newAcc;
        },
        initialFilteredState,
      );

      preparedArticles.push(...Object.values(preparedFilteredArticles));
    });
  });

  return preparedArticles;
};

interface IArticleWithRow extends IInsertedArticle {
  row: number;
}

const prepareArticleForUpload = (
  article: IArticleWithRow,
  day: DateStringType,
  machineKey: string,
) => {
  const quantity = article.timesToBeProduced || '0';

  return {
    quantity,
    article: article.articleCode,
    machine: machineKey,
    production_date: day,
    production_number: article.productionNumber || undefined,
    rawArticle: { ...article, isUploaded: article?.isUploaded },
    rowIndexList: [{ index: article.tableIndex, quantity, uuid: article.uuid }],
    machine_planning_id: article.machinePlanningId,
  };
};

export type PreparedArticlesType = ReturnType<typeof prepareArticleForUpload>;
