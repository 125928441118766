import { type SelectedHeadersType } from '../ArticlesTableProvider';
import { type ArticleDataType } from './ArticleTypes';

const shortagesHeaders: Array<SelectedHeadersType[number]> = [
  'weekZeroShortages',
  'weekOneShortages',
  'weekTwoShortages',
  'weekThreeShortages',
  'weekFourShortages',
  'weekFiveShortages',
  'weeksLeftShortages',
];

export const itHasShortages = (article: ArticleDataType) => {
  return shortagesHeaders.some((shortage) =>
    parseInt(`${article[shortage]}`, 10),
  );
};
