/* eslint-disable @typescript-eslint/no-loop-func */
import { useMemo } from 'react';

import config from '../../../config';
import {
  type AllBarCodes,
  useArticlesContext,
} from '../../providers/ArticlesTableProvider';
import { useProductionPlan } from '../../providers/ProductionPlanProvider';
import { type IProductionData } from '../../providers/ProductionPlanProvider/types';
import { filterArticlesByMachinePark } from '../filterArticlesByMachinePark';
import { getBarCodeData } from '../getBarCodeData';
import PDFDocument from '../PDFDocument';

type DocumentRequiredData = Pick<
  IProductionData,
  'machinePark' | 'insertedArticles' | 'week' | 'year' | 'comments' | 'days'
> & {
  machine: Exclude<IProductionData['machine'], undefined>;
  barCodes: AllBarCodes;
  availableMachineParks: ReturnType<
    typeof useProductionPlan
  >['availableMachineParks'];
};

const usePDFRender = () => {
  const { barCodes } = useArticlesContext();
  const {
    productionPlanData: {
      machine,
      machinePark,
      insertedArticles,
      week,
      year,
      comments,
      days,
    },
    availableMachineParks,
  } = useProductionPlan();

  const values = useMemo(() => {
    const selectedMachine = machine;

    if (!selectedMachine) {
      return null;
    }

    const data = prepareDocumentData({
      machine: selectedMachine,
      barCodes,
      machinePark,
      insertedArticles: { ...insertedArticles },
      week,
      year,
      comments,
      days,
      availableMachineParks,
    });

    const document = <PDFDocument data={data} />;

    return {
      document,
    };
  }, [
    machine,
    barCodes,
    machinePark,
    insertedArticles,
    week,
    year,
    comments,
    days,
    availableMachineParks,
  ]);

  return values;
};

const prepareDocumentData = ({
  comments,
  machine,
  barCodes,
  insertedArticles,
  week: zeroBasedWeek,
  year,
  days,
  availableMachineParks,
  machinePark: selectedMachinePark,
}: DocumentRequiredData) => {
  const containerRoot = document.getElementById('barcode-container');

  if (!containerRoot) {
    return [];
  }

  const { allMachines, allMachineParkKey } = config;

  const week = (zeroBasedWeek ?? 0) + 1;
  const documents = [];
  const machineCodes =
    machine.machineCode === allMachines
      ? Object.keys(insertedArticles)
      : [machine.machineCode];

  for (const machineCode of machineCodes) {
    const machineComments = comments[machineCode];

    if (selectedMachinePark !== allMachineParkKey) {
      const preparedMachineArticles = filterArticlesByMachinePark(
        insertedArticles[machineCode],
        selectedMachinePark,
      );

      const barCodeData = getBarCodeData(
        barCodes,
        preparedMachineArticles,
        containerRoot,
      );

      documents.push({
        machineComments,
        barCodeData,
        machinePark: selectedMachinePark,
        days,
        planningWeek: `${week}-${year}`,
        machineCode,
        articles: preparedMachineArticles,
      });

      continue;
    }

    for (const machinePark of availableMachineParks) {
      const preparedMachineArticles = filterArticlesByMachinePark(
        insertedArticles[machineCode],
        machinePark,
      );

      const barCodeData = getBarCodeData(
        barCodes,
        preparedMachineArticles,
        containerRoot,
      );

      documents.push({
        machineComments,
        barCodeData,
        machinePark,
        days,
        planningWeek: `${week}-${year}`,
        machineCode,
        articles: preparedMachineArticles,
      });
    }
  }

  return documents;
};

export type PreparedDocumentData = ReturnType<typeof prepareDocumentData>;

export default usePDFRender;
