import { type SearchFields } from '../../../../components/ArticlesPartition/Components/ArticlesTable/ArticlesTable';
import { type ArticleDataType } from './ArticleTypes';
import { isArticlesDataIndex } from './isArticlesDataIndex';

export const satisfiesSearchParams = (
  searchFields: SearchFields,
  article: ArticleDataType,
) => {
  return Object.entries(searchFields).every(([column, value]) => {
    if (!isArticlesDataIndex(article, column)) {
      return false;
    }

    const columnValue = `${article[column]}`.toLowerCase();

    const searchWord = `${value}`.toLowerCase();
    return columnValue.includes(searchWord);
  });
};
