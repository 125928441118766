import {
  type PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { useAsyncMemo } from './hooks/useAsyncMemo';
import {
  type ReturnTypeUseProductionPlanSetters,
  useProductionPlanSetters,
} from './hooks/useProductionPlanSetters';
import { getAllMachinesParks } from './utils/getAllMachinesParks';
import { prepareBaseData } from './utils/prepareInitialData';
import config from '../../../config';
import { isObjectEmpty } from '../../helpers/isObjectEmpty';
import {
  initializeData,
  reducerHOC,
} from '../../reducers/ProductionPlanReducer';
import {
  type IPreparedMachineParks,
  type IProductionData,
  type ProductionPlanAction,
} from './types';

interface IProducutionPlanContextProps
  extends ReturnTypeUseProductionPlanSetters,
    IPreparedMachineParks {
  productionPlanData: IProductionData;
  holidays: Array<string>;

  getInsertedArticles: (
    machineKey?: string,
  ) =>
    | IProductionData['insertedArticles'][keyof IProductionData['insertedArticles']]
    | undefined;
}

export const machinesParkInitialState: IPreparedMachineParks = {
  machineParks: {},
  availableMachineParks: [],
  availableMachines: [],
};

export const ProductionPlanContext = createContext<
  IProducutionPlanContextProps | undefined
>(undefined);

const { allMachineParkKey } = config;

export const ProductionPlanProvider = memo(
  ({ children }: PropsWithChildren) => {
    const preparedData = useMemo(() => {
      return initializeData(prepareBaseData());
    }, []);

    const [data, dispatch] = useReducer(reducerHOC, preparedData);

    const [holidays, setHolidays] = useState<Array<string>>([]);

    const dispatchCallback = useCallback((action: ProductionPlanAction) => {
      dispatch(action);
    }, []);

    const {
      setDays,
      setWeek,
      setYear,
      setFullDate,
      setMachine,
      setMachinePark,
      setComment,
      setInsertedArticle,
      changeInsertedArticle,
      removeInsertedArticle,
      setArticlesRow,
    } = useProductionPlanSetters(dispatchCallback);

    const { machineParks, availableMachineParks, availableMachines } =
      useAsyncMemo(getAllMachinesParks, [], machinesParkInitialState);

    useEffect(() => {
      if (data.machinePark) {
        return;
      }

      setMachinePark(allMachineParkKey);
    }, [availableMachineParks, data.machinePark, setMachinePark]);

    useEffect(() => {
      if (
        (data.machine &&
          availableMachines.includes(data.machine.machineCode)) ||
        isObjectEmpty(machineParks)
      ) {
        return;
      }

      const machineParksKeys = Object.keys(machineParks);

      if (!machineParksKeys.length) {
        return;
      }

      setMachine(machineParks[machineParksKeys[0]][0]);
    }, [
      availableMachines,
      data.machine,
      data.machinePark,
      machineParks,
      setMachine,
    ]);

    useEffect(() => {
      (async () => {
        // const response = await request<Array<string>>(
        //   config.routes.sendArticles,
        //   {
        //     method: 'POST',
        //     executionToken: 'sendArticles',
        //     returnError: true,
        //   },
        // );

        await new Promise((resolve) =>
          setTimeout(() => resolve(undefined), 1500),
        );

        setHolidays(['10/05/2023', '13/05/2023']);
      })();
    }, []);

    const getInsertedArticles = useCallback(
      (machineKey?: string) => {
        if (!data.machine && !machineKey) {
          return undefined;
        }

        return data.insertedArticles[
          machineKey || data.machine?.machineCode || ''
        ];
      },
      [data.insertedArticles, data.machine],
    );

    const value = useMemo(() => {
      return {
        machineParks,
        availableMachineParks,
        availableMachines,
        holidays,
        setDays,
        setWeek,
        setYear,
        setFullDate,
        setMachine,
        setMachinePark,
        setComment,
        setInsertedArticle,
        changeInsertedArticle,
        removeInsertedArticle,
        getInsertedArticles,
        setArticlesRow,
        productionPlanData: data,
      };
    }, [
      machineParks,
      availableMachineParks,
      availableMachines,
      holidays,
      setDays,
      setWeek,
      setYear,
      setFullDate,
      setMachine,
      setMachinePark,
      setComment,
      setInsertedArticle,
      changeInsertedArticle,
      removeInsertedArticle,
      getInsertedArticles,
      setArticlesRow,
      data,
    ]);

    return (
      <ProductionPlanContext.Provider value={value}>
        {children}
      </ProductionPlanContext.Provider>
    );
  },
);
