import { type DateStringType } from '../../helpers/types';
import { type ArticleDataType } from '../ArticlesTableProvider/utils/ArticleTypes';

export enum ProductionPlanActionType {
  SET_DATA = 'SET_DATA',
  SET_MACHINE = 'SET_MACHINE',
  SET_DAYS = 'SET_DAYS',
  SET_WEEK = 'SET_WEEK',
  SET_YEAR = 'SET_YEAR',
  SET_FULL_DATE = 'SET_FULL_DATE',
  SET_INSERTED_ARTICLE = 'SET_INSERTED_ARTICLE',
  CHANGE_INSERTED_ARTICLE = 'CHANGE_INSERTED_ARTICLE',
  REMOVE_ARTICLE = 'REMOVE_ARTICLE',
  SET_COMMENT = 'SET_COMMENT',
  CHANGE_MACHINE_PARK = 'CHANGE_MACHINE_PARK',
  ADD_ROW = 'ADD_ROW',
}

export interface IFullDate {
  days: Array<Date>;
  year: number;
  week: number | undefined;
}

export type KeyOfFullDateType = keyof IFullDate;

export type MachinesParkResponseType = Array<{
  formula_result: {
    [machineKey: string]: Array<{
      machine_code: string;
      possible_machines: string;
      lengte_trilbalk: number;
    }>;
  };
}>;

export interface MachinesParks {
  [key: string]: Array<ProductionPlanMachine>;
}

export interface IPreparedMachineParks {
  machineParks: MachinesParks;
  availableMachineParks: Array<string>;
  availableMachines: Array<string>;
}

export interface IInsertedArticle extends ArticleDataType {
  tableIndex: number;
}

export type InsertedArticlesType = Partial<
  Record<DateStringType, Array<Array<IInsertedArticle>>>
>;

export interface ProductionPlanMachine {
  machineCode: string;
  possibleMachines: string[];
  beamLength: number;
}

export interface IInsertedArticlesPayload {
  machineKey: string;
  key: DateStringType;
  article: IInsertedArticle;
  row: number;
  tableIndex?: number;
}

export interface IInsertedArticlesRemovePayload {
  machineKey: string;
  day: DateStringType;
  row: number;
  tableIndex: number;
  uuid?: string;
}

export interface ICommentsPayload {
  machineKey: string;
  key: DateStringType;
  comment: string;
  rowIndex: number;
}

export interface IAddRowPayload {
  day: DateStringType;
  inx: number;
  row?: Array<IInsertedArticle> | null;
}

export interface IProductionData extends IFullDate {
  insertedArticles: {
    [machineType: string]: InsertedArticlesType;
  };
  comments: {
    [machineType: string]: Record<DateStringType, string[]>;
  };
  machine: ProductionPlanMachine | undefined;
  machinePark: string | undefined;
}

export interface ISetProductionPlan {
  type: ProductionPlanActionType.SET_DATA;
  payload: IProductionData;
}

export interface ISetProductionPlanDays {
  type: ProductionPlanActionType.SET_DAYS;
  payload: Array<Date>;
}

export interface ISetProductionPlanWeek {
  type: ProductionPlanActionType.SET_WEEK;
  payload: number;
}

export interface ISetProductionPlanYear {
  type: ProductionPlanActionType.SET_YEAR;
  payload: number;
}

export interface ISetProductionPlanFullDate {
  type: ProductionPlanActionType.SET_FULL_DATE;
  payload: IFullDate;
}

export interface ISetProductionPlanInsertedArticle {
  type: ProductionPlanActionType.SET_INSERTED_ARTICLE;
  payload: IInsertedArticlesPayload;
}

export interface IChangeProductionPlanInsertedArticle {
  type: ProductionPlanActionType.CHANGE_INSERTED_ARTICLE;
  payload: IInsertedArticlesPayload;
}

export interface IRemoveProductionPlanInsertedArticle {
  type: ProductionPlanActionType.REMOVE_ARTICLE;
  payload: IInsertedArticlesRemovePayload;
}

export interface ISetProductionPlanMachine {
  type: ProductionPlanActionType.SET_MACHINE;
  payload: ProductionPlanMachine;
}

export interface ISetProductionPlanComment {
  type: ProductionPlanActionType.SET_COMMENT;
  payload: ICommentsPayload;
}

export interface ISetProductionPlanMachinePark {
  type: ProductionPlanActionType.CHANGE_MACHINE_PARK;
  payload: string;
}

export interface ISetProductionPlanArticlesRow {
  type: ProductionPlanActionType.ADD_ROW;
  payload: IAddRowPayload;
}

export type ProductionPlanAction =
  | ISetProductionPlan
  | ISetProductionPlanDays
  | ISetProductionPlanWeek
  | ISetProductionPlanYear
  | ISetProductionPlanFullDate
  | ISetProductionPlanInsertedArticle
  | IChangeProductionPlanInsertedArticle
  | ISetProductionPlanMachine
  | ISetProductionPlanComment
  | ISetProductionPlanMachinePark
  | ISetProductionPlanArticlesRow
  | IRemoveProductionPlanInsertedArticle;
