import { memo } from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

import { AuthenticatedRoutes } from './components/AuthenticatedRoutes';
import { PublicRoutes } from './components/PublicRoutes';
import { useAuthContext } from '../core/providers/AuthenticationProvider';

export const Routes = memo(() => {
  const { isLogedIn } = useAuthContext();

  return (
    <RouterRoutes>
      {isLogedIn ? (
        <Route path='/*' element={<AuthenticatedRoutes />} />
      ) : (
        <Route path='/*' element={<PublicRoutes />} />
      )}
    </RouterRoutes>
  );
});
