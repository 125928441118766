import { type SvgIconProps } from '@mui/material';

import { NoFillSvgIcon } from '../NoFillSvgIcon';

export const DocumentIcon = (props: SvgIconProps) => {
  return (
    <NoFillSvgIcon {...props} width='11' height='15' viewBox='0 0 11 15'>
      <path
        d='M7 .75v3.5h3.5L7 .75Zm-.875 3.5V.75H1.312a1.427 1.427 0 0 0-.93.383 1.427 1.427 0 0 0-.382.93v11.374c.018.365.146.675.383.93.255.237.565.365.93.383h7.875a1.43 1.43 0 0 0 .93-.383 1.43 1.43 0 0 0 .382-.93V5.126H7.027a.947.947 0 0 1-.656-.246.852.852 0 0 1-.246-.629Zm1.313 7.875H3.063c-.274-.018-.42-.164-.438-.438.018-.273.164-.419.438-.437h4.374c.274.018.42.164.438.438-.018.273-.164.419-.438.437Zm0-1.75H3.063c-.274-.018-.42-.164-.438-.438.018-.273.164-.419.438-.437h4.374c.274.018.42.164.438.438-.018.273-.164.419-.438.437Zm.437-2.188c-.018.274-.164.42-.438.438H3.063c-.273-.018-.419-.164-.437-.438.018-.273.164-.419.438-.437h4.374c.274.018.42.164.438.438Z'
        fill='currentColor'
      />
    </NoFillSvgIcon>
  );
};
