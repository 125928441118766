import { type Theme, type ThemeOptions } from '@mui/material/styles';

export default (theme: Theme): ThemeOptions['components'] => ({
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        textTransform: 'none',
        height: '30px',
        width: 'fit-content',
      },
      outlinedPrimary: {
        background: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        '&:hover': {
          background: theme.palette.primary.light,
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
      containedPrimary: {
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.dark}`,
        color: 'white',
        '&:hover': {
          background: theme.palette.primary.main,
        },
      },
    },
  },
});
