import {
  type CSSProperties,
  type FC,
  type KeyboardEvent,
  useState,
} from 'react';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { CloseIcon } from '../../../assets/icons';
import { nl } from '../../../assets/text/nl';
import { prepareRowForPaste } from '../../../core/helpers/prepareRowForPaste';
import { isDateString } from '../../../core/helpers/utils';
import { useContextMenuContext } from '../../../core/providers/ContextMenuProvider';
import { useProductionPlan } from '../../../core/providers/ProductionPlanProvider';
import { ModalContent } from '../../Modal/styles';
import { DateParseOptions } from './PlanningCalendar';

interface DatePickerResult {
  $d: Date;
}

const styles: Record<string, CSSProperties> = {
  contentBox: {
    ...ModalContent,
    width: 'auto',
    height: 'auto',
    minWidth: 'auto',
    minHeight: 'auto',
    padding: '0',
    fontWeight: 600,
  },
  header: {
    padding: '10px 15px',
  },
  inputContainer: {
    padding: '10px 15px',
  },
  label: {
    fontWeight: 800,
    marginBottom: '10px',
  },
  closeIcon: {
    position: 'absolute',
    top: '16px',
    right: '15px',
    fontSize: '10px',
    fontWeight: 600,
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 0 5px 0',
  },
};

const { shiftHeader, shiftLabel } = nl;

const ShiftRowModal: FC = () => {
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const { setArticlesRow, getInsertedArticles } = useProductionPlan();
  const { shiftModalProps, setShiftModalProps } = useContextMenuContext();

  if (!shiftModalProps) {
    return null;
  }

  const close = () => setShiftModalProps(null);

  const keyDown = ({ key }: KeyboardEvent<HTMLSpanElement>) => {
    if (key === 'Escape') {
      close();
    }
  };

  const confirm = () => {
    if (!selectedDay || isNaN(selectedDay.getTime())) {
      return;
    }

    const date = selectedDay.toLocaleString('en-GB', DateParseOptions);

    if (!isDateString(date)) {
      return;
    }
    const articles = getInsertedArticles();

    const row = prepareRowForPaste(
      articles?.[shiftModalProps.day]?.[shiftModalProps.inx] ?? null,
    );

    setArticlesRow({
      ...shiftModalProps,
      row: shiftModalProps.inx ? null : [],
    });

    const rowIndex = articles?.[date]?.length ?? 0;

    setArticlesRow({ day: date, inx: rowIndex + 1, row: row });

    close();
  };

  return (
    <Box sx={styles.contentBox}>
      <Box sx={styles.header}>
        <Typography style={{ fontWeight: 700 }}>{shiftHeader}</Typography>
      </Box>
      <CloseIcon
        onClick={close}
        sx={(theme) => ({
          ...styles.closeIcon,
          color: theme.palette.grey[600],
        })}
      />
      <Divider />
      <Box sx={styles.inputContainer}>
        <Typography sx={styles.label}>{shiftLabel}</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disablePast
            value={selectedDay}
            inputFormat='DD/MM/YYYY'
            onChange={(value: unknown) =>
              setSelectedDay((value as DatePickerResult).$d)
            }
            renderInput={(props) => (
              <TextField {...props} onKeyDown={keyDown} />
            )}
          />
        </LocalizationProvider>
        <Box sx={styles.buttonContainer}>
          <Button
            sx={(theme) => ({
              border: `1px solid ${theme.palette.grey[400]}`,
              background: theme.palette.grey[300],
              color: theme.palette.primary.contrastText,
              marginRight: '7px',
            })}
          >
            Cancel
          </Button>
          <Button
            sx={(theme) => ({
              border: `1px solid ${theme.palette.primary.main}`,
              background: theme.palette.grey[300],
              color: theme.palette.primary.main,
              fontWeight: '600',
            })}
            onClick={confirm}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ShiftRowModal;
