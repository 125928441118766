import { type FC, memo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';

import { nl } from '../../assets/text/nl';
import { Header } from '../../components/Header/Header';
import MuiFormTextField from '../../core/helpers/MuiFormTextField';
import {
  type LoginProps,
  useAuthContext,
} from '../../core/providers/AuthenticationProvider';

const initialValues: LoginProps = {
  email: '',
  password: '',
};

export const Login: FC = memo(() => {
  const { login } = useAuthContext();

  return (
    <Box>
      <Header />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          login(values);
        }}
      >
        <Form>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 43px)',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant='body1'
                sx={{ alignSelf: 'flex-start', mb: '5px' }}
              >
                {nl.login}:
              </Typography>
              <Field
                name='email'
                component={MuiFormTextField}
                sx={{ mb: 2, width: '200px' }}
              />
              <Typography variant='body1' sx={{ mb: '5px' }}>
                {nl.password}:
              </Typography>
              <Field
                name='password'
                type='password'
                component={MuiFormTextField}
                sx={{ mb: '20px', width: '200px' }}
              />
              <Button
                variant={'contained'}
                sx={{ width: '100%' }}
                type='submit'
              >
                <Typography variant='caption'>{nl.enter}</Typography>
              </Button>
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
});
