export const getWeekNumber = (currentDate: Date, weeks: number) => {
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days =
    Math.floor(
      (currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000),
    ) +
    weeks * 7;

  const weekNumber = Math.ceil(days / 7);
  return weekNumber;
};

const getWeek = (d: Date) => {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));

  const yearStart = new Date(d.getFullYear(), 0, 1).getTime();
  const weekNo = Math.ceil(((d.getTime() - yearStart) / 86400000 + 1) / 7);

  return [d.getFullYear(), weekNo];
};

export const weekCountInYear = (year: number) => {
  const month = 11;

  let week;
  let day = 31;

  do {
    const d = new Date(year, month, day--);

    week = getWeek(d)[1];
  } while (week == 1);

  return week;
};

export const getWeekNumberOfDate = (date: Date) => {
  date = new Date(+date);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));

  const yearStart = new Date(date.getFullYear(), 0, 1);
  const weekNo = Math.ceil(
    ((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
  );
  return weekNo;
};

export const weeksInYear = (year: number) => {
  const date = new Date(year, 11, 31);
  const week = getWeekNumberOfDate(date);
  return week == 1 ? 52 : week;
};
