import { useCallback, useMemo } from 'react';

import {
  changeProductionPlanInsertedArticleAction,
  removeProductionPlanInsertedArticleAction,
  setProductionPlanArticlesRow,
  setProductionPlanCommentsAction,
  setProductionPlanDaysAction,
  setProductionPlanFullYearAction,
  setProductionPlanInsertedArticleAction,
  setProductionPlanMachineAction,
  setProductionPlanMachineParkAction,
  setProductionPlanWeekAction,
  setProductionPlanYearAction,
} from '../../../actions/ProductionPlan/productionPlanActions';
import {
  type IAddRowPayload,
  type ICommentsPayload,
  type IFullDate,
  type IInsertedArticlesPayload,
  type IInsertedArticlesRemovePayload,
  type KeyOfFullDateType,
  type ProductionPlanAction,
  type ProductionPlanMachine,
} from '../types';
import { prepareBaseData } from '../utils/prepareInitialData';

export interface ReturnTypeUseProductionPlanSetters {
  setDays: (days: Array<Date>) => void;
  setWeek: (week: number) => void;
  setYear: (year: number) => void;
  setFullDate: (dateProps: Partial<IFullDate>) => void;
  setMachine: (machine: ProductionPlanMachine) => void;
  setMachinePark: (machineParkKey: string) => void;
  setInsertedArticle: (props: IInsertedArticlesPayload) => void;
  changeInsertedArticle: (props: IInsertedArticlesPayload) => void;
  removeInsertedArticle: (props: IInsertedArticlesRemovePayload) => void;
  setArticlesRow: (props: IAddRowPayload) => void;
  setComment: (props: ICommentsPayload) => void;
}

const IFullDateKeys: Array<KeyOfFullDateType> = ['days', 'week', 'year'];

export const useProductionPlanSetters = (
  dispatch: React.Dispatch<ProductionPlanAction>,
): ReturnTypeUseProductionPlanSetters => {
  const setDays = useCallback(
    (days: Array<Date>) => {
      dispatch(setProductionPlanDaysAction(days));
    },
    [dispatch],
  );

  const setWeek = useCallback(
    (week: number) => {
      dispatch(setProductionPlanWeekAction(week));
    },
    [dispatch],
  );

  const setYear = useCallback(
    (year: number) => {
      dispatch(setProductionPlanYearAction(year));
    },
    [dispatch],
  );

  const setInsertedArticle = useCallback(
    (props: IInsertedArticlesPayload) => {
      if (!props.article) {
        return;
      }

      dispatch(setProductionPlanInsertedArticleAction(props));
    },
    [dispatch],
  );

  const setMachine = useCallback(
    (props: ProductionPlanMachine) => {
      dispatch(setProductionPlanMachineAction(props));
    },
    [dispatch],
  );

  const setMachinePark = useCallback(
    (machineParkKey: string) => {
      dispatch(setProductionPlanMachineParkAction(machineParkKey));
    },
    [dispatch],
  );

  const setComment = useCallback(
    (props: ICommentsPayload) => {
      dispatch(setProductionPlanCommentsAction(props));
    },
    [dispatch],
  );

  const setArticlesRow = useCallback(
    (props: IAddRowPayload) => {
      dispatch(setProductionPlanArticlesRow(props));
    },
    [dispatch],
  );

  const changeInsertedArticle = useCallback(
    (props: IInsertedArticlesPayload) => {
      if (!props.article) {
        return;
      }

      dispatch(changeProductionPlanInsertedArticleAction(props));
    },
    [dispatch],
  );

  const removeInsertedArticle = useCallback(
    (props: IInsertedArticlesRemovePayload) => {
      dispatch(removeProductionPlanInsertedArticleAction(props));
    },
    [dispatch],
  );

  const setFullDate = useCallback(
    (dateProps: Partial<IFullDate>) => {
      const baseDate = prepareBaseData();

      const newState = IFullDateKeys.reduce<IFullDate>((acc, key) => {
        return {
          ...acc,
          [key]: Object.prototype.hasOwnProperty.call(dateProps, key)
            ? dateProps[key]
            : baseDate[key],
        };
      }, {} as IFullDate);

      dispatch(setProductionPlanFullYearAction(newState));
    },
    [dispatch],
  );

  const value = useMemo(() => {
    return {
      setDays,
      setWeek,
      setYear,
      setInsertedArticle,
      setMachine,
      setMachinePark,
      changeInsertedArticle,
      removeInsertedArticle,
      setFullDate,
      setComment,
      setArticlesRow,
    };
  }, [
    setDays,
    setWeek,
    setYear,
    setInsertedArticle,
    setMachine,
    setMachinePark,
    changeInsertedArticle,
    removeInsertedArticle,
    setFullDate,
    setComment,
    setArticlesRow,
  ]);

  return value;
};
