import { type IInsertedArticle } from '../../../core/providers/ProductionPlanProvider/types';
import { type useContextMenuActions } from '../components/ContextMenu/useContextMenuActions';

export const isDisabledMenuOption = (
  rowArticles: IInsertedArticle[] | undefined,
  menuItemKey: keyof ReturnType<typeof useContextMenuActions>,
  rowIdx: string | undefined,
  cellIdx: string | undefined,
  copiedRow: Array<IInsertedArticle> | undefined,
) => {
  if (menuItemKey === 'pasteRow' && !copiedRow) {
    return true;
  }

  const isFirstRow = menuItemKey === 'removeRow' && rowIdx === '0';
  const noCell = menuItemKey === 'removeCell' && !cellIdx;

  if (menuItemKey === 'removeCell') {
    const cellIsUploaded = !!(
      cellIdx && rowArticles?.[parseInt(cellIdx, 10)]?.isUploaded
    );

    return isFirstRow || noCell || cellIsUploaded;
  }

  const rowContainsUploaded =
    menuItemKey === 'removeRow' &&
    rowArticles?.some((article) => article.isUploaded);

  return isFirstRow || noCell || rowContainsUploaded;
};
