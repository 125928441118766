import {
  type IInsertedArticlesRemovePayload,
  type IProductionData,
} from '../providers/ProductionPlanProvider/types';

export const removeStateArticle = (
  state: IProductionData,
  payload: IInsertedArticlesRemovePayload,
) => {
  const { row, day, machineKey, tableIndex, uuid } = payload;

  if (!state.insertedArticles || !uuid) {
    return {
      ...state,
    };
  }

  const selectedRow = [
    ...(state.insertedArticles[machineKey]?.[day]?.[row] ?? []),
  ];

  if (selectedRow?.[tableIndex]?.uuid !== uuid) {
    return { ...state };
  }

  selectedRow.splice(tableIndex, 1);

  const selectedDay = [...(state.insertedArticles[machineKey]?.[day] ?? [])];

  selectedDay.splice(row, 1, selectedRow);

  return {
    ...state,
    insertedArticles: {
      ...state.insertedArticles,
      [machineKey]: {
        ...(state.insertedArticles?.[machineKey] ?? {}),
        [day]: selectedDay,
      },
    },
  };
};
