import { type ArticleDataType } from '../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { type IInsertedArticle } from '../../../core/providers/ProductionPlanProvider/types';

export const getPalletesNumber = (
  cellData?: ArticleDataType | IInsertedArticle,
) => {
  if (!cellData?.timesToBeProduced || !cellData.quantityPerMould) {
    return '';
  }

  return Math.ceil(
    parseInt(cellData.timesToBeProduced, 10) /
      parseInt(cellData.quantityPerMould, 10),
  );
};
