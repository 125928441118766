import config from '../../../../config';
import { addIdToArticles } from '../../../helpers/addIdToArticles';
import { type IProductionData } from '../types';
import { deleteOldArticles } from './deleteOldArticles';
import { saveDataToLocalStorage } from './saveDataToLocalStorage';

interface ILocalStorageData extends IProductionData {
  localStorageVersion: string;
}

const { PRODUCTION_PLAN_DATA } = config.localStorageKeys;

const { localStorageVersion: configStorageVersion } = config;

export const parseDataFromLocalStorage = (data: IProductionData) => {
  const localStorageData = localStorage.getItem(PRODUCTION_PLAN_DATA);

  if (!localStorageData) {
    return;
  }

  try {
    const { localStorageVersion, ...savedData }: ILocalStorageData = JSON.parse(
      localStorageData ?? '{}',
    );

    const { insertedArticles } = savedData;

    if (localStorageVersion !== configStorageVersion) {
      localStorage.removeItem(PRODUCTION_PLAN_DATA);
      return undefined;
    }

    const newArticles = deleteOldArticles(insertedArticles);

    const removedOldArticlesData = {
      ...savedData,
      insertedArticles: addIdToArticles(newArticles),
    };

    const days = savedData.days.map((day) => new Date(day));

    const newData = {
      ...data,
      ...removedOldArticlesData,
      days,
    };

    saveDataToLocalStorage(newData);

    return newData;
  } catch (error) {
    return undefined;
  }
};
