import { TextField } from '@mui/material';
import { type FieldInputProps, type FormikState } from 'formik';

interface IMuiFormTextFieldProps {
  field: FieldInputProps<unknown>;
  form: FormikState<unknown>;
}

const MuiFormTextField = ({
  field,
  form: _form,
  ...otherProps
}: IMuiFormTextFieldProps) => {
  return (
    <TextField
      sx={{
        mb: 2,
        width: '200px',
      }}
      inputProps={{
        sx: { paddingY: '6px' },
        ...field,
      }}
      {...otherProps}
    />
  );
};

export default MuiFormTextField;
