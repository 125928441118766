import { Box } from '@mui/material';

import { ArticlesPartition } from '../../components/ArticlesPartition/ArticlesPartition';
import { Header } from '../../components/Header/Header';
import { ProductionPlan } from '../../components/ProductionPlan/ProductionPlan';
import { ResizableLayout } from '../../components/ResizableLayout/ResizableLayout';
import { ArticlesTableDataProvider } from '../../core/providers/ArticlesTableProvider';
import { ContextMenuProvider } from '../../core/providers/ContextMenuProvider';

export const SplitScreenTables = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Header />
      <ArticlesTableDataProvider>
        <ContextMenuProvider>
          <ResizableLayout
            leftChild={<ArticlesPartition />}
            rigthChild={<ProductionPlan />}
          />
        </ContextMenuProvider>
      </ArticlesTableDataProvider>
    </Box>
  );
};
