import { type FC } from 'react';
import {
  type SelectChangeEvent,
  Box,
  Button,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';

import { TableColumnsSelect } from './components/TableColumnsSelect';
import { ReloadIcon } from '../../../../assets/icons';
import { nl } from '../../../../assets/text/nl';
import config from '../../../../config';
import { isValueTrueString } from '../../../../core/helpers/isValueTrueString';
import { useArticlesContext } from '../../../../core/providers/ArticlesTableProvider';
import { useProductionPlan } from '../../../../core/providers/ProductionPlanProvider';
import { CustomSelect } from '../../../CustomSelect/CustomSelect';

const { allMachineParkKey } = config;

export const TableOverheadButtons: FC = () => {
  const {
    productionPlanData: { machinePark },
    availableMachineParks,
    setMachinePark,
  } = useProductionPlan();
  const { setRefreshTableData } = useArticlesContext();

  const onChangeSelect = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target;

    if (!isValueTrueString(value)) {
      return;
    }

    setMachinePark(value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        py: '10px',
        pr: 0.5,
      }}
    >
      <CustomSelect
        value={machinePark}
        sx={{ mr: 1 }}
        onChange={onChangeSelect}
      >
        <MenuItem value={allMachineParkKey}>{allMachineParkKey}</MenuItem>
        {availableMachineParks.map((machineParkKey) => {
          return (
            <MenuItem value={machineParkKey} key={machineParkKey}>
              {machineParkKey}
            </MenuItem>
          );
        })}
      </CustomSelect>
      <Box sx={{ display: 'flex' }}>
        <Button sx={{ mr: 1.5 }} variant='outlined'>
          <Typography variant='body2'>{nl.plannen}</Typography>
        </Button>
        <Box sx={{ display: 'flex', gap: 0.75 }}>
          <TableColumnsSelect />
          <IconButton
            onClick={() => {
              setRefreshTableData(true);
            }}
          >
            <ReloadIcon fontSize='inherit' />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
