import { type SelectedHeadersType } from '../ArticlesTableProvider';
import { type ArticleSort, SortOrder } from '../types';
import { type ArticleDataType } from './ArticleTypes';

export const sortFunction = (
  a: ArticleDataType,
  b: ArticleDataType,
  sort: NonNullable<ArticleSort>,
) => {
  const [[sortKey, sortOrder]] = Object.entries(sort);

  const previousArticle = parseInt(
    `${a[sortKey as SelectedHeadersType[number]]}`.match(/\d/g)?.join('') ??
      '0',
    10,
  );
  const currentArticle = parseInt(
    `${b[sortKey as SelectedHeadersType[number]]}`.match(/\d/g)?.join('') ??
      '0',
    10,
  );

  if (SortOrder.Ascending === sortOrder) {
    return currentArticle - previousArticle;
  }

  return previousArticle - currentArticle;
};
