import {
  type Dispatch,
  type PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useContextMenu } from '../../helpers/hooks/useContextMenu';
import { type DateStringType } from '../../helpers/types';
import { noop } from '../../helpers/utils';
import { useProductionPlan } from '../ProductionPlanProvider';
import { type IInsertedArticle } from '../ProductionPlanProvider/types';

interface IShiftModalProps {
  inx: number;
  day: DateStringType;
}

interface ContextMenuResult extends Partial<ReturnType<typeof useContextMenu>> {
  copiedRow: Array<IInsertedArticle> | undefined;
  setCopiedRow: Dispatch<React.SetStateAction<IInsertedArticle[] | undefined>>;
  shiftModalProps: IShiftModalProps | null;
  setShiftModalProps: Dispatch<React.SetStateAction<IShiftModalProps | null>>;
}

type ContextMenuContextType = ContextMenuResult | undefined;

const defaultData = {
  copiedRow: undefined,
  shiftModalProps: null,
  setShiftModalProps: noop,
  setCopiedRow: noop,
};

export const ContextMenuContext =
  createContext<ContextMenuContextType>(defaultData);

export const ContextMenuProvider = ({ children }: PropsWithChildren) => {
  const [copiedRow, setCopiedRow] = useState<Array<IInsertedArticle>>();
  const [shiftModalProps, setShiftModalProps] =
    useState<IShiftModalProps | null>(null);
  const {
    productionPlanData: { machine },
  } = useProductionPlan();

  const contextMenuProps = useContextMenu();

  useEffect(() => {
    setCopiedRow(undefined);
    setShiftModalProps(null);
  }, [machine]);

  const value: ContextMenuContextType = useMemo(
    () => ({
      ...contextMenuProps,
      copiedRow,
      shiftModalProps,
      setShiftModalProps,
      setCopiedRow,
    }),
    [contextMenuProps, copiedRow, shiftModalProps],
  );

  return (
    <ContextMenuContext.Provider value={value}>
      {children}
    </ContextMenuContext.Provider>
  );
};
