import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';

import config from '../../config';
import { logError } from './logError';

interface ApiError {
  key?: string;
  message?: string;
}

export interface ErrorResponse {
  errors: ApiError[];
}

interface BuildHeadersOptions {
  isJson?: boolean;
  extraHeaders?: object;
  skipCache?: boolean;
  executionToken: ExecutionToken;
}

type ExecutionToken = keyof typeof config.executionToken;

export interface RequestOptions extends BuildHeadersOptions {
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  body?: unknown;
  extraAxiosConfig?: Partial<AxiosRequestConfig>;
  skipCache?: boolean;
  executionToken: ExecutionToken;
  returnError?: boolean;
}

export const getExecutionToken = (key: ExecutionToken) => {
  return config.executionToken[key];
};

const buildHeaders = ({
  executionToken,
  isJson = true,
  extraHeaders = {},
  skipCache = false,
}: BuildHeadersOptions): Record<string, string | boolean> => {
  const headers: Record<string, string | boolean> = { ...extraHeaders };

  if (isJson) {
    headers['Content-Type'] = 'application/json';
  }

  if (executionToken) {
    headers['X-Execution-Token'] = config.executionToken[executionToken];
  }

  if (skipCache) {
    headers['Skip-Cache'] = true;
  }

  return headers;
};

function request<TResponse>(
  uri: string,
  options: RequestOptions,
): Promise<TResponse | null> {
  const {
    method = 'GET',
    isJson = true,
    body = {},
    extraHeaders = {},
    extraAxiosConfig = {},
    skipCache,
    executionToken,
  } = options ?? {};

  const headers = buildHeaders({
    isJson,
    extraHeaders,
    skipCache,
    executionToken,
  });

  return axios
    .request<TResponse>({
      method,
      headers,
      url: uri,
      data: body,
      ...extraAxiosConfig,
    })
    .then(({ data }) => data)
    .catch((error: AxiosError<TResponse>) => {
      if (options.returnError) {
        return error.response?.data || null;
      }
      logError(error);

      return null;
    });
}

export default request;
