import React, {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useState,
} from 'react';

import { type ArticleDataType } from '../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';

interface SharedTableDataContextType {
  sharedData: ArticleDataType | null;
  setSharedData: Dispatch<SetStateAction<ArticleDataType | null>>;
}

interface SharedTableDataProviderProps {
  children: ReactNode;
}

export const SharedTableDataContext =
  createContext<SharedTableDataContextType | null>(null);

export const SharedTableDataProvider = ({
  children,
}: SharedTableDataProviderProps) => {
  const [sharedData, setSharedData] = useState<ArticleDataType | null>(null);

  return (
    <SharedTableDataContext.Provider value={{ sharedData, setSharedData }}>
      {children}
    </SharedTableDataContext.Provider>
  );
};
