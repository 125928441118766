import { type IArticlesData } from '../types';
import { baseSelectedHeader } from './prepareArticlesData';

export const initialState = (): IArticlesData => {
  return {
    searchFields: {},
    allArticles: [],
    loading: true,
    dictionary: undefined,
    selectedHeaders: baseSelectedHeader,
    selectedRow: undefined,
    sort: undefined,
  };
};
