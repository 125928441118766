export const resizeSvg = (
  <svg
    width='12'
    height='8'
    viewBox='0 0 12 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.3 4.30573L8.7 6.90573C8.38333 7.2224 8.021 7.29306 7.613 7.11773C7.20433 6.94306 7 6.63073 7 6.18073V1.03073C7 0.580732 7.20433 0.268398 7.613 0.0937314C8.021 -0.0816019 8.38333 -0.0109354 8.7 0.305731L11.3 2.90573C11.4 3.00573 11.475 3.11406 11.525 3.23073C11.575 3.3474 11.6 3.4724 11.6 3.60573C11.6 3.73906 11.575 3.86406 11.525 3.98073C11.475 4.0974 11.4 4.20573 11.3 4.30573Z'
      fill='gray'
    />
    <path
      d='M0.300006 2.90573L2.90001 0.305725C3.21667 -0.0109418 3.57901 -0.0816084 3.98701 0.093725C4.39567 0.268392 4.60001 0.580725 4.60001 1.03073V6.18072C4.60001 6.63072 4.39567 6.94306 3.98701 7.11772C3.57901 7.29306 3.21667 7.22239 2.90001 6.90573L0.300006 4.30572C0.200006 4.20572 0.125006 4.09739 0.0750065 3.98073C0.0250065 3.86406 5.72205e-06 3.73906 5.72205e-06 3.60573C5.72205e-06 3.47239 0.0250065 3.34739 0.0750065 3.23073C0.125006 3.11406 0.200006 3.00573 0.300006 2.90573V2.90573Z'
      fill='gray'
    />
  </svg>
);
