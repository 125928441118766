import { type SelectProps, Select } from '@mui/material';

import { Chevron } from '../../assets/icons/Chevron';

export const CustomSelect = (props: SelectProps) => {
  return (
    <Select IconComponent={Chevron} {...props}>
      {props.children}
    </Select>
  );
};
