import { Divider, MenuItem, MenuList } from '@mui/material';

import { useContextMenuActions } from './ContextMenu/useContextMenuActions';
import { nl } from '../../../assets/text/nl';
import { typedObjectKeys } from '../../../core/helpers/typedObjectKeys';
import { isDateString } from '../../../core/helpers/utils';
import { useContextMenuContext } from '../../../core/providers/ContextMenuProvider';
import { useProductionPlan } from '../../../core/providers/ProductionPlanProvider';
import { Modal } from '../../Modal';
import { isDisabledMenuOption } from '../utils/isDisabledMenuOption';
import ShiftRowModal from './ShiftRowModal';

const contextMenuDividers = [2, 4, 6];

const ContextMenu = () => {
  const {
    isMenuVisible,
    menuPosition,
    targetElement,
    copiedRow,
    shiftModalProps,
    setShiftModalProps,
  } = useContextMenuContext();
  const { getInsertedArticles } = useProductionPlan();

  const open = isMenuVisible && menuPosition?.x !== 0 && menuPosition?.y !== 0;
  const contextMenuActions = useContextMenuActions();

  const menuNames = typedObjectKeys(contextMenuActions);
  const insertedArticles = getInsertedArticles();

  return (
    <>
      <MenuList
        sx={{
          display: open ? 'block' : 'none',
          position: 'fixed',
          top: menuPosition?.y,
          left: menuPosition?.x,
          background: '#fff',
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          zIndex: 2,
        }}
      >
        {menuNames.map((menuItemKey, index) => {
          const { contextCellIdx, contextMenuRow, contextMenuDay } =
            targetElement?.current?.dataset || {};

          const onClick = () => {
            contextMenuActions[menuItemKey](targetElement?.current ?? null);
          };
          const menuRow = parseInt(contextMenuRow || '', 10);

          const rowArticles = isDateString(contextMenuDay)
            ? insertedArticles?.[contextMenuDay]?.[menuRow]
            : undefined;

          const disabled = isDisabledMenuOption(
            rowArticles,
            menuItemKey,
            contextMenuRow,
            contextCellIdx,
            copiedRow,
          );

          return [
            <MenuItem
              disabled={disabled}
              onClick={onClick}
              key={menuItemKey}
              sx={{
                fontSize: '14px',
                margin: '5px 0 0 0',
                padding: '0 5px',
                width: '200px',
              }}
            >
              {nl[menuItemKey] ?? menuItemKey}
            </MenuItem>,
            contextMenuDividers.includes(index) && <Divider key={index} />,
          ];
        })}
      </MenuList>
      <Modal
        key='keyShiftModal'
        open={!!shiftModalProps}
        close={() => setShiftModalProps(null)}
        replaceContent
      >
        <ShiftRowModal />
      </Modal>
    </>
  );
};

export default ContextMenu;
