import { type FC, type PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { Box } from '@mui/system';

import { CloseIcon } from '../../assets/icons';
import {
  ModalCloseIcon,
  ModalContainer,
  ModalContent,
  ModalOverlay,
} from './styles';

interface IModalProps extends PropsWithChildren {
  open: boolean;
  close: () => void;
  replaceContent?: boolean;
}

const Modal: FC<IModalProps> = ({ children, open, close, replaceContent }) => {
  const modalRoot = document.getElementById('modal-root');

  if (!open || !modalRoot) {
    return null;
  }

  return createPortal(
    <Box className='modal' sx={ModalContainer}>
      <Box className='modal-overlay' onClick={close} sx={ModalOverlay} />
      {replaceContent ? (
        children
      ) : (
        <Box className='modal-content' sx={ModalContent}>
          <CloseIcon onClick={close} sx={ModalCloseIcon} />
          <Box sx={{ height: 'calc(100% - 40px)', padding: '20px' }}>
            {children}
          </Box>
        </Box>
      )}
    </Box>,
    modalRoot,
  );
};

export default Modal;
