import { getWeekNumber } from '../../ProductionPlanProvider/utils/getWeekNumber';
import {
  type ProductionStatus,
  type RawArticlesDataType,
} from './getTableData';

export default [
  {
    ordertype: 'Make to Order',
    article_code: '4110200MVHO',
    article_description: 'Renovatierooster 248x110x13 ',
    article_length: '248',
    article_width: '110',
    article_height: '13',
    poss_moulds: '0096250-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 260,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: 'null',
    quantity_per_pallet: '',
    quantity_required: 0,
    economic_stock: '10',
    available_stock: '1',
    week: 0,
    year: 2023,
    productions: [
      {
        production_status: 'Entered' as ProductionStatus,
        year: '2024',
        week: '2',
        quantity_to_produce: 50,
        production_nr: '23004413',
        machine_planning_id: '059ACA1E-3B6F-4495-B89B-E4B26E2C5F1A',
        customer: 'Landgoed de Princepeel  ',
        salesorder_nr: 'AB-SO-2023-00373',
      },
    ],
  },
  {
    ordertype: 'Make to Order',
    article_code: '4110200MVDI',
    article_description: 'Bev. tussenwand / muur',
    article_length: null,
    article_width: null,
    article_height: null,
    poss_moulds: null,
    plan_quantity: null,
    plan_custom_item: null,
    quantity_per_pallet: null,
    machine_park: null,
    possible_machines: null,
    length_mould: null,
    repeats: null,
    quantity_per_mould: null,
    turn: null,
    total_quantity_to_produce: null,
    economic_stock: null,
    available_stock: null,
    productions: [
      {
        production_status: 'Planned',
        year: null,
        week: null,
        quantity_to_produce: 30,
        production_nr: '23003388',
        machine_planning_id: null,
        customer: 'Albers Holding Landhorst',
        salesorder_nr: 'AB-SO-2023-00230',
        machine_code_planned: 'TELBON',
        ean_codes: [
          {
            production_method: 'TELBON',
            ean_code: '23003388001',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: null,
        week: null,
        quantity_to_produce: 60,
        production_nr: '23003390',
        machine_planning_id: null,
        customer: 'Albers Holding Landhorst',
        salesorder_nr: 'AB-SO-2023-00230',
        machine_code_planned: 'TELBON',
        ean_codes: [
          {
            production_method: 'TELBON',
            ean_code: '23003390001',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: null,
        week: null,
        quantity_to_produce: 9,
        production_nr: '23004156',
        machine_planning_id: null,
        customer: 'IV Bouw & Montage',
        salesorder_nr: 'AB-SO-2023-00389',
        machine_code_planned: 'TELBON',
        ean_codes: [
          {
            production_method: 'TELBON',
            ean_code: '23004156001',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: null,
        week: null,
        quantity_to_produce: 11,
        production_nr: '23004158',
        machine_planning_id: null,
        customer: 'IV Bouw & Montage',
        salesorder_nr: 'AB-SO-2023-00389',
        machine_code_planned: 'TELBON',
        ean_codes: [
          {
            production_method: 'TELBON',
            ean_code: '23004158001',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: null,
        week: null,
        quantity_to_produce: 23,
        production_nr: '23004160',
        machine_planning_id: null,
        customer: 'Landgoed de Princepeel  ',
        salesorder_nr: 'AB-SO-2023-00374',
        machine_code_planned: 'TELBON',
        ean_codes: [
          {
            production_method: 'TELBON',
            ean_code: '23004160001',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Make to Order',
    article_code: 'BEV1120',
    article_description: 'Bev. tussenwand / voorwand',
    article_length: null,
    article_width: null,
    article_height: null,
    poss_moulds: null,
    plan_quantity: null,
    plan_custom_item: null,
    quantity_per_pallet: null,
    machine_park: null,
    possible_machines: null,
    length_mould: null,
    repeats: null,
    quantity_per_mould: null,
    turn: null,
    total_quantity_to_produce: null,
    economic_stock: null,
    available_stock: null,
    productions: [
      {
        production_status: 'Planned',
        year: null,
        week: null,
        quantity_to_produce: 9,
        production_nr: '23004157',
        machine_planning_id: null,
        customer: 'IV Bouw & Montage',
        salesorder_nr: 'AB-SO-2023-00389',
        machine_code_planned: 'TELBON',
        ean_codes: [
          {
            production_method: 'TELBON',
            ean_code: '23004157001',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: null,
        week: null,
        quantity_to_produce: 11,
        production_nr: '23004159',
        machine_planning_id: null,
        customer: 'IV Bouw & Montage',
        salesorder_nr: 'AB-SO-2023-00389',
        machine_code_planned: 'TELBON',
        ean_codes: [
          {
            production_method: 'TELBON',
            ean_code: '23004159001',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Make to Order',
    article_code: 'BEV1170',
    article_description: 'Bev. voorwand / roostervloer',
    article_length: null,
    article_width: null,
    article_height: null,
    poss_moulds: null,
    plan_quantity: null,
    plan_custom_item: null,
    quantity_per_pallet: null,
    machine_park: null,
    possible_machines: null,
    length_mould: null,
    repeats: null,
    quantity_per_mould: null,
    turn: null,
    total_quantity_to_produce: null,
    economic_stock: null,
    available_stock: null,
    productions: [
      {
        production_status: 'Planned',
        year: null,
        week: null,
        quantity_to_produce: 12,
        production_nr: '23003389',
        machine_planning_id: null,
        customer: 'Albers Holding Landhorst',
        salesorder_nr: 'AB-SO-2023-00230',
        machine_code_planned: 'TELBON',
        ean_codes: [
          {
            production_method: 'TELBON',
            ean_code: '23003389001',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Make to Order',
    article_code: 'MRPDELAVAL18L300B078',
    article_description: 'Melkrobotplaat Delaval 18cm 300x78cm',
    article_length: 300,
    article_width: 78,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: null,
    plan_custom_item: null,
    quantity_per_pallet: '1',
    machine_park: 'ZVBMX',
    possible_machines: 'ZVBMRPLELY',
    length_mould: 320,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: null,
    available_stock: null,
    productions: [
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 1,
        production_nr: '23004578',
        machine_planning_id: '92A0FEA3-FF6D-48A3-9A31-71A41B47DE96',
        customer: 'Bouwbedrijf H. Bouwers',
        salesorder_nr: 'AB-SO-2023-00281',
        machine_code_planned: 'ZVBMRPLELY',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004578001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004578002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '96248',
    article_description: 'Renovatierooster 248x110x13 ',
    article_length: 248,
    article_width: 110,
    article_height: 13,
    poss_moulds: '0096250-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 260,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 10,
    available_stock: 1,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2022,
        week: 53,
        quantity_to_produce: 1,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 45,
        quantity_to_produce: 10,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2024,
        week: 1,
        quantity_to_produce: 10,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '96273',
    article_description: 'Renovatierooster 273x110x13 ',
    article_length: 273,
    article_width: 110,
    article_height: 13,
    poss_moulds: '0096275-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 285,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: -5,
    available_stock: 20,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 12,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 12,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 10,
        production_nr: '23004861',
        machine_planning_id: 'D010E730-244C-4702-9717-4B2CAE001302',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004861001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004861002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '96298',
    article_description: 'Renovatierooster 298x110x13 ',
    article_length: 298,
    article_width: 110,
    article_height: 13,
    poss_moulds: '0096300-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 1,
    available_stock: 90,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 83,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 146,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 40,
        production_nr: '23004624',
        machine_planning_id: '4446FEA8-002F-4A71-9D43-FFD712464B09',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004624001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004624002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 10,
        production_nr: '23004863',
        machine_planning_id: 'A0942C12-05E9-4AFC-9AD9-429075F7DB9A',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004863001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004863002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 70,
        production_nr: '23004866',
        machine_planning_id: '56C23EE2-489D-4FAC-B32B-427686E815A8',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004866001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004866002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 20,
        production_nr: '23004867',
        machine_planning_id: 'B5C8AD4C-EA40-4375-ADF8-2FA7875335C6',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004867001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004867002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '230300',
    article_description: 'PORCO-Varkensrooster 300x115x12                   ',
    article_length: 300,
    article_width: 115,
    article_height: 12,
    poss_moulds: '0230300-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '120',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 4,
    available_stock: 9,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 19,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 14,
        production_nr: '23004856',
        machine_planning_id: '45BCC49D-2287-4994-BA85-875A4AF13591',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004856001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004856002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '509250',
    article_description:
      'T-Opstortplaat berijdbaar 250x120x12                               ',
    article_length: 250,
    article_width: 120,
    article_height: 12,
    poss_moulds: '0503660-1;0503660-2',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '312',
    length_mould: 260,
    repeats: '2',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: -13,
    available_stock: 255,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 176,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 108,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 18,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 8,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 7,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 12,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 39,
        quantity_to_produce: 55,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 42,
        quantity_to_produce: 43,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 33,
        production_nr: '23004571',
        machine_planning_id: 'D9AA5F8A-4A76-4F9A-A0D2-BC5C6E782E94',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004571001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004571002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '509310',
    article_description:
      'T-Opstortplaat berijdbaar 310x120x12                               ',
    article_length: 310,
    article_width: 120,
    article_height: 12,
    poss_moulds: '0503660-2;0503660-1',
    plan_quantity: 'EXACT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '312',
    length_mould: 320,
    repeats: '2',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 0,
    available_stock: 0,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 33,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 33,
        production_nr: '23004576',
        machine_planning_id: 'C5B26898-78AF-4DB0-8D8B-9B98D5ABEBC2',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004576001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004576002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '660200',
    article_description: 'Stro-rooster 200x100x20  ',
    article_length: 200,
    article_width: 100,
    article_height: 20,
    poss_moulds: '0660300-1;0660300-2',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '120',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 85,
    available_stock: 15,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 16,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 70,
        production_nr: '23004844',
        machine_planning_id: '80116A03-7AC3-4708-8850-FA8824BF9171',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004844001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004844002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '660240',
    article_description: 'Stro-rooster 240x100x20  ',
    article_length: 240,
    article_width: 100,
    article_height: 20,
    poss_moulds: '0660300-2',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '120',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 51,
    available_stock: 77,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 46,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 23,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 27,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 70,
        production_nr: '23004845',
        machine_planning_id: 'C04F6958-CF2B-4BD3-9D3C-70CEABAEAA00',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004845001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004845002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '660280',
    article_description: 'Stro-rooster 280x100x20  ',
    article_length: 280,
    article_width: 100,
    article_height: 20,
    poss_moulds: '0660300-2',
    plan_quantity: 'EXACT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '120',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 1,
    available_stock: 1,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 7,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 7,
        production_nr: '23004575',
        machine_planning_id: '6B235DFD-DED7-4D21-B117-92ECD9AD47D8',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004575001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004575002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '685300',
    article_description: 'Voergangrooster 300x120x22 ',
    article_length: 300,
    article_width: 120,
    article_height: 22,
    poss_moulds: '0680300-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '120',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 56,
    available_stock: 343,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 17,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 266,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 3,
        production_nr: '23004778',
        machine_planning_id: '83C685C5-C2F7-43CD-9862-18A7C6B471BD',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004778001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004778002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1399350',
    article_description: 'Mammoetrooster rundvee 350x110x18  ',
    article_length: 350,
    article_width: 110,
    article_height: 18,
    poss_moulds: '1399350-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 360,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: -54,
    available_stock: 6,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 50,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 37,
        quantity_to_produce: 19,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 44,
        production_nr: '23004574',
        machine_planning_id: 'AF73689C-38D3-48F8-9EB1-0DA6BA59E7FE',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004574001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004574002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499200',
    article_description: 'Profielrooster rundvee 200x110x18',
    article_length: 200,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 210,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 83,
    available_stock: 96,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 5,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 76,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 235,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 28,
        quantity_to_produce: 95,
        production_nr: '23004197',
        machine_planning_id: 'AD614302-55FD-49C9-A528-AC289ECA6C88',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 44,
        production_nr: '23004600',
        machine_planning_id: '1135DFBF-3F77-4B26-9433-5F3D54B01DD2',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004600001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004601001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004601002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 70,
        production_nr: '23004604',
        machine_planning_id: '08D2AE33-8A1C-460A-8ABC-39EF92189B8A',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004604001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004605001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004605002',
          },
        ],
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 28,
        quantity_to_produce: 33,
        production_nr: '23004690',
        machine_planning_id: '9CA79549-EB7B-47C4-91BF-43DC000FF302',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 90,
        production_nr: '23004846',
        machine_planning_id: 'A492F01C-9088-4024-9647-453F9D2F9646',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004846001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004847001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004847002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499230',
    article_description: 'Profielrooster rundvee 230x110x18  ',
    article_length: 230,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 240,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 85,
    available_stock: 113,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 1,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 50,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 11,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 91,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 25,
        quantity_to_produce: 37,
        production_nr: '23003310',
        machine_planning_id: 'F54C600D-BFAE-4113-B7B1-9C4347BB1491',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499240',
    article_description:
      'Profielrooster rundvee 240x110x18                         ',
    article_length: 240,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 250,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 133,
    available_stock: 104,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 37,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 5,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 139,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 57,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 53,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 250,
        production_nr: '23004573',
        machine_planning_id: '73817857-5FE6-4BE2-A1F2-4EF37F3142D6',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004573001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004613001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004613002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 50,
        production_nr: '23004619',
        machine_planning_id: '4514DC2F-F875-4A58-A920-5E6A8C235547',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004619001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004620001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004620002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499250',
    article_description: 'Profielrooster rundvee 250x110x18  ',
    article_length: 250,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 260,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 294,
    available_stock: 330,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 18,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 9,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 26,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 14,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 42,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 24,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 26,
        quantity_to_produce: 30,
        production_nr: '23003309',
        machine_planning_id: '53C7534C-B56E-41C8-869F-657E9AD9D629',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 42,
        production_nr: '23004749',
        machine_planning_id: '751DB21E-17D4-41C2-A3C8-CEAE74DD1E1E',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004749001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004750001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004750002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 45,
        production_nr: '23004850',
        machine_planning_id: 'AB7129E8-FE4A-42F3-9123-9FE086FDDC7C',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004850001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004851001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004851002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 14,
        production_nr: '23004852',
        machine_planning_id: '1B16C91E-95B5-4BF1-9BE5-D7D5F409E14F',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004852001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004853001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004853002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499275',
    article_description: 'Profielrooster rundvee 275x110x18  ',
    article_length: 275,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 285,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 139,
    available_stock: 40,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 20,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 57,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 28,
        quantity_to_produce: 83,
        production_nr: '23004216',
        machine_planning_id: '2D41FBAD-7D06-492F-ABAE-BB3425DCD1DF',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [],
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 28,
        quantity_to_produce: 7,
        production_nr: '23004221',
        machine_planning_id: '6DE7B2F9-82D4-4D8B-9650-1113D2C0D635',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [],
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 28,
        quantity_to_produce: 76,
        production_nr: '23004568',
        machine_planning_id: '166E4158-1F05-4421-ADF4-D4C49323FDDB',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499300',
    article_description: 'Profielrooster rundvee 300x110x18                ',
    article_length: 300,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 347,
    available_stock: 570,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 140,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 22,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 240,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 84,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 23,
        quantity_to_produce: 60,
        production_nr: '23001460',
        machine_planning_id: 'F11D8D76-4346-4C33-AA1E-6DCD954CB41C',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [],
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 25,
        quantity_to_produce: 1,
        production_nr: '23002793',
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
        machine_code_planned: '401',
        ean_codes: [],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499350',
    article_description:
      'Profielrooster rundvee 350x110x18                          ',
    article_length: 350,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 360,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 495,
    available_stock: 649,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 33,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 228,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 26,
        quantity_to_produce: 54,
        production_nr: '23003316',
        machine_planning_id: 'CE08F9AF-328D-4A08-B7A4-9CBC03E71492',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 90,
        production_nr: '23004848',
        machine_planning_id: '02B313BE-B100-4F78-9442-AC1ECE1C34C6',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004848001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004849001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004849002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499375',
    article_description: 'Profielrooster rundvee 375x110x18  ',
    article_length: 375,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 385,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 172,
    available_stock: 32,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 48,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 5,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 62,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 250,
        production_nr: '23004577',
        machine_planning_id: 'B2375731-997F-4A0E-BC7D-BCB5D214F1E7',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004577001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004621001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004621002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1499400',
    article_description: 'Profielrooster rundvee 400x110x18  ',
    article_length: 400,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 409,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 266,
    available_stock: 159,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 34,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 17,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 28,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 41,
        quantity_to_produce: 36,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 28,
        quantity_to_produce: 96,
        production_nr: '23004196',
        machine_planning_id: '2BF5C12E-CFFE-4D41-9554-1EFAB80CEDEC',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 70,
        production_nr: '23004609',
        machine_planning_id: '40DDA356-215D-4EDF-8DD4-0CB7CB5EA3CD',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004609001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004610001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004610002',
          },
        ],
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 28,
        quantity_to_produce: 33,
        production_nr: '23004692',
        machine_planning_id: '2F3720AD-E1D9-4EA0-8A8F-0BC2AB31B5D6',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1599247',
    article_description:
      'ECO-Betonrooster 247x115x18                                         ',
    article_length: 247,
    article_width: 115,
    article_height: 18,
    poss_moulds: '1599250-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '210',
    length_mould: 260,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 0,
    available_stock: 15,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 44,
        quantity_to_produce: 20,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 5,
        production_nr: '23004596',
        machine_planning_id: '892937A1-2D2F-496E-9989-5224163D7D4C',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004596001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004596002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1599400',
    article_description:
      'ECO-Betonrooster 400x115x18                                         ',
    article_length: 400,
    article_width: 115,
    article_height: 18,
    poss_moulds: '1599400-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '210',
    length_mould: 409,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 288,
    available_stock: 308,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 103,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 1,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 42,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 24,
        production_nr: '23004603',
        machine_planning_id: 'C28AC3AB-094B-4C76-93B1-3461C379EDE6',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004603001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004603002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 5,
        production_nr: '23004612',
        machine_planning_id: 'CE4B90D1-EBCC-47A0-8E66-C809294EFC1E',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004612001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004612002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 70,
        production_nr: '23004775',
        machine_planning_id: 'FA970942-210D-45BA-A94A-BC7C7DCBF96B',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004775001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004775002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 28,
        production_nr: '23004776',
        machine_planning_id: '23173E08-C588-4655-8C21-9F2647AF6750',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004776001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004776002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1799300',
    article_description: 'Profielrooster jongvee 300x110x18  ',
    article_length: 300,
    article_width: 110,
    article_height: 18,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 92,
    available_stock: 2,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 5,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 50,
        production_nr: '23004687',
        machine_planning_id: '9ACF0D9B-8D03-4448-AA56-137E7DE42697',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004687001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004688001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004688002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 45,
        production_nr: '23004854',
        machine_planning_id: '85DFDC16-3E4A-449D-A6E1-FCFD7FC61248',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004854001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004855001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004855002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '1899247',
    article_description: 'ECO-Roostervloer 247x115x18 10 ton Aslast',
    article_length: 247,
    article_width: 115,
    article_height: 18,
    poss_moulds: '1599250-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '210',
    length_mould: 260,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 24,
    available_stock: 0,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 24,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 28,
        quantity_to_produce: 24,
        production_nr: '23004514',
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004514001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004514002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 24,
        production_nr: '23004607',
        machine_planning_id: '9149F1C3-5B5C-48A2-BCFC-95C12C7BAE10',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004607001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004607002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '3125180',
    article_description: 'Onderslagbalk 30x25x180 Berijdbaar vrachtwagen',
    article_length: 180,
    article_width: 25,
    article_height: 30,
    poss_moulds: '3025410-1',
    plan_quantity: 'EXACT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVBBALK400',
    length_mould: 190,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 0,
    available_stock: 0,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 4,
        production_nr: '23004700',
        machine_planning_id: 'B92D8565-FFF5-48CA-8FBF-A1FC88359036',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVBBALK400',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004700001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004700002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '3125215',
    article_description: 'Onderslagbalk 30x25x215 Berijdbaar vrachtwagen',
    article_length: 215,
    article_width: 25,
    article_height: 30,
    poss_moulds: '3025400-1',
    plan_quantity: 'EXACT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVBBALK400',
    length_mould: 400,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 0,
    available_stock: 0,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 8,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 4,
        production_nr: '23004699',
        machine_planning_id: 'D744302A-204B-4ED9-8A87-01CEE7355C71',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVBBALK400',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004699001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004699002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 4,
        production_nr: '23004723',
        machine_planning_id: '2D698D3F-A418-47B5-839E-F0DE25CC5D07',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVBBALK400',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004723001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004723002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '11099300',
    article_description: 'Massief mammoetrooster 300x115x18',
    article_length: 300,
    article_width: 115,
    article_height: 18,
    poss_moulds: '11099300-2;11099300-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '120',
    length_mould: 310,
    repeats: '2',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 22,
    available_stock: 106,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 48,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 45,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 17,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 36,
        quantity_to_produce: 16,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 42,
        production_nr: '23004751',
        machine_planning_id: '3F234E61-2CC7-4340-A4EB-8B7C276BC954',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM2',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004751001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004751002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '11099325',
    article_description: 'Massief mammoetrooster 325x115x18',
    article_length: 325,
    article_width: 115,
    article_height: 18,
    poss_moulds: '11099350-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '120',
    length_mould: 360,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 13,
    available_stock: 49,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 36,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 3,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 3,
        production_nr: '23004774',
        machine_planning_id: '3773B902-BBFF-4C12-A4B3-33974EC014BF',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004774001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004774002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '96273PR',
    article_description: 'Renovatie profielrooster rundvee 273x110x13 ',
    article_length: 273,
    article_width: 110,
    article_height: 13,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 285,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 184,
    available_stock: 177,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 22,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 5,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 13,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 24,
        quantity_to_produce: 11,
        production_nr: '23001919',
        machine_planning_id: 'B0994A6D-9929-4DCD-AF8B-84D9E366A396',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 40,
        production_nr: '23004626',
        machine_planning_id: 'BA95AC30-20F6-4B69-AF75-FCAAD979D22F',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004626001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004627001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004627002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '96198PR',
    article_description: 'Renovatie profielrooster rundvee 198x110x13 ',
    article_length: 198,
    article_width: 110,
    article_height: 13,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 210,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 110,
    available_stock: 135,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 13,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 21,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'In execution',
        year: 2023,
        week: 24,
        quantity_to_produce: 11,
        production_nr: '23002099',
        machine_planning_id: 'AF5F0095-C34C-4FD5-ABC7-9485EEA2A032',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '96298PR',
    article_description: 'Renovatie profielrooster rundvee 298x110x13 ',
    article_length: 298,
    article_width: 110,
    article_height: 13,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 310,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 37,
    available_stock: 94,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 87,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 60,
        production_nr: '23004625',
        machine_planning_id: '20783988-6E4E-438B-B489-DDF7178B6AB3',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004625001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004628001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004628002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '96248PR',
    article_description: 'Renovatie profielrooster rundvee 248x110x13 ',
    article_length: 248,
    article_width: 110,
    article_height: 13,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 260,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 138,
    available_stock: 330,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 76,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 32,
        quantity_to_produce: 109,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 10,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 35,
        quantity_to_produce: 29,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 60,
        production_nr: '23004629',
        machine_planning_id: '41BA711E-F6CC-4AA5-863E-BA795146E53A',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004629001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004630001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004630002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 70,
        production_nr: '23004864',
        machine_planning_id: '6C1ACC2E-D0E6-4591-B001-95F9B131D3AF',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM3',
        ean_codes: [
          {
            production_method: 'BETON PROFILERING',
            ean_code: '23004864001',
          },
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004865001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004865002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '8531250LA',
    article_description:
      'Eindligboxwand 250x165x14 met half prefab boxdek (links afgeschuind)            ',
    article_length: 250,
    article_width: 165,
    article_height: 14,
    poss_moulds: '8531165L-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVB8531L',
    length_mould: 300,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: -1,
    available_stock: 3,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 1,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 1,
        production_nr: '23004697',
        machine_planning_id: '7A884B9C-6F0A-4964-A755-C791B9B89A80',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8531L',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004697001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004697002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '8604275RA',
    article_description:
      'Eindligboxwand 275x165x14 met voet (rechts afgeschuind)',
    article_length: 275,
    article_width: 165,
    article_height: 14,
    poss_moulds: '8604165R-1',
    plan_quantity: 'EXACT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVB8604R',
    length_mould: 300,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: -7,
    available_stock: 0,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 1,
        production_nr: '23004698',
        machine_planning_id: '0AE1F6E4-59C0-47EB-805D-F32BA23405BF',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604R',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004698001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004698002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '8531280LA',
    article_description:
      'Eindligboxwand 280x165x14 met half prefab boxdek (links afgeschuind)            ',
    article_length: 280,
    article_width: 165,
    article_height: 14,
    poss_moulds: '8531165L-1',
    plan_quantity: 'EXACT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVB8531L',
    length_mould: 300,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 0,
    available_stock: 0,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 1,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 37,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 1,
        production_nr: '23004701',
        machine_planning_id: '401A828F-FD89-4D5E-9929-8C7F747D35DE',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8531L',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004701001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004701002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '8604275LA',
    article_description:
      'Eindligboxwand 275x165x14 met voet (links afgeschuind)',
    article_length: 275,
    article_width: 165,
    article_height: 14,
    poss_moulds: '8604165L-1',
    plan_quantity: 'EXACT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVB8604L',
    length_mould: 300,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: -7,
    available_stock: 0,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 1,
        production_nr: '23004702',
        machine_planning_id: 'CA4700D9-6481-4113-95B5-EAC5BF9C75DC',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604L',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004702001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004702002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '820020014LGC',
    article_description:
      'AB FORTE-Verhardingsplaat licht grijs 200x200x14 type C',
    article_length: 200,
    article_width: 200,
    article_height: 14,
    poss_moulds: null,
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'KT',
    length_mould: 200,
    repeats: '14',
    quantity_per_mould: 1,
    turn: 1,
    total_quantity_to_produce: null,
    economic_stock: 14,
    available_stock: 7,
    productions: [
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 7,
        production_nr: '23004703',
        machine_planning_id: 'CD775644-DA7B-4903-85F0-D08E63DB0A2B',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'KT1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004703001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004703002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '8604250RA',
    article_description:
      'Eindligboxwand 250x165x14 met voet (rechts afgeschuind)',
    article_length: 250,
    article_width: 165,
    article_height: 14,
    poss_moulds: '8604165R-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVB8604R',
    length_mould: 300,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: -18,
    available_stock: 2,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 15,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 15,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004708',
        machine_planning_id: 'C245B8F8-7F13-4A51-B061-E730D8386F26',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604R',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004708001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004708002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004709',
        machine_planning_id: '399C123F-63CB-40C0-9B1D-17EEA5F86FD4',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604R',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004709001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004709002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004714',
        machine_planning_id: '0BC8744B-5395-4E1B-916A-1DB0FC74E653',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604R',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004714001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004714002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004715',
        machine_planning_id: '68B0B97C-82F6-4F74-BC69-5D8BFF0C2CA9',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604R',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004715001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004715002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004716',
        machine_planning_id: '0619D25D-BDC3-4A02-AEE2-F958A379A420',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604R',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004716001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004716002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '8604250LA',
    article_description:
      'Eindligboxwand 250x165x14 met voet (links afgeschuind)',
    article_length: 250,
    article_width: 165,
    article_height: 14,
    poss_moulds: '8604165L-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVB8604L',
    length_mould: 300,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: -18,
    available_stock: 2,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 15,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 34,
        quantity_to_produce: 15,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004710',
        machine_planning_id: 'B25FD156-310D-4E76-AAF7-34FBA389E448',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604L',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004710001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004710002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004711',
        machine_planning_id: '080081D2-938C-4F1A-9DF1-6AC9E4475CEB',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604L',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004711001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004711002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004712',
        machine_planning_id: '37016C66-306F-4A9A-94E6-CBB94B008EB6',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604L',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004712001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004712002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004713',
        machine_planning_id: '516A160B-5C46-429F-9E2F-250AD76CED74',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604L',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004713001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004713002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004717',
        machine_planning_id: 'DFDA591E-E9A6-4F0A-AA7D-DA88F9EEBC35',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8604L',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004717001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004717002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: 'DSR30042',
    article_description:
      'Diepstrooisel Rand Hoogte 42Cm Lengte 300Cm                              ',
    article_length: 300,
    article_width: 42,
    article_height: 15,
    poss_moulds: '0DSR300-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVBDSR',
    length_mould: 300,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: -70,
    available_stock: 42,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 42,
        quantity_to_produce: 132,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004718',
        machine_planning_id: '60719BD6-5C4D-4FA6-96F6-3397540BB0FD',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVBDSR',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004718001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004718002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004719',
        machine_planning_id: 'D8E073C1-9BD4-4149-9D95-0BECBBA5FB6C',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVBDSR',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004719001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004719002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004720',
        machine_planning_id: '58A3A80F-6BD7-44DF-B1B8-E58E8E4F2CE7',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVBDSR',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004720001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004720002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004721',
        machine_planning_id: 'F1782BC6-3688-4808-83B6-9DBC53EBC992',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVBDSR',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004721001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004721002',
          },
        ],
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 2,
        production_nr: '23004722',
        machine_planning_id: '9F863769-A692-4E29-BA6F-EF2C879EA16A',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVBDSR',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004722001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004722002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '8531250RA',
    article_description:
      'Eindligboxwand 250x165x14 met half prefab boxdek (rechts afgeschuind)           ',
    article_length: 250,
    article_width: 165,
    article_height: 14,
    poss_moulds: '8531165R-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'ZVBMX',
    possible_machines: 'ZVB8531R',
    length_mould: 300,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 0,
    available_stock: 3,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 33,
        quantity_to_produce: 4,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 37,
        quantity_to_produce: 1,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 1,
        production_nr: '23004754',
        machine_planning_id: 'D89EB9FB-6806-4B04-9457-2B0328665D21',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'ZVB8531R',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004754001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004754002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '806240A',
    article_description: 'HYDRO-comfort-plaat 240x120x14',
    article_length: 240,
    article_width: 120,
    article_height: 14,
    poss_moulds: '806250-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 250,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 32,
    available_stock: 7,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 31,
        quantity_to_produce: 45,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 70,
        production_nr: '23004777',
        machine_planning_id: '771599A5-AFEC-4AE9-B73C-5658D8D98131',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004777001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004777002',
          },
        ],
      },
    ],
  },
  {
    ordertype: 'Stock Order',
    article_code: '806240B',
    article_description: 'HYDRO-comfort-plaat 240x120x14',
    article_length: 240,
    article_width: 120,
    article_height: 14,
    poss_moulds: '806250-1',
    plan_quantity: 'ONBEPERKT',
    plan_custom_item: 0,
    machine_park: 'AVBMX',
    possible_machines: '123',
    length_mould: 250,
    repeats: '1',
    quantity_per_mould: 1,
    turn: 0,
    total_quantity_to_produce: null,
    economic_stock: 205,
    available_stock: 551,
    productions: [
      {
        production_status: 'On salesorder',
        year: 2023,
        week: 30,
        quantity_to_produce: 430,
        production_nr: null,
        machine_planning_id: null,
        customer: null,
        salesorder_nr: null,
      },
      {
        production_status: 'Planned',
        year: 2023,
        week: 32,
        quantity_to_produce: 29,
        production_nr: '23004779',
        machine_planning_id: '6CA1DC67-B39A-4A21-9851-06869E6845DD',
        customer: null,
        salesorder_nr: null,
        machine_code_planned: 'AVBM1',
        ean_codes: [
          {
            production_method: 'BETON GIETEN',
            ean_code: '23004779001',
          },
          {
            production_method: 'LABELLEN + QC',
            ean_code: '23004779002',
          },
        ],
      },
    ],
  },
] as unknown as Array<RawArticlesDataType>;

const currentYear = new Date().getFullYear();
const todayWeekNumber = getWeekNumber(new Date(), 0);

export const ARTICLE_WITH_YEAR_DIFFERENCE: RawArticlesDataType = {
  ordertype: 'Stock Order',
  article_code: '96248',
  article_description: 'Renovatierooster 248x110x13 ',
  article_length: '248',
  article_width: '110',
  article_height: '13',
  poss_moulds: '0096250-1',
  plan_quantity: 'ONBEPERKT',
  plan_custom_item: 0,
  machine_park: 'AVBMX',
  possible_machines: '123',
  length_mould: 260,
  repeats: '1',
  quantity_per_mould: 1,
  turn: 1,
  total_quantity_to_produce: 'null',
  quantity_per_pallet: '',
  quantity_required: 0,
  economic_stock: '10',
  available_stock: '1',
  week: 0,
  year: currentYear,
  productions: [
    {
      production_status: 'On salesorder' as ProductionStatus,
      year: `${currentYear - 1}`,
      week: '53',
      quantity_to_produce: 1,
      production_nr: null,
      machine_planning_id: null,
      customer: null,
      salesorder_nr: null,
    },
    {
      production_status: 'On salesorder' as ProductionStatus,
      year: `${currentYear}`,
      week: '45',
      quantity_to_produce: 10,
      production_nr: null,
      machine_planning_id: null,
      customer: null,
      salesorder_nr: null,
    },
    {
      production_status: 'On salesorder' as ProductionStatus,
      year: `${currentYear + 1}`,
      week: '1',
      quantity_to_produce: 10,
      production_nr: null,
      machine_planning_id: null,
      customer: null,
      salesorder_nr: null,
    },
  ],
};

export const MAKE_TO_ORDER_ARTICLE: RawArticlesDataType = {
  ordertype: 'Make to Order',
  article_code: '4110200MVHO',
  article_description: 'Renovatierooster 248x110x13 ',
  article_length: '248',
  article_width: '110',
  article_height: '13',
  poss_moulds: '0096250-1',
  plan_quantity: 'ONBEPERKT',
  plan_custom_item: 0,
  machine_park: 'AVBMX',
  possible_machines: '123',
  length_mould: 260,
  repeats: '1',
  quantity_per_mould: 1,
  turn: 1,
  total_quantity_to_produce: 'null',
  quantity_per_pallet: '',
  quantity_required: 0,
  economic_stock: '10',
  available_stock: '1',
  week: 0,
  year: currentYear,
  productions: [
    {
      production_status: 'Entered' as ProductionStatus,
      year: '2024',
      week: `${todayWeekNumber + 4}`,
      quantity_to_produce: 50,
      production_nr: '23004413',
      machine_planning_id: '059ACA1E-3B6F-4495-B89B-E4B26E2C5F1A',
      customer: 'Landgoed de Princepeel  ',
      salesorder_nr: 'AB-SO-2023-00373',
    },
  ],
};
