import { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { nl } from '../../../../assets/text/nl';
import config from '../../../../config';
import { useArticlesContext } from '../../../../core/providers/ArticlesTableProvider';

const { availableMoldsTableLength } = config;

export const AvailableMoldsTable = () => {
  const {
    articlesData: { selectedRow },
  } = useArticlesContext();
  const { mouldPosition } = selectedRow || {};

  const mouldArray = useMemo(() => {
    if (!mouldPosition) {
      return;
    }

    return mouldPosition.split(';');
  }, [mouldPosition]);

  const rows = Array.from(Array(availableMoldsTableLength)).map(
    (_item, idx) => {
      return mouldArray?.[idx];
    },
  );

  return (
    <TableContainer sx={{ mt: '10px' }}>
      <Table aria-label='molds-table'>
        <TableHead>
          <TableRow>
            <TableCell colSpan={availableMoldsTableLength}>
              <Typography variant='body2'>{nl.availableMolds}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {rows.map((el, idx) => (
              <TableCell
                align='right'
                size='small'
                key={idx}
                width={`${100 / availableMoldsTableLength}%`}
              >
                {el}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
