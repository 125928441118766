import { type ProductionPlanMachine } from '../../ProductionPlanProvider/types';
import { type ArticleDataType } from './ArticleTypes';

export const isInPossibleMachines = (
  machine: ProductionPlanMachine | undefined,
  article: ArticleDataType,
) => {
  if (!machine?.possibleMachines.length) {
    return true;
  }

  return machine?.possibleMachines.includes(article.possibleMachines);
};
