import { type MouseEvent, useMemo, useRef, useState } from 'react';

export type ContextMenuEventType = MouseEvent<
  HTMLDivElement,
  globalThis.MouseEvent
>;

export const CONTEXT_MENU_DATE_KEY = 'data-context-menu-day';
export const CONTEXT_MENU_ROW_KEY = 'data-context-menu-row';
export const CONTEXT_MENU_CELL_IDX = 'data-context-cell-idx';

const initialPosition = { x: 0, y: 0 };

const menuSize = {
  width: 200,
  height: 260,
};

export const useContextMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState(initialPosition);
  const targetElement = useRef<HTMLElement | null>(null);

  const handleContextMenu = (event: ContextMenuEventType) => {
    event.preventDefault();

    setIsMenuVisible(true);
    setMenuPosition(calculateMenuPosition(event.clientX, event.clientY));

    const { target } = event;

    if (!(target instanceof HTMLElement)) {
      return;
    }

    targetElement.current = target.closest(`[${CONTEXT_MENU_DATE_KEY}]`);
  };

  const returnValue = useMemo(() => {
    return {
      isMenuVisible,
      menuPosition,
      targetElement,
      handleContextMenu,
      setIsMenuVisible,
    };
  }, [isMenuVisible, menuPosition]);

  return returnValue;
};

const calculateMenuPosition = (clientX: number, clientY: number) => {
  const result = { x: clientX, y: clientY };

  const { innerWidth, innerHeight } = window;

  if (innerWidth - clientX < menuSize.width) {
    result.x = clientX - menuSize.width;
  }

  if (innerHeight - clientY < menuSize.height) {
    result.y = clientY - menuSize.height;
  }

  return result;
};
