import {
  getWeekNumber,
  weeksInYear,
} from '../../ProductionPlanProvider/utils/getWeekNumber';
import { type RawArticlesDataType, ProductionStatus } from './getTableData';

export const getMakeToOrderShortages = (
  dataItem: RawArticlesDataType,
  week: number | undefined,
) => {
  const production = dataItem.productions[0];

  // What about more productions in a item?

  if (!production || !production.week || !production.year) {
    return { data: '0', stockIsEnough: true };
  }

  const todayWeekNumber = getWeekNumber(new Date(), 0);

  const currentYear = new Date().getFullYear();
  const intProductionYear = parseInt(production.year, 10);
  const weeksInThisYear = weeksInYear(currentYear);

  const intProductionWeek = parseInt(production.week, 10);

  const productionWeek =
    intProductionYear > currentYear
      ? weeksInThisYear + intProductionWeek
      : intProductionWeek;

  const isSelectedWeek =
    typeof week === 'number'
      ? productionWeek === getWeekNumber(new Date(), week)
      : productionWeek - todayWeekNumber > 5;

  const {
    production_status: productionStatus,
    quantity_to_produce: quantityToProduce,
  } = production || {};

  if (productionStatus === ProductionStatus.Entered && isSelectedWeek) {
    return { data: quantityToProduce ?? '0', stockIsEnough: true };
  }

  return { data: '0', stockIsEnough: true };
};
