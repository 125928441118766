import { type ArticleDataType } from '../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { type IInsertedArticle } from '../../../core/providers/ProductionPlanProvider/types';
import { getPalletesNumber } from './getPalletesNumber';

export const getMaxPalettesNumber = (
  dayArticles: Array<ArticleDataType> | Array<IInsertedArticle> | undefined,
) => {
  if (!dayArticles || dayArticles.length === 0) {
    return null;
  }

  const paletteNumbers = dayArticles?.map(getPalletesNumber) || [];

  return Math.max(...(paletteNumbers as number[])) || null;
};

export const getMaxPalettesOverall = (
  insertedArticles: Array<ArticleDataType> | undefined,
) => {
  return getMaxPalettesNumber(insertedArticles);
};
