import { useCallback } from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { Chevron } from '../../../../../assets/icons';
import {
  type SelectedHeadersType,
  useArticlesContext,
} from '../../../../../core/providers/ArticlesTableProvider';
import { SortOrder } from '../../../../../core/providers/ArticlesTableProvider/types';
import { ResizableHeadTableCell } from './ResizableHeadTableCell';

const sortOptions = ['productionWeek', 'possibleMachines'];

const oppositeSortOrder = {
  [SortOrder.Ascending]: SortOrder.Descending,
  [SortOrder.Descending]: SortOrder.Ascending,
};

export const HeaderCellsArticlesTable = () => {
  const {
    articlesData: { selectedHeaders, dictionary, sort },
    setSort,
  } = useArticlesContext();

  const handleSortChannge = useCallback(
    (cell: SelectedHeadersType[number]) => {
      const currentOrder = sort?.[cell];
      const order =
        currentOrder === undefined
          ? SortOrder.Ascending
          : oppositeSortOrder[currentOrder];

      setSort({ [cell]: order });
    },
    [setSort, sort],
  );

  return (
    <TableHead sx={{ position: 'relative' }}>
      <TableRow
        sx={{
          position: 'sticky',
          top: '-1px',
          zIndex: 1,
        }}
      >
        {selectedHeaders.map((cell) => (
          <TableCell key={cell}>
            <ResizableHeadTableCell>
              <Typography variant='body2'>{dictionary?.[cell]}</Typography>
              {sortOptions.includes(cell) && (
                <Chevron
                  color={sort?.[cell] ? undefined : 'disabled'}
                  sx={{
                    cursor: 'pointer',
                    width: '13px',
                    transform: `rotate(${
                      sort?.[cell] === SortOrder.Ascending ? '180' : '0'
                    }deg)`,
                    transition: 'linear 100ms',
                  }}
                  onClick={() => handleSortChannge(cell)}
                />
              )}
            </ResizableHeadTableCell>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
