export const articlesHeadersTranslations = {
  repeats: 'Aantal herhalingen',
  turn: 'Draaien',
  height: 'Hoogte',
  articleCode: 'Artikel',
  articleDescription: 'Beschrijving',
  mouldLength: 'Mallengte',
  possibleMachines: 'Machine',
  quantityPerMould: 'Aantal per mal',
  orderType: 'Order type',
  length: 'Lengte',
  width: 'Breedte',
  quantityPlan: 'Plan aantal',
  productionSalesOrderNumber: 'Verkoop order',
  productionQuantityToProduce: `Aantal tekort`,
  planCustomItem: 'Plan maatwerk',
  mouldPosition: 'Malpositie of mogelijke mallen',
  machinePark: 'Machinepark',
  productionWeek: 'Jaar week',
  productionCustomer: 'Klant naam',
  productionYear: 'year',
  totalQuantityToProduce: 'Totaal tekort',
  weekZeroShortages: 'TK-W0',
  weekOneShortages: 'TK-W1',
  weekTwoShortages: 'TK-W2',
  weekThreeShortages: 'TK-W3',
  weekFourShortages: 'TK-W4',
  weekFiveShortages: 'TK-W5',
  weeksLeftShortages: 'TK-LT',
  productionNumber: 'Production nr',
  machinePlanningId: 'machinePlanningId',
  sum: 'Gepland',
} as const;
