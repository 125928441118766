import {
  type PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import config from '../../../config';
import { logError } from '../../helpers/logError';
import request from '../../helpers/request';

export type LoginProps = Record<'email' | 'password', string>;

interface AuthContextProps {
  isLogedIn: boolean;
  logout: () => void;
  login: (values: LoginProps) => void;
}

const { AUTH_STORAGE_KEY, LOGIN_ERROR_TOAST_ID } = config.localStorageKeys;

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined,
);

export const AuthProvider = memo(({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const [isLogedIn, setIsLogedIn] = useState<boolean>(
    !!localStorage.getItem(AUTH_STORAGE_KEY),
  );

  const login = useCallback(
    async (values: LoginProps) => {
      let response: unknown = null;

      try {
        response = await request(config.routes.auth, {
          method: 'POST',
          executionToken: 'authentication',
          body: values,
        });
      } catch (error) {
        logError(error);
      }

      if (!response) {
        toast.error('Incorrect email or password', {
          id: LOGIN_ERROR_TOAST_ID,
        });
        return;
      }

      localStorage.setItem(AUTH_STORAGE_KEY, 'true');
      setIsLogedIn(true);
      navigate('/');
    },
    [navigate],
  );

  const logout = useCallback(() => {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    setIsLogedIn(false);
    // navigate('/login');
  }, []);

  const value = useMemo(
    () => ({ isLogedIn, login, logout }),
    [isLogedIn, login, logout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});
