import toast from 'react-hot-toast';

import { nl } from '../../../assets/text/nl';
import { type ArticleDataType } from '../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { ArticleProductDetails } from '../components/ArticlesTableProductionPlan';

export const isArticleValuesError = (
  key: ArticleProductDetails,
  value: string,
  cellData: ArticleDataType | undefined,
) => {
  const numericValue = parseInt(value, 10);

  if (isNaN(numericValue)) {
    return false;
  }

  if (
    key === ArticleProductDetails.TimesToBeProduced &&
    numericValue % parseInt(cellData?.quantityPerMould ?? '', 10)
  ) {
    toast.error(nl.multiPerMouldError, { duration: 4000 });
  }

  const qPerMouldIsMoreThanDefault =
    key === ArticleProductDetails.QuantityPerMould &&
    numericValue > parseInt(cellData?.defaultQuantityPerMould ?? '', 10);

  return qPerMouldIsMoreThanDefault;
};
