import config from '../../config';
import { addStateRow } from '../helpers/addStateRow';
import { modifyStateArticle } from '../helpers/modifyStateArticle';
import { removeStateArticle } from '../helpers/removeStateArticle';
import { setStateArticle } from '../helpers/setStateArticle';
import {
  type IProductionData,
  type ProductionPlanAction,
  ProductionPlanActionType,
} from '../providers/ProductionPlanProvider/types';
import { parseDataFromLocalStorage } from '../providers/ProductionPlanProvider/utils/parseDataFromLocalStorage';
import { saveDataToLocalStorage } from '../providers/ProductionPlanProvider/utils/saveDataToLocalStorage';

export const reducerHOC = (
  state: IProductionData,
  payload: ProductionPlanAction,
): IProductionData => {
  const newState = ProductionPlanReducer(state, payload);

  saveDataToLocalStorage(newState);

  return newState;
};

const ProductionPlanReducer = (
  state: IProductionData,
  { type, payload }: ProductionPlanAction,
) => {
  switch (type) {
    case ProductionPlanActionType.SET_DATA:
      return initializeData(payload);

    case ProductionPlanActionType.SET_MACHINE:
      return {
        ...state,
        machine: payload,
      };

    case ProductionPlanActionType.SET_DAYS:
      return {
        ...state,
        days: payload,
      };

    case ProductionPlanActionType.SET_WEEK:
      return {
        ...state,
        week: payload,
      };

    case ProductionPlanActionType.SET_YEAR:
      return {
        ...state,
        year: payload,
      };

    case ProductionPlanActionType.SET_FULL_DATE:
      return {
        ...state,
        year: payload.year,
        days: payload.days,
        week: payload.week,
      };

    case ProductionPlanActionType.SET_COMMENT:
      const {
        rowIndex,
        comment,
        machineKey: commentMachineKey,
        key: commentKey,
      } = payload;

      const comments = state.comments?.[commentMachineKey]?.[commentKey] ?? [];

      comments[rowIndex] = comment;

      return {
        ...state,
        comments: {
          ...state.comments,
          [commentMachineKey]: {
            ...(state.comments?.[commentMachineKey] ?? {}),
            [commentKey]: comments,
          },
        },
      };

    case ProductionPlanActionType.SET_INSERTED_ARTICLE:
      return setStateArticle(state, payload);

    case ProductionPlanActionType.CHANGE_INSERTED_ARTICLE:
      return modifyStateArticle(state, payload);

    case ProductionPlanActionType.REMOVE_ARTICLE:
      return removeStateArticle(state, payload);

    case ProductionPlanActionType.ADD_ROW:
      return addStateRow(state, payload);

    case ProductionPlanActionType.CHANGE_MACHINE_PARK:
      return {
        ...state,
        machinePark: payload,
      };

    default:
      return {
        ...state,
      };
  }
};

const { PRODUCTION_PLAN_DATA } = config.localStorageKeys;

export const initializeData = (data: IProductionData) => {
  const localStorageData = localStorage.getItem(PRODUCTION_PLAN_DATA);

  if (!localStorageData) {
    return data;
  }

  return parseDataFromLocalStorage(data) ?? data;
};
