import { type Theme, type ThemeOptions } from '@mui/material/styles';

export default (theme: Theme): ThemeOptions['components'] => ({
  MuiSelect: {
    styleOverrides: {
      select: {
        borderRadius: 0,
        paddingLeft: 8,
        paddingRight: '40px !important',
        paddingTop: 5,
        paddingBottom: 5,
      },
      icon: {
        color: 'unset',
        borderLeft: `1px solid ${theme.palette.grey?.[200]}`,
        paddingLeft: 8,
        paddingRight: 8,
        height: '100%',
        fontSize: 13,
        top: 0,
        right: 0,
        background: theme.palette.primary.light,
      },
      iconOpen: {
        borderLeft: `none`,
        borderRight: `1px solid ${theme.palette.grey?.[200]}`,
      },
    },
  },
});
