import {
  type Dispatch,
  type FC,
  type SetStateAction,
  memo,
  useEffect,
  useMemo,
} from 'react';
import {
  type SelectChangeEvent,
  alpha,
  Box,
  Button,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';

import { DocumentIcon } from '../../../assets/icons';
import { nl } from '../../../assets/text/nl';
import config from '../../../config';
import { getWorkingDaysInWeeks } from '../../../core/helpers/getWorkingDaysInWeeks';
import { useSaveProductionPlan } from '../../../core/helpers/hooks/useSaveProductionPlan';
import { isValueTrueString } from '../../../core/helpers/isValueTrueString';
import { useProductionPlan } from '../../../core/providers/ProductionPlanProvider';
import { type ProductionPlanMachine } from '../../../core/providers/ProductionPlanProvider/types';
import { CustomSelect } from '../../CustomSelect/CustomSelect';
import PDFRenderButton from '../../PDFRender/PDFRender';
import { UploadArticlesModal } from './UploadArticlesModal';
import { WeekSelect } from './WeekSelect';

const { yearsToBeShown, allMachineParkKey } = config;

interface ProductionPlanOverheadButtonsProps {
  setShowAm: Dispatch<SetStateAction<boolean>>;
}

export const ProductionPlanOverheadButtons: FC<ProductionPlanOverheadButtonsProps> =
  memo(({ setShowAm }) => {
    const {
      productionPlanData: { days, week, year, machine, machinePark },
      availableMachines,
      machineParks,
      setWeek,
      setDays,
      setYear,
      setMachine,
    } = useProductionPlan();
    const { saveProductionPlan, currentUploads, closeModal } =
      useSaveProductionPlan();

    const [currentWeek, allWeeks] = useMemo(() => {
      return getWorkingDaysInWeeks(year);
    }, [year]);

    const selectedMachinesOptions = useMemo(() => {
      if (!machinePark || machinePark === allMachineParkKey) {
        return availableMachines;
      }

      return (
        machineParks[machinePark]?.map((selectedMachineParkMachine) => {
          return selectedMachineParkMachine.machineCode;
        }) ?? []
      );
    }, [availableMachines, machinePark, machineParks]);

    useEffect(() => {
      if (typeof week === 'undefined' && currentWeek) {
        setWeek(currentWeek);

        return;
      }

      const weekIndex = allWeeks.findIndex((weekElement) => {
        const [firstDay] = weekElement;
        const [firstSavedDay] = days;

        return firstDay?.getTime() === firstSavedDay?.getTime();
      });

      const preparedWeek = weekIndex !== -1 ? weekIndex : 0;

      if (preparedWeek !== week) {
        setWeek(preparedWeek);
      }
    }, [allWeeks, currentWeek, days, setWeek, week]);

    const getYear = (paramYear: number) => {
      const currentYear = new Date().getUTCFullYear();

      return currentYear + paramYear;
    };

    const handleYearChange = (ev: SelectChangeEvent<unknown>) => {
      setYear(parseInt(ev.target.value as string, 10));
    };

    const handleAmChange = () => {
      setShowAm((prev) => !prev);
    };

    const changeWeek = (newWeek: number) => {
      if (newWeek < 0 || newWeek > allWeeks.length - 1) {
        return;
      }

      setDays(allWeeks[newWeek]);
    };

    useEffect(() => {
      if (typeof week === 'undefined' || days.length) {
        return;
      }

      setDays(allWeeks[week]);
    }, [week, year, setDays, allWeeks, days]);

    const onChangeSelect = (e: SelectChangeEvent<unknown>) => {
      const { value } = e.target;

      if (
        !isValueTrueString(value) ||
        !availableMachines.includes(value) ||
        !machinePark
      ) {
        return;
      }

      if (machinePark === allMachineParkKey) {
        const machineParksKeys = Object.keys(machineParks);
        let selectedMachine: ProductionPlanMachine | undefined;

        machineParksKeys.forEach((machineParkKey) => {
          const itterableMachinePark = machineParks[machineParkKey];

          const foundMachine = itterableMachinePark.find((itterableMachine) => {
            return itterableMachine.machineCode === value;
          });

          if (foundMachine) {
            selectedMachine = foundMachine;
          }
        });

        if (selectedMachine) {
          setMachine(selectedMachine);
        }

        return;
      }

      const selectedMachine = machineParks[machinePark].find(
        (machineParkMachine) => {
          return machineParkMachine.machineCode === value;
        },
      );

      if (!selectedMachine) {
        return;
      }

      setMachine(selectedMachine);
    };

    const selectedMachine = selectedMachinesOptions.includes(
      machine?.machineCode ?? '',
    )
      ? machine?.machineCode
      : '';

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: '10px',
          minWidth: 'max-content',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <WeekSelect
            shownWeekNumber={(week ?? 0) + 1}
            changeWeek={changeWeek}
          />
          <CustomSelect
            value={year}
            onChange={handleYearChange}
            sx={{ ml: '10px' }}
          >
            {[...Array(yearsToBeShown)].map((_x, i) => (
              <MenuItem key={i - 1} value={getYear(i - 1)}>
                <Typography>{getYear(i - 1)}</Typography>
              </MenuItem>
            ))}
          </CustomSelect>
          <Typography sx={{ ml: 3 }}>{nl.machine}</Typography>
          <CustomSelect
            sx={{ ml: '10px', minWidth: '100px' }}
            value={selectedMachine}
            onChange={onChangeSelect}
          >
            {selectedMachinesOptions.map((machineKeyItem) => {
              return (
                <MenuItem value={machineKeyItem} key={machineKeyItem}>
                  {machineKeyItem}
                </MenuItem>
              );
            })}
          </CustomSelect>
        </Box>
        <Box sx={{ display: 'flex', ml: 1 }}>
          <Button
            onClick={handleAmChange}
            variant={'outlined'}
            sx={{ width: '40px' }}
          >
            <Typography>{nl.am}</Typography>
          </Button>
          <Box sx={{ display: 'flex', gap: '6px', mx: 3, fontSize: '12px' }}>
            <PDFRenderButton fileName={`${nl.week} - ${(week ?? 0) + 1}`}>
              <IconButton>
                <DocumentIcon
                  sx={(theme) => ({
                    color: theme.palette.common.black,
                    fontSize: '12px',
                  })}
                />
              </IconButton>
            </PDFRenderButton>
          </Box>
          <Box>
            <Typography
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.secondary.light, 0.7),
                display: 'inline-block',
                cursor: 'pointer',
                ml: '6px',
                position: 'relative',
                top: '1px',
                height: '27px',
                width: '108px',
                lineHeight: '27px',
                textAlign: 'center',
                color: theme.palette.secondary.main,
                border: `1px solid ${theme.palette.secondary.light}`,
              })}
              onClick={saveProductionPlan}
            >
              {nl.uploadButton}
            </Typography>
          </Box>
        </Box>
        <UploadArticlesModal
          open={!!currentUploads}
          close={closeModal}
          currentUploads={currentUploads}
        />
      </Box>
    );
  });
