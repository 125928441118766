import config from '../../../../config';
import { isDevMode } from '../../../helpers/isDevMode';
import { logError } from '../../../helpers/logError';
import request from '../../../helpers/request';
import tableDataMocks from './tableDataMocks';

export enum ProductionStatus {
  Planned = 'Planned',
  OnSalesorder = 'On salesorder',
  Entered = 'Entered',
}

export interface RawArticlesDataType
  extends Record<
      | 'article_code'
      | 'article_description'
      | 'article_height'
      | 'article_width'
      | 'article_length'
      | 'plan_quantity'
      | 'poss_moulds'
      | 'possible_machines'
      | 'quantity_per_pallet'
      | 'ordertype'
      | 'repeats'
      | 'total_quantity_to_produce'
      | 'machine_park',
      string
    >,
    Record<'economic_stock' | 'available_stock', string | null>,
    Record<
      | 'length_mould'
      | 'quantity_per_mould'
      | 'quantity_required'
      | 'plan_custom_item',
      number
    >,
    Record<'turn' | 'week' | 'year', number | null> {
  productions: Array<
    | {
        production_status: ProductionStatus | null;
        year: string | null;
        week: string | null;
        quantity_to_produce: number | null;
        production_nr: string | null;
        machine_planning_id: string | null;
        customer: string | null;
        salesorder_nr: string | null;
        ean_codes?: [
          {
            production_method:
              | 'BETON GIETEN'
              | 'LABELLEN + QC'
              | 'BETON PROFILERING';
            ean_code: string;
          },
        ];
      }
    | undefined
  >;
}

export async function getTableData() {
  if (isDevMode) {
    return tableDataMocks;
  }

  let response: [Array<RawArticlesDataType>] | null = null;

  try {
    response = await request<[Array<RawArticlesDataType>]>(
      config.routes.sharedTable,
      {
        method: 'POST',
        executionToken: 'sharedTable',
      },
    );
  } catch (error) {
    logError(error);
  }

  return response?.[0] ?? [];
}
