/* eslint-disable react-hooks/exhaustive-deps */
import { type DependencyList, useEffect, useState } from 'react';

export function useAsyncMemo<T>(
  func: () => T,
  deps: DependencyList,
  initialState: Awaited<T>,
): Awaited<T>;

export function useAsyncMemo<T>(
  func: () => T,
  deps: DependencyList,
): Awaited<T> | undefined;

export function useAsyncMemo<T>(
  func: () => T,
  deps: DependencyList,
  initialState?: Awaited<T>,
) {
  const [data, setData] = useState(initialState);

  useEffect(() => {
    (async function () {
      setData(await func());
    })();
  }, deps);

  return data;
}
