import React, { type FC, useMemo, useState } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import { TableColumns } from '../../../../../assets/icons';
import {
  disabledHeaders,
  useArticlesContext,
} from '../../../../../core/providers/ArticlesTableProvider';
import { type ArticleDataTypeKeys } from '../../../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { CustomCheckbox } from '../../../../CustomCheckbox.tsx/CustomCheckbox';

export const TableColumnsSelect: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const {
    articlesData: { selectedHeaders, allHeaders, dictionary },
    setSelectedHeaders,
  } = useArticlesContext();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const manageHeaderCells = (cellName: ArticleDataTypeKeys) => {
    const isActive = selectedHeaders.includes(cellName);

    const newState = isActive
      ? selectedHeaders.filter((header) => header !== cellName)
      : [...selectedHeaders, cellName];

    setSelectedHeaders(newState);
  };

  const reorderedHeaders = useMemo(() => {
    return [...allHeaders].sort((item) => {
      return disabledHeaders.includes(item) ? -1 : 1;
    });
  }, [allHeaders]);

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        id='columns-icon'
        aria-controls={open ? 'table-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <TableColumns fontSize='inherit' />
      </IconButton>
      <Menu
        id='TableColumns-menu'
        sx={{ maxHeight: '379px' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'columns-icon',
        }}
      >
        {reorderedHeaders.map((columnName) => {
          const isDisabled = disabledHeaders.includes(columnName);

          const onClick = isDisabled
            ? undefined
            : () => manageHeaderCells(columnName);

          const disabledStyle = {
            color: '#4c4c4c',
            fontWeight: 600,
            letterSpacing: 0.4,
          };

          return (
            <MenuItem onClick={onClick} key={columnName}>
              <CustomCheckbox
                sx={{ py: '3px', px: '9px' }}
                checked={selectedHeaders.includes(columnName)}
              />
              <Box sx={isDisabled ? disabledStyle : {}}>
                {dictionary?.[columnName]}
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
