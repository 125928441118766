import config from '../../../../config';
import { type ArticleDataType } from './ArticleTypes';

export const isInMachinePark = (
  machinePark: string | undefined,
  article: ArticleDataType,
) => {
  const { allMachineParkKey } = config;

  if (machinePark === allMachineParkKey) {
    return true;
  }

  return article.machinePark === machinePark;
};
