import { memo } from 'react';
import { Box } from '@mui/material';

import { ArticlesTable } from './Components/ArticlesTable/ArticlesTable';
import { AvailableMoldsTable } from './Components/AvailableMoldsTable/AvailableMoldsTable';
import { TableOverheadButtons } from './Components/TableOverheadButtons/TableOverheadButtons';

export const ArticlesPartition = memo(() => {
  return (
    <Box>
      <TableOverheadButtons />
      <ArticlesTable />
      <AvailableMoldsTable />
    </Box>
  );
});
