import { type Theme, type ThemeOptions } from '@mui/material/styles';

export default (theme: Theme): ThemeOptions['components'] => ({
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.grey?.[200]}`,
        padding: 5,
        height: '30px',
        boxSizing: 'border-box',
      },
      body: {
        padding: '5px !important',
      },
      head: {
        backgroundColor: theme.palette.grey?.[100],
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});
