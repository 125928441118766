import { useContext } from 'react';

import { ArticlesTableDataContext } from './ArticlesTableProvider';

export const useArticlesContext = () => {
  const ctx = useContext(ArticlesTableDataContext);

  if (ctx == null) {
    throw new Error('Wrap app with MachinesTableDataContext');
  }

  return ctx;
};
