import { type ArticleDataType } from './utils/ArticleTypes';
import { type SearchFields } from '../../../components/ArticlesPartition/Components/ArticlesTable/ArticlesTable';
import config from '../../../config';
import { type typedObjectValues } from '../../helpers/typedObjectKeys';
import {
  type DictionaryType,
  type SelectedHeadersType,
} from './ArticlesTableProvider';

const {
  eanCodes: { types: CodeTypes },
} = config;

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum TOrderType {
  MakeToOrder = 'Make to Order',
  StockOrder = 'Stock Order',
}

export type ArticleSort =
  | Partial<Record<SelectedHeadersType[number], SortOrder>>
  | undefined;

export interface IArticlesData {
  searchFields: SearchFields;
  allArticles: Array<ArticleDataType>;
  loading: boolean;
  dictionary: DictionaryType | undefined;
  selectedHeaders: SelectedHeadersType;
  selectedRow: ArticleDataType | undefined;
  sort: ArticleSort;
}

export enum ArticlesDataActionType {
  SET_SEARCH_FIELDS = 'setSearchFields',
  SET_ALL_ARTICLES_DATA = 'setArticlesData',
  SET_LOADING = 'setLoading',
  SET_DICTIONARY = 'setDictionary',
  SET_SELECTED_HEADERS = 'setSelectedHeaders',
  SET_SELECTED_ROW = 'setSelectedRow',
  SET_SORT = 'setSort',
}

export interface ISetSearchFields {
  type: ArticlesDataActionType.SET_SEARCH_FIELDS;
  payload: SearchFields;
}

export interface ISetArticlesData {
  type: ArticlesDataActionType.SET_ALL_ARTICLES_DATA;
  payload: Array<ArticleDataType>;
}

export interface ISetLoading {
  type: ArticlesDataActionType.SET_LOADING;
  payload: boolean;
}

export interface ISetDictionary {
  type: ArticlesDataActionType.SET_DICTIONARY;
  payload: DictionaryType | undefined;
}

export interface ISetSelectedHeaders {
  type: ArticlesDataActionType.SET_SELECTED_HEADERS;
  payload: SelectedHeadersType;
}

export interface ISetSelectedRow {
  type: ArticlesDataActionType.SET_SELECTED_ROW;
  payload: ArticleDataType | undefined;
}

export interface ISetArticleSort {
  type: ArticlesDataActionType.SET_SORT;
  payload: ArticleSort;
}

export type BarCodeType = ReturnType<
  typeof typedObjectValues<typeof CodeTypes>
>[number];

export type ArticlesDataAction =
  | ISetSearchFields
  | ISetArticlesData
  | ISetLoading
  | ISetDictionary
  | ISetSelectedHeaders
  | ISetSelectedRow
  | ISetArticleSort;
