import config from '../../../../config';
import { isObjectEmpty } from '../../../helpers/isObjectEmpty';
import { machinesParkInitialState } from '../ProductionPlanProvider';
import {
  type IPreparedMachineParks,
  type MachinesParkResponseType,
  type MachinesParks,
} from '../types';

const prepareMachinePark = (
  response: MachinesParkResponseType | null,
): IPreparedMachineParks => {
  if (!response) {
    return machinesParkInitialState;
  }

  const machineParks: MachinesParks | Record<string, never> = {};

  response.forEach(({ formula_result: formulaResult }) => {
    const resultKeys = Object.keys(formulaResult);

    resultKeys.forEach((machineParkKey) => {
      const preparedMachines = formulaResult[machineParkKey].map((machine) => {
        const {
          machine_code: machineCode,
          lengte_trilbalk: beamLength,
          possible_machines: possibleMachines,
        } = machine;

        return {
          possibleMachines: possibleMachines?.split(';') ?? [],
          machineCode,
          beamLength,
        };
      });

      machineParks[machineParkKey] = [
        {
          possibleMachines: [],
          machineCode: config.allMachines,
          beamLength: 0,
        },
        ...preparedMachines,
      ];
    });
  });

  if (isObjectEmpty(machineParks)) {
    return machinesParkInitialState;
  }

  const availableMachineParks = Object.keys(machineParks);
  let availableMachineKeys = new Set<string>();

  availableMachineKeys = availableMachineParks.reduce((acc, machineParkKey) => {
    const MachineParkMachineKeys = machineParks[machineParkKey].map(
      ({ machineCode }) => {
        return machineCode;
      },
    );

    return new Set([...acc, ...MachineParkMachineKeys]);
  }, availableMachineKeys);

  return {
    machineParks,
    availableMachineParks,
    availableMachines: [...availableMachineKeys],
  };
};

export default prepareMachinePark;
