import { type SvgIconProps } from '@mui/material';

import { NoFillSvgIcon } from '../NoFillSvgIcon';

export const TableColumns = (props: SvgIconProps) => {
  return (
    <NoFillSvgIcon {...props} width='14' height='13' viewBox='0 0 14 13'>
      <path
        d='M12.25.625H1.75c-.492.018-.902.191-1.23.52-.329.328-.502.738-.52 1.23v8.75c.018.492.191.902.52 1.23.328.329.738.502 1.23.52h10.5c.492-.018.902-.191 1.23-.52.329-.328.502-.738.52-1.23v-8.75c-.018-.492-.191-.902-.52-1.23-.328-.329-.738-.502-1.23-.52Zm-6.125 10.5H1.75v-7h4.375v7Zm6.125 0H7.875v-7h4.375v7Z'
        fill='currentColor'
      />
    </NoFillSvgIcon>
  );
};
