import config from '../../../../config';
import { saveBackUpArticles } from '../../ArticlesTableProvider/utils/saveBackUpArticles';
import { type IProductionData } from '../types';

const { localStorageVersion, localStorageKeys } = config;

export const saveDataToLocalStorage = (data: IProductionData) => {
  localStorage.setItem(
    localStorageKeys.PRODUCTION_PLAN_DATA,
    JSON.stringify({ ...data, localStorageVersion }),
  );

  saveBackUpArticles(data.insertedArticles);
};
