import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { useArticlesDataSetters } from './hooks/useArticlesDataSetters';
import { articlesReducer } from './reducers/ArticlesReducer';
import {
  type ArticleDataType,
  type ArticleDataTypeKeys,
} from './utils/ArticleTypes';
import { type BarCodes } from './utils/getBarCodes';
import { getTableData } from './utils/getTableData';
import { initialState } from './utils/initialState';
import { isInMachinePark } from './utils/isInMachinePark';
import { isInPossibleMachines } from './utils/isInPossibleMachines';
import {
  hiddenHeaders,
  prepareArticlesData,
} from './utils/prepareArticlesData';
import { prepareDictionary } from './utils/prepareDictionary';
import { satisfiesSearchParams } from './utils/satisfiesSearchParams';
import { sortFunction } from './utils/sortFunction';
import { type SearchFields } from '../../../components/ArticlesPartition/Components/ArticlesTable/ArticlesTable';
import { typedObjectKeys } from '../../helpers/typedObjectKeys';
import { isDateString, noop } from '../../helpers/utils';
import { useProductionPlan } from '../ProductionPlanProvider';
import { type ArticlesDataAction, type ArticleSort } from './types';

export type SelectedHeadersType = Array<ArticleDataTypeKeys>;
export type AllBarCodes = Record<string, BarCodes>;

interface ArticlesTableDataContextType {
  articlesData: {
    loading: boolean;
    allArticles: Array<ArticleDataType>;
    dictionary: DictionaryType | undefined;
    allHeaders: SelectedHeadersType;
    selectedHeaders: SelectedHeadersType;
    selectedRow: ArticleDataType | undefined;
    searchFields: SearchFields;
    sort: ArticleSort;
    filteredArticles: Array<ArticleDataType>;
  };
  barCodes: AllBarCodes;
  setSelectedRow: (selectedRow: ArticleDataType) => void;
  setSearchFields: (searchFields: SearchFields) => void;
  setSelectedHeaders: (val: SelectedHeadersType) => void;
  setAllArticles: (machines: Array<ArticleDataType>) => void;
  setSort: (sort: ArticleSort) => void;
  setRefreshTableData: Dispatch<SetStateAction<boolean>>;
}

export type DictionaryType = Record<ArticleDataTypeKeys, string>;

export const ArticlesTableDataContext =
  createContext<ArticlesTableDataContextType>({
    articlesData: {
      loading: true,
      dictionary: undefined,
      allArticles: [],
      allHeaders: [],
      selectedHeaders: [],
      selectedRow: undefined,
      searchFields: {},
      filteredArticles: [],
      sort: undefined,
    },
    barCodes: {},
    setSearchFields: noop,
    setSelectedRow: noop,
    setAllArticles: noop,
    setSelectedHeaders: noop,
    setRefreshTableData: noop,
    setSort: noop,
  });

export const ArticlesTableDataProvider = memo(
  ({ children }: PropsWithChildren) => {
    const [data, dispatch] = useReducer(articlesReducer, initialState());
    const [refreshTableData, setRefreshTableData] = useState(true);
    const [barCodes, setBarCodes] = useState<Record<string, BarCodes>>({});

    const dispatchCallback = useCallback((action: ArticlesDataAction) => {
      dispatch(action);
    }, []);

    const {
      setSearchFields,
      setAllArticles,
      setLoading,
      setDictionary,
      setSelectedHeaders,
      setSelectedRow,
      setSort,
    } = useArticlesDataSetters(dispatchCallback);
    const {
      productionPlanData: { machine, machinePark, insertedArticles },
      setArticlesRow,
    } = useProductionPlan();

    const { dictionary, allArticles, searchFields, sort } = data;

    useEffect(() => {
      if (!refreshTableData) {
        return;
      }

      setRefreshTableData(false);
      setLoading(true);

      getTableData().then((resData) => {
        if (!resData.length) {
          return;
        }

        const preparedData = prepareArticlesData(resData);

        const newBarCodes: AllBarCodes = {};

        Object.entries(insertedArticles).map(
          ([_machineName, machineArticles]) => {
            Object.entries(machineArticles).map(([date, dateArticlesRows]) => {
              dateArticlesRows?.map((articlesArray) => {
                if (!isDateString(date)) {
                  return;
                }

                articlesArray.forEach((article) => {
                  const { articleCode } = article;

                  const filteredArticles = preparedData.filter(
                    (preparedArticle) =>
                      preparedArticle.articleCode === articleCode,
                  );

                  const newBarCodesForArticle = filteredArticles.reduce(
                    (acc, currentItem) => {
                      return { ...acc, ...currentItem.barCodes };
                    },
                    {} as BarCodes,
                  );

                  newBarCodes[articleCode] = {
                    ...(newBarCodes[articleCode] ?? {}),
                    ...newBarCodesForArticle,
                  };
                });
              });
            });
          },
        );

        setBarCodes(newBarCodes);
        setDictionary(prepareDictionary(preparedData[0]));
        setAllArticles(preparedData);
        setLoading(false);
      });
    }, [
      setDictionary,
      setLoading,
      setAllArticles,
      refreshTableData,
      insertedArticles,
      setArticlesRow,
    ]);

    const filteredArticles = useMemo(() => {
      const filtered = allArticles.filter((article) => {
        return (
          isInPossibleMachines(machine, article) &&
          satisfiesSearchParams(searchFields, article) &&
          isInMachinePark(machinePark, article)
        );
      });

      if (!sort) {
        return filtered;
      }

      return filtered.sort((a, b) => sortFunction(a, b, sort));
    }, [allArticles, machine, machinePark, searchFields, sort]);

    const allHeaders = useMemo(() => {
      if (!dictionary) {
        return [];
      }

      const headers = typedObjectKeys(dictionary);

      return headers.filter((header) => {
        return !hiddenHeaders.includes(header);
      });
    }, [dictionary]);

    const value: ArticlesTableDataContextType = useMemo(
      () => ({
        articlesData: {
          ...data,
          filteredArticles,
          allHeaders,
        },
        barCodes,
        setSearchFields,
        setSort,
        setAllArticles,
        setSelectedRow,
        setSelectedHeaders,
        setRefreshTableData,
      }),
      [
        data,
        filteredArticles,
        allHeaders,
        barCodes,
        setSearchFields,
        setSort,
        setAllArticles,
        setSelectedRow,
        setSelectedHeaders,
      ],
    );

    return (
      <ArticlesTableDataContext.Provider value={value}>
        {children}
      </ArticlesTableDataContext.Provider>
    );
  },
);
