import { type ChangeEvent, type FC, memo } from 'react';
import {
  type Theme,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { nl } from '../../../assets/text/nl';
import config from '../../../config';
import {
  CONTEXT_MENU_CELL_IDX,
  CONTEXT_MENU_DATE_KEY,
  CONTEXT_MENU_ROW_KEY,
} from '../../../core/helpers/hooks/useContextMenu';
import { isArticleDropError } from '../../../core/helpers/isArticleDropError';
import { isNumeric } from '../../../core/helpers/isNumeric';
import { type DateStringType } from '../../../core/helpers/types';
import { isDateString } from '../../../core/helpers/utils';
import { type ArticleDataType } from '../../../core/providers/ArticlesTableProvider/utils/ArticleTypes';
import { useContextMenuContext } from '../../../core/providers/ContextMenuProvider';
import { useProductionPlan } from '../../../core/providers/ProductionPlanProvider';
import { useSharedTableDataContext } from '../../ResizableLayout/SharedTableDataProvider';
import { getPalletesNumber } from '../utils/getPalletesNumber';
import { isArticleValuesError } from '../utils/isArticleValuesError';

export enum ArticleProductDetails {
  TimesToBeProduced = 'timesToBeProduced',
  QuantityPerMould = 'quantityPerMould',
  Palette = 'Palette',
}

interface ArticleTableProps {
  highlightArticles: boolean;
  showHeader: boolean;
  day: DateStringType;
  tableIndex: number;
  articles: Array<ArticleDataType> | undefined;
  rowIndex: number;
  showAm: boolean;
}

export const ArticlesTableProductionPlan: FC<ArticleTableProps> = memo(
  ({
    day,
    showHeader,
    tableIndex,
    highlightArticles,
    articles,
    rowIndex,
    showAm,
  }) => {
    const { sharedData } = useSharedTableDataContext();
    const {
      productionPlanData: { machine },
      holidays,
      setInsertedArticle,
      changeInsertedArticle,
    } = useProductionPlan();
    const { handleContextMenu } = useContextMenuContext();

    const shouldHighlightCells = highlightArticles && !!sharedData;
    const isHoliday = holidays.includes(day);

    const handleMouseUp = () => {
      if (!machine || !sharedData) {
        return;
      }

      const filteredArticles = (articles ?? []).filter(Boolean);

      if (
        isArticleDropError(filteredArticles, sharedData, machine, isHoliday)
      ) {
        return;
      }

      setInsertedArticle({
        row: rowIndex,
        machineKey: machine.machineCode,
        key: day,
        article: {
          ...sharedData,
          tableIndex,
        },
      });
    };

    const handlePropertyChange = (
      ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      key: ArticleProductDetails,
    ) => {
      const value = ev.target.value;

      if (
        !machine ||
        // E - is a math symbol which will not trigger number validation
        value.toLowerCase().includes('e') ||
        !cellData
      ) {
        return;
      }

      if (isArticleValuesError(key, value, cellData)) {
        return;
      }

      changeInsertedArticle({
        row: rowIndex,
        machineKey: machine.machineCode,
        key: day,
        article: {
          ...cellData,
          tableIndex,
          isUploaded: false,
          [key]: isNumeric(value) ? Math.abs(parseInt(value, 10)) : value,
        },
      });
    };

    const insertedDay = isDateString(day) ? articles : undefined;

    const cellData = Array.isArray(insertedDay)
      ? insertedDay?.[tableIndex]
      : undefined;

    return (
      <TableContainer sx={{ width: '200px', ml: 0.5 }}>
        <Table
          onMouseUp={handleMouseUp}
          sx={{ tableLayout: 'fixed' }}
          onContextMenu={(e) => handleContextMenu?.(e)}
          {...{
            [CONTEXT_MENU_DATE_KEY]: day,
            [CONTEXT_MENU_ROW_KEY]: rowIndex,
            [CONTEXT_MENU_CELL_IDX]: cellData && tableIndex,
          }}
        >
          <colgroup>
            <col style={{ width: '45%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            {showAm && <col style={{ width: '15%' }} />}
          </colgroup>
          {showHeader && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>{nl.artikel}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{nl.ag}</Typography>
                </TableCell>
                {showAm && (
                  <TableCell>
                    <Typography>{nl.am}</Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Typography>{nl.p}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            <TableRow>
              <TableCell
                sx={(theme) =>
                  tableCellStyles(
                    theme,
                    cellData,
                    shouldHighlightCells,
                    machine?.machineCode,
                    isHoliday,
                  )
                }
              >
                {cellData?.[config.fields.articleCodeName]}
              </TableCell>
              <TableCell>
                {cellData != null && (
                  <Input
                    onChange={(ev) => {
                      handlePropertyChange(
                        ev,
                        ArticleProductDetails.TimesToBeProduced,
                      );
                    }}
                    disableUnderline
                    type='number'
                    value={cellData.timesToBeProduced ?? ''}
                    sx={{
                      '& .MuiInput-input': {
                        padding: 0,
                        textAlign: 'end',
                      },
                    }}
                  />
                )}
              </TableCell>
              {showAm && (
                <TableCell>
                  {cellData != null && (
                    <Input
                      onChange={(ev) =>
                        handlePropertyChange(
                          ev,
                          ArticleProductDetails.QuantityPerMould,
                        )
                      }
                      disableUnderline
                      type='number'
                      inputProps={{ min: '0', type: 'number' }}
                      value={cellData.quantityPerMould ?? ''}
                      sx={{
                        '& .MuiInput-input': {
                          padding: 0,
                          textAlign: 'end',
                        },
                      }}
                    />
                  )}
                </TableCell>
              )}
              <TableCell>
                <Typography sx={{ textAlign: 'end' }}>
                  {getPalletesNumber(cellData)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={(theme) => ({ background: theme.palette.grey[300] })}>
              {Array.from(Array(showAm ? 4 : 3)).map((_, idx) => (
                <TableCell key={idx}></TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  },
);

const tableCellStyles = (
  theme: Theme,
  cellData: ArticleDataType | undefined,
  shouldHighlightCells: boolean,
  machineCode: string | undefined,
  isHoliday: boolean,
) => {
  let styles: Record<string, string> = {};

  if (isHoliday && shouldHighlightCells) {
    return {
      background: theme.palette.error.light,
    };
  }

  if (cellData?.isUploaded) {
    styles = {
      background: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.light}`,
    };
  } else if (cellData?.[config.fields.articleCodeName]) {
    styles = {
      background: theme.palette.success.main,
      border: `1px solid ${theme.palette.success.dark}`,
    };
  } else if (shouldHighlightCells) {
    styles = {
      background:
        machineCode === config.allMachines
          ? theme.palette.error.light
          : theme.palette.success.light,
    };
  }

  return styles;
};
