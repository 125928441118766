import { Route, Routes } from 'react-router-dom';

import { Login } from '../../screens/Login/Login';

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/*' element={<Login />} />
    </Routes>
  );
};
